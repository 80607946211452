import React, { useEffect, useState, useRef } from "react";
import { useDeepCompareMemo } from "use-deep-compare";
import TableRb from "../../../components/Tables/tableRb";
import externalLink from "../../../assets/icons/external_link.svg";
import AddIcon from "../../../assets/svgs/plus_box.svg";
import MinusIcon from "../../../assets/svgs/minus_box.svg";
import { Stack } from "react-bootstrap";
import overviewSeiingIcon from "../../../assets/svgs/filter.svg";
import SwitchBtn from "../../../components/Buttons/switchBtn";
import ascendingIconSvg from "../../../assets/svgs/acendingIconSvg.svg";
import descendingIconSvg from "../../../assets/svgs/descendingIcon.svg";

import SpinnerShimmer from "../../../components/spinners/spinner";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import dragIcon from "../../../assets/svgs/filter-bars-black.svg";
import Button from "react-bootstrap/Button";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../context/global";
import { useDownloadExcel } from "react-export-table-to-excel";
import {previousDaysDifference} from "../../../helper/Reports/common";
import {
  humanize,
  formatTableData,
  saveCustomView,
  getCustomView,
  allMetrics,
  measuresFunnal,measuresFunnalDRR,
  measuresPerformance,measuresPerformanceDRR,
  linkedStrings,
  expendableStrings,
  defaultRadioValues,
  formatDate,
  displayDate, format, format1Decimal, formatDecimal, getDivideData,
  metricStrings, summaryMapping, getProductWithImage,calenderDate,getSelectedPresetLabel
} from "./utils";
import {
    ChildTableRenderer,
    Download
  } from "./";

import Loader from "../../../components/loader";
import LazyLoadSpinner from "../../../components/spinners/LazyLoadSpinner";
import numeral from 'numeral';
import SearchWithSelect from '../../../components/Search/SearchWithSelect';
import { useSearchParams } from "react-router-dom";
import { keyExtractor } from "../../../helper/widget";

export const TableRenderer = ({
    resultSet,
    pivotConfig,
    customClasses,
    percentageShow,
    displayType,
    props,
    children_1,
    children_1_header,
    FunnelViews,
    CurrentViewType,
    shimmerEffect,
    showFilter,
    page,
    paginationResultSet,
    summaryTotal,
    predefinedHeadings,
    getHeadingsOnViewChange,
    pageName,
    customDownloadURL,
  }) => {
    const userPlatform = JSON.parse(localStorage.getItem("user_platform"));
    const [selectedRows, setSelectedRows] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [dataPresent, setDataPresent] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [hiddenKeys, setHiddenKeys] = useState([]);
    const [defaultHiddenKeys, setDefaultHiddenKeys] = useState([]);
    const [sortKey, setSortKey] = useState(1);
    const [sortType, setSortType] = useState("desc");
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search)
    const drrViewType = queryParams.get('metricViewType');
    const [drrView, setDrrView] = useState(drrViewType ? drrViewType : props?.drrViewType  ?? "")
    const { clientId, setReportsTime, setReportsFunnelType , setDateDifference } = useGlobalContext();
    const [isLoaderVisible, setIsLoaderVisible] = useState(false);
    const [resultOrder, setResultOrder] = useState({});
    const tableRefScroll = useRef(null);
    const [lazyLoading, setLazyLoading] = useState(false);
    const [pagination, setPagination] = useState({
      limit:0,
      offset:0
    })
    const [totalRecords, setTotalRecords] = useState(0);
    const [tableRefKey, setTableRefKey] = useState(0);
    const [onKeyChange,setOnKeyChange] = useState(false)

    // Using this effect if the result set changes then change this state so that it will trigger re-render of the hiddenkeys useEffect with new tablecolumns.
    useEffect(() => {
      setOnKeyChange(!onKeyChange);
     },[resultSet])

 

    const handleCellClick = (rowIndex) => {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.includes(rowIndex)
          ? prevSelectedRows.filter((row) => row !== rowIndex)
          : [...prevSelectedRows, rowIndex]
      );
    };
    const tableRef = useRef(null);
    const [visibleData, setVisibleData] = useState([]);
    const [startIdx, setStartIdx] = useState(0);
    const [pageSize] = useState(1000);
  
    const [tableColumnsCalculated, dataSourceCalculated] =
      useDeepCompareMemo(() => {
        const columns = resultSet.tableColumns(pivotConfig)
        return [
          columns,
          formatTableData(columns, resultSet.tablePivot(pivotConfig)),
        ];
      }, [resultSet, pivotConfig]);
      const [tableColumnsPagination, dataSourcePagination] = useDeepCompareMemo(() => {
      let paginationColumns = [];
      let formattedData = [];
  
      if (paginationResultSet && typeof paginationResultSet.tableColumns === 'function') {
        paginationColumns = paginationResultSet.tableColumns(pivotConfig);
        formattedData = formatTableData(paginationColumns, paginationResultSet.tablePivot(pivotConfig));
      }
  
      return [paginationColumns, formattedData];
    }, [paginationResultSet, pivotConfig]);
      
    var totals = {};
    const [tableColumns, setTableColumns] = useState(tableColumnsCalculated);
    const [dataSource, setDataSource] = useState(dataSourceCalculated);
    const [viewType, setViewType] = useState(
      CurrentViewType ?? "Performance View"
    );
    const allMetricsFiltered = page === "performance-overview" ? allMetrics?.filter((data) => {
      return !data?.toLowerCase().includes("drr")
    }) : allMetrics // Removing DRR Metrics For Perfomance-overview page (For Drag & Drop Logic).
    const [headings, setHeadings] = useState({});
    const [runHidden, setRunHidden] = useState(false);
    const [tempHeadings, setTempHeadings] = useState(predefinedHeadings);
    const [filterHeadings,setFilterHeadings] = useState({});
    const [hideImages, setHideImages] = useState(false);
    const [itemList, setItemList] = useState(allMetricsFiltered);
    useEffect(() => {
      const searchParams = new URLSearchParams(window.location.search);
      const funnelValue = searchParams.get("funnel");
      if (funnelValue) {
        setViewType(funnelValue);
      }
    }, [window.location.search]);
  
    useEffect(() => {
      if (paginationResultSet && typeof paginationResultSet.tableColumns === 'function') {
        setDataSource((prev) => {
          return [...prev, ...dataSourcePagination]
        })
        setLazyLoading(false);
      } else {
        loadMoreData();
      }
    },[dataSourcePagination])
     
    useEffect(() => {
      loadMoreData();
    },[dataSource])

    useEffect(() => {
      if(drrView!=="") {
        const queryParams = new URLSearchParams(window.location.search)
        if (queryParams.get('metricViewType')) {
          // 'viewtype' parameter exists, update its value to 'newType'
          queryParams.set('metricViewType', drrView);
        } else {
          // 'viewtype' parameter doesn't exist, add it with a default value of 'defaultValue'
          queryParams.set('metricViewType', drrView);
        }      
        // Update the browser URL
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.replaceState(null, null, newUrl);
      }
    },[drrView])
  
    useEffect(()=> {
      setStartIdx(0);
      setVisibleData([]);
    }, [resultSet])
  
    useEffect(()=> {
      if(visibleData.length === 0) {
        if(dataSource.length > 0) {
          loadMoreData();
        }
      }
    }, [visibleData])

    useEffect(() => {
      const queryParams = new URLSearchParams(window.location.search)
      if (queryParams.get('viewtype')) {
        // 'viewtype' parameter exists, update its value to 'newType'
        queryParams.set('viewtype', viewType);
      } else {
        // 'viewtype' parameter doesn't exist, add it with a default value of 'defaultValue'
        queryParams.set('viewtype', viewType);
      }
      
      // Update the browser URL
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.replaceState(null, null, newUrl);
     
    },[viewType])
  
  
    useEffect(() => {
      setExpandedRows([])
      setSelectedRows([])
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.delete("funnel", viewType);
      setHeadings({});
      setFilterHeadings({});
      navigate({ search: queryParams.toString(), replace: true });
      let add = [];
      const resultSetNewColumn = JSON.parse(JSON.stringify(tableColumns));
      if (viewType === "Comparison View") {
        if (typeof props.handleComparisonView === "function") {
          props.handleComparisonView(viewType);
        }
      } else {
        if (typeof props.handleComparisonView === "function") {
          props.handleComparisonView(viewType);
        }
        if (viewType === "Funnel View") {
          if(drrView === 'drr') {
            add = resultSetNewColumn.reduce((acc, item, index) => {
              if (measuresPerformanceDRR[userPlatform].includes(keyExtractor(item.key))) {
                acc.push(index);
              }
              return acc;
            }, []);
          } else {
            add = resultSetNewColumn.reduce((acc, item, index) => {
              if (measuresPerformance[userPlatform].includes(keyExtractor(item.key))) {
                acc.push(index);
              }
              return acc;
            }, []);
          }
        } else if (viewType === "Performance View") {
          if(drrView === 'drr') {
            add = resultSetNewColumn.reduce((acc, item, index) => {
              if (measuresFunnalDRR[userPlatform].includes(keyExtractor(item.key))) {
                acc.push(index);
              }
              return acc;
            }, []);
          } else {
            add = resultSetNewColumn.reduce((acc, item, index) => {
              if (measuresFunnal[userPlatform].includes(keyExtractor(item.key))) {
                acc.push(index);
              }
              return acc;
            }, []);
          }
        } else if (viewType === "Custom View") {
          add = [];
        }
        setHiddenKeys((prev) => {
          if (resultSetNewColumn.map(function (o) {return o.shortTitle}).indexOf("month_numeric") > 0) {
            add.push(tableColumns.map(function (o) { return o.shortTitle;}).indexOf("month_numeric"));
          }
          return add;
        });
        setDefaultHiddenKeys(hiddenKeys);
        setReportsFunnelType(viewType)
      }
      
    }, [viewType,resultSet, drrView , onKeyChange]);

  
    useEffect(()=>{
      if(viewType!=='Comparison View') {
        // When the view will get change also change the date difference.
        const startDate = formatDate(resultSet.loadResponse.pivotQuery.timeDimensions[0].dateRange[0])
        const endDate = formatDate(resultSet.loadResponse.pivotQuery.timeDimensions[0].dateRange[1])     
        const isDate = window.sessionStorage.getItem('dateDiff')
        if(isDate) {
          setDateDifference(isDate)
        }
        setReportsTime([[startDate, endDate]])
      }
    },[viewType])
  
    function setViewTypeAction(value) {
      if(value?.includes("Comparison")) {
        getHeadingsOnViewChange(headings)
      }
      setViewType(value)
      setDefaultHiddenKeys([])
      setTableColumns(tableColumnsCalculated)
      setDataSource(dataSource) // set the existing datasource again.
    }
    
    const startDate = formatDate(resultSet.loadResponse.pivotQuery.timeDimensions[0].dateRange[0]);
    const endDate = formatDate(resultSet.loadResponse.pivotQuery.timeDimensions[0].dateRange[1]);
    var daysDifference = previousDaysDifference([startDate, endDate])
    function getTotals() {
      if(Object.keys(summaryTotal).length > 0) {
        totals = summaryTotal
      }
     else if (dataSource.length > 0 && Object.keys(totals).length === 0) {
        Object.values(dataSource[0])?.map((totalrow, index) => {
          totals[keyExtractor(tableColumns[index].key)] = dataSource
            .reduce((acc, obj) => {
              let key = tableColumns[index].key;
              return acc + parseFloat(obj[key]);
            }, 0)
            .toFixed(page!=="marketing-stream" ? 0 : 2); // For marketing stream upto 2 decimal places
        });
      }
    }
    
    const getCalculatedValue = (key) => {
      getTotals();
        if (summaryMapping[key]) {
          const { numeratorKey, denominatorKey, multiplier, symbol, decimal } = summaryMapping[key];
          let numerator;
          if(denominatorKey === "dateDiff") {
             numerator = parseFloat(getDivideData(totals[numeratorKey], daysDifference));
          } else {
             numerator = parseFloat(getDivideData(totals[numeratorKey], totals[denominatorKey]));
          }
          const result = isNaN(numerator) ? "-" : (numerator * (multiplier || 1));
          let resultValue = parseFloat(result);
          if(decimal === 0) {
            resultValue = numeral(resultValue).format(format)
          } else {
            resultValue = numeral(resultValue).format(formatDecimal)
          }
          return resultValue + (symbol ? "%" : "")
      } else {
        return isNaN(totals[key]) ? "-" : (page!=="marketing-stream" ?numeral(totals[key]).format(format):numeral(totals[key]).format(formatDecimal));
      }
    };
  
    const loadMoreData = () => {
      const newData = dataSource.slice(startIdx, startIdx + pageSize);
      setVisibleData([...visibleData, ...newData]);
      setStartIdx(startIdx + pageSize);
    };
  
    const handleScroll = () => {
      if (tableRefScroll.current) {
        const { scrollTop, scrollHeight, clientHeight } = tableRefScroll.current;
        if (scrollTop === 0) {
          if (startIdx >= pageSize) {
            // const newData = dataSource.slice(startIdx-pageSize, startIdx);
            // setVisibleData([...newData]);
            // setStartIdx(startIdx - pageSize);
            // commenting out this startIdx as this is causing startIdx to revert back to (startIdx-pageSize) records when we again scroll to top of the table  and when we scroll down to bottom of it then it takes the already fetched records from data source causing data repeating again.
          }
        } else if (scrollTop + clientHeight >= scrollHeight - 100) {
          if (startIdx  < dataSource.length && visibleData?.length<totalRecords && lazyLoading===false) {
            loadMoreData();
          } else if(startIdx + pageSize >= dataSource.length && dataSource.length < totalRecords && lazyLoading === false) {
            setLazyLoading(true);
            props.pagination(pagination)
          }
        }
      }
      
    };
  
    useEffect(() => {
      setExpandedRows([])
      setTableRefKey((prev)=> { return prev+1 })
      setLazyLoading(false);
      if(resultSet.loadResponse.pivotQuery.order) {
        setResultOrder({id:resultSet?.loadResponse?.pivotQuery?.order?.[0]?.[0],desc:resultSet?.loadResponse?.pivotQuery?.order?.[0]?.[1] === 'desc' ? true : false})
      }
      setPagination({limit:resultSet.loadResponse.pivotQuery?.limit,offset:resultSet.loadResponse.pivotQuery?.offset/resultSet.loadResponse.pivotQuery?.limit})
    },[resultSet])
  
    const handleSort = (index, title, type) => {
      setSortKey(index);
      setSortType(type);
      if(customClasses.includes("sortByApi")) {
        setStartIdx(0);
        setVisibleData([])
        props.sortData(title,type)
      } else {
        if(resultOrder.id === title) {
          setResultOrder((prev) => {
            let data = {...prev}
            data.desc = !data.desc
            return data
          })
        } else{
          setResultOrder({
            id:title,desc:true
          })
        }
        setIsLoaderVisible(true);
        sortByKeyIndex(index, type);
        setStartIdx(0);
        setVisibleData([]);
      }
    };
  
    function sortByKeyIndex(keyIndex, order) {
      const objectOrderKeys = tableColumns?.map((data) => data?.key);
      const key = objectOrderKeys[keyIndex];
    
      dataSource.sort((a, b) => {
        let valueA = a[key];
        let valueB = b[key];
    
        // Handle null or undefined values
        if (valueA == null) valueA = order === "asc" ? Infinity : -Infinity;
        if (valueB == null) valueB = order === "asc" ? Infinity : -Infinity;
    
        // Handle percentages
        if (typeof valueA === "string" && valueA.includes("%") && valueB.includes("%")) {
          valueA = parseFloat(valueA.replace("%", ""));
          valueB = parseFloat(valueB.replace("%", ""));
        }
        // Handle numeric strings
        else if (!isNaN(valueA) && !isNaN(valueB)) {
          valueA = parseFloat(valueA);
          valueB = parseFloat(valueB);
        }
        // Handle dates
        else if (!isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB))) {
          valueA = new Date(valueA).getTime();
          valueB = new Date(valueB).getTime();
        }
    
        // Compare values (string comparison for non-numeric values)
        if (typeof valueA === "string" && typeof valueB === "string") {
          return order === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        } else {
          return order === "asc" ? valueA - valueB : valueB - valueA;
        }
      });
    
      setIsLoaderVisible(false);
    }
    
    
    
  
    const handleChange = (event, value, type, index) => {
      if (type === "expend" || type === "campaign_format") {
        if (expandedRows.includes(value)) {
          setExpandedRows(prevExpandedRows => 
            prevExpandedRows.filter(expandedIndex => expandedIndex !== value)
          );
        } else {
          setExpandedRows(prevExpandedRows => [...prevExpandedRows, value]);
        }
      }
      event.stopPropagation();
      props.handleChange(value, type, index);
    };
  
    var otherRedirectString = ["Category"];
    var redirectKeys = tableColumns.map(function (o, index) {
      if (otherRedirectString.includes(o.shortTitle)) {
        return index;
      }
    });
    redirectKeys = redirectKeys.filter(function (item) {
      return item !== undefined;
    });
    var linkedKeys = tableColumns.map(function (o, index) {
      if (linkedStrings.includes(o.shortTitle)) {
        return index;
      }
    });
    linkedKeys = linkedKeys.filter(function (item) {
      return item !== undefined;
    });
    var expendableKeys = tableColumns.map(function (o, index) {
      if (expendableStrings.includes(o.shortTitle)) {
        return index;
      }
    });
    expendableKeys = expendableKeys.filter(function (item) {
      return item !== undefined;
    });
  
  
    var dateKey = tableColumns.map(function (o) { return o.shortTitle; }).indexOf("Date");
    var monthKey = tableColumns.map(function (o) { return o.shortTitle; }).indexOf("month");
  
    useEffect(() => {
      setHiddenKeys((prev) => {
        let data = [...prev];
        data.push(
          tableColumnsCalculated
            .map(function (o) {
              return o.shortTitle;
            })
            .indexOf("month_numeric")
        );
        return data;
      });
      
      setExpandedRows([])
      setTableColumns(tableColumnsCalculated);
      setDataSource(dataSourceCalculated);
    }, [resultSet]);
  
    
  
    const { onDownload } = useDownloadExcel({
      currentTableRef: tableRef.current,
      filename:
        new Date().toLocaleDateString("en-US", {
          day: "numeric",
          year: "numeric",
          month: "long",
        }) + window.location.pathname,
      sheet: viewType,
    });
  
    useEffect(() => {
      if (hideImages) {
        onDownload();
        setHideImages(false);
      }
    }, [hideImages]);
  
    function onDownloadReady() {
      setHideImages(true);
    }
  
    const onDownloadComplete = () => {
      console.log("downalod complete")
    };
    var imageDataKeys = tableColumns.map(function (o, index) {
      if (o.meta?.image) {
        return index;
      }
    }).filter(function (item) {
      return item !== undefined;
    });
    useEffect(()=> {
      // if(customClasses.includes("sortByApi")) {
      //   setTotalRecords(resultSet.loadResponse?.results[0]?.total)
      // } else {
      //   setTotalRecords(dataSource.length)
      // }
      
      setTotalRecords(resultSet.loadResponse?.results[0]?.total ?? dataSource.length)
    },[dataSource])

    function shuffleObjectKeys(originalObject, keyOrderArray) {
      const shuffledObject = {};
      const remainingKeys = { ...originalObject };
    
      keyOrderArray.forEach((key) => {
        // if (originalObject.hasOwnProperty(key)) {
          shuffledObject[key] = originalObject[key];
          delete remainingKeys[key];
        // }
      });
    
      // // Add any remaining keys not present in the keyOrderArray
      // // Object.keys(remainingKeys).forEach((key) => {
      // //   shuffledObject[key] = remainingKeys[key];
      // // });
    
      return shuffledObject;
    }
    function convertToFilterFormat(oldFormat,parsedData) {
      // Map oldFormat to newFormat
      oldFormat.forEach(({ member, operator, values }) => {
          const key = keyExtractor(member); // Get the key name, e.g., "cost" from "VariantAnalysisTable.cost"
          
          if (parsedData[key]) {
              if (operator === "gte") {
                parsedData[key].min = values[0].toString();
              } else if (operator === "lte") {
                parsedData[key].max = values[0].toString();
              }
          }
      });
  
      return parsedData;
  }

    const hoverTxt = "Category not mapped as few advertised asins are not added in catalog";
  
    var tableBody = (
      <>
        <div className="campaign-list-table-data">
          <Stack className="campaign-list-main-table">
            <div
              className={
                "main-targeting-table-body-container listinng-table " +
                customClasses +
                "-table-wrapper"
              }
              ref={tableRefScroll}
              onScroll={handleScroll}
            >
              {tableColumns?.length ? (<>
                <table className={"mt-table " + customClasses} ref={tableRef} key={tableRefKey} >
                  <thead className="fixed-table">
                    <tr className="main-table-row-fixed">
                      {tableColumns?.map((row, mainIndex) => {
                        return (
                          <>
                            {hideImages && hiddenKeys.includes(mainIndex) ? (
                              ""
                            ) : (
                              <th
                                className={
                                  mainIndex === 0 && row.key === resultOrder.id
                                    ? "targetinng-second-column targetinng-table-heads selected"
                                    : mainIndex === 0
                                    ? "targetinng-second-column targetinng-table-heads"
                                    : hiddenKeys.includes(mainIndex)
                                    ? "d-none"
                                    : row.key === resultOrder.id
                                    ? "targetinng-table-heads selected"
                                    : "targetinng-table-heads"
                                }
                                style={{
                                  zIndex: mainIndex === 0 ? 99 : "",
                                }}
                              >
                                <div className="d-flex gap-1 align-items-center">
                                  <div>{humanize(row.shortTitle)}</div>
                                  <div
                                    className={
                                      row.key === resultOrder.id
                                        ? "cursor-pointer-global sortSelected"
                                        : "cursor-pointer-global"
                                    }
                                  >
                                    {!hideImages && (
                                      <img onClick={() => handleSort(mainIndex,row.key,(row.key === resultOrder.id) ? resultOrder.desc ? 'asc' : 'desc' : 'desc')}
                                      className={row.key === resultOrder.id ? "searchIcon sortSelected" :"searchIcon"}
                                      src={(row.key === resultOrder.id) ? resultOrder.desc ? descendingIconSvg : ascendingIconSvg : ascendingIconSvg}
                                      alt="ASC"
                                    />
                                    )}
                                  </div>
                                </div>
                              </th>
                            )}
                            {displayType === "campaign_format" &&
                            expendableKeys.length > 0 &&
                            mainIndex === 0 ? (
                              <th className="targetinng-table-heads">
                                <div className="d-flex gap-1">
                                  <div>{humanize(children_1_header)}</div>
                                </div>
                              </th>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </tr>
                  </thead>
                  {dataSource?.length ? (
                    <tbody className="another-tbody">
                      <tr
                        onClick={() => handleCellClick("summary")}
                        className={
                          selectedRows.includes("summary")
                            ? "highlight cursor-pointer summary-table-row-fixed"
                            : "cursor-pointer summary-table-row-fixed"
                        }
                      >
                        {tableColumns?.map((data) => data?.key)?.map((keyName, index) => {
                          return (
                            <React.Fragment key={index + "total"}>
                              {hideImages && hiddenKeys.includes(index) ? (
                                ""
                              ) : (
                                <>
                                  {index === 0 ? (
                                    <td className={"targetinng-second-column " + props.page}>
                                      <big className="font-weight-bold tableSummary">
                                        Summary
                                      </big>
                                    </td>
                                  ) : (
                                    <>
                                      <td
                                        className={
                                          hiddenKeys.includes(index)
                                            ? "d-none"
                                            : ""
                                        }
                                      >
                                        <big className="font-weight-bold tableSummary">
                                          <span className="d-none"></span>
                                          {resultSet?.loadResponses?.[0]?.summary ?  resultSet?.loadResponses?.[0]?.summary[keyName] ?? "-" : getCalculatedValue(
                                            keyExtractor(keyName)
                                          )}
                                        </big>
                                      </td>
                                    </>
                                  )}
                                  {displayType === "campaign_format" &&
                                  expendableKeys.length > 0 &&
                                  index === 0 ? (
                                    <td></td>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </tr>
                      {visibleData?.map((tr, index1) => {
                        const objectOrderKeys = tableColumns?.map((data) => data?.key)
                        // This function will re-order the object according to given array.
                        const modifiedObject = shuffleObjectKeys(tr,objectOrderKeys);
                        return (
                          <React.Fragment key={index1 + 1}>
                            <tr
                              index={index1 + "-row"}
                              className={
                                selectedRows.includes(index1 + "data")
                                  ? "highlight cursor-pointer"
                                  : "cursor-pointer"
                              }
                            >
                              {Object.entries(modifiedObject).map(([datakey, td], columnindex) => {
                                const isHoverCondition = (page==='category' || page==='expendable') && columnindex === 0 && !td
                              return  <>
                                  {hideImages &&
                                  hiddenKeys.includes(columnindex) ? (
                                   
                                    ""
                                  ) : (
                                    <>
                                      <td
                                        onClick={() =>
                                          handleCellClick(index1 + "data")
                                        }
                                        title={ isHoverCondition ? hoverTxt : null}
                                        index={columnindex + "-column"}
                                        className={
                                          columnindex === 0
                                            ? "targetinng-second-column "+ props.page
                                            : hiddenKeys.includes(columnindex)
                                            ? "d-none"
                                            : ""
                                        }
                                      >
                                        {columnindex === dateKey &&
                                        !linkedKeys.includes(columnindex) ? (
                                          displayDate(td,'date')
                                        ) : columnindex === monthKey &&
                                          !linkedKeys.includes(columnindex) ? (
                                            displayDate(td,'month')
                                          ) : linkedKeys.includes(columnindex) &&
                                          expendableKeys.includes(columnindex) &&
                                          columnindex === 0 ? (
                                          <>
                                            <div className="d-flex gap-1 align-items-center justify-content-between">
                                              <div>
                                                {columnindex === dateKey
                                                  ? displayDate(td,'date') : columnindex === monthKey ? displayDate(td,'month') : td}
                                              </div>
                                              <div className="d-flex">
                                                {!hideImages ? (
                                                  <>
                                                    <div className="selected-icon-hover"
                                                      onClick={(event) =>
                                                        handleChange(
                                                          event,
                                                          td,
                                                          "expend",
                                                          index1
                                                        )
                                                      }
                                                    >
                                                      <img
                                                        className="tdIcon"
                                                        src={ expandedRows.includes(td) ? MinusIcon : AddIcon}
                                                        alt="action"
                                                      />
                                                    </div>
                                                    <div className="icon-hover"
                                                      onClick={(event) =>
                                                        handleChange(
                                                          event,
                                                          td,
                                                          "link",
                                                          index1
                                                        )
                                                      }
                                                    >
                                                      <img
                                                        className="tdIcon"
                                                        src={externalLink}
                                                        alt="link"
                                                      />
                                                    </div>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                          </>
                                        ) : linkedKeys.includes(columnindex) ? (
                                          <>
                                            <div
                                              className="d-flex gap-1 align-items-center justify-content-between"
                                              onClick={(event) =>
                                                handleChange(
                                                  event,
                                                  td,
                                                  displayType,
                                                  columnindex
                                                )
                                              }
                                            >
                                              <div>
                                                {columnindex === dateKey ? (
                                                  displayDate(td,'date')
                                                ) :columnindex === monthKey ? displayDate(td,'month') : (
                                                  <>
                                                    {redirectKeys.includes(
                                                      columnindex
                                                    ) ? (
                                                      <a
                                                        href={`/campaign-list?category=${td}`}
                                                        target={"_blank"}
                                                        rel="noreferrer"
                                                        className="dotted-redirect-link"
                                                        onClick={(e) =>
                                                          e.stopPropagation()
                                                        }
                                                      >
                                                        {td}
                                                      </a>
                                                    ) : (
                                                      td
                                                    )}
                                                  </>
                                                )}
                                              </div>
                                              <div className="icon-hover">
                                                {!hideImages && (
                                                  <img
                                                    className="tdIcon"
                                                    src={externalLink}
                                                    alt="link"
                                                  />
                                                )}
                                              </div>
                                            </div>
                                          </>
                                        ) : expendableKeys.includes(
                                            columnindex
                                          ) && columnindex === 0 ? (
                                          <>
                                            <div
                                              className="d-flex gap-1 align-items-center justify-content-between"
                                              onClick={(event) =>
                                                handleChange(
                                                  event,
                                                  td,
                                                  displayType,
                                                  index1
                                                )
                                              }
                                            >
                                              <div>{td}</div>
                                              <div className="selected-icon-hover">
                                                {!hideImages && (
                                                  <img
                                                    className="tdIcon"
                                                    alt="action"
                                                    src={expandedRows.includes(td) ? MinusIcon : AddIcon }
                                                  />
                                                )}
                                              </div>
                                            </div>
                                          </>
                                        )  : imageDataKeys.includes(columnindex) && td !== null  ?
                                        <>
                                        {getProductWithImage(td,hideImages)}
                                        </> 
                                        : <>
                                          {(metricStrings.includes(keyExtractor(datakey)) && ((keyExtractor(datakey).toLowerCase().includes("drr")) || page==="marketing-stream") ) ? numeral(td).format(formatDecimal) : metricStrings.includes(keyExtractor(datakey)) && page!=="marketing-stream" ? numeral(td).format(format) : td}
                                        </>}
                                        {/* { !hideImages && percentageShow ? <>
                                        {metricStrings.includes(keyExtractor(datakey)) ?
                                        td > 0 ? 
                                        "(" + numeral(((td / totals[keyExtractor(datakey)]) * 100)).format(format1Decimal) + "%)"
                                        : "(0%)"
                                        : ""
                                        }
                                        
                                        </> : <></>
                                        } */}

{!hideImages && percentageShow && metricStrings.includes(keyExtractor(datakey)) && (
    td > 0
    ? (() => {
        
        let percentage = "";
        if(resultSet?.loadResponses?.[0]?.summary) {
         percentage = (td / resultSet?.loadResponses?.[0]?.summary[datakey]) * 100;

        }else{
          percentage = (td / totals[keyExtractor(datakey)]) * 100;
        }
        const formattedPercentage = (isNaN(percentage) || percentage === Infinity) ? "(-)" : "(" + numeral(percentage).format(format1Decimal) + "%)";

        return formattedPercentage;
    })()
    : "(0%)"
)}
                                      </td>
                                    </>
                                  )}
                                  {displayType === "campaign_format" &&
                                  expendableKeys.length > 0 &&
                                  columnindex === 0 ? (
                                    <td
                                      onClick={() =>
                                        handleCellClick(index1 + "data")
                                      }
                                    >
                                      -
                                    </td>
                                  ) : (
                                    ""
                                  )}
                                </>
                            })}
                            </tr>
                            {expandedRows.includes(tr[objectOrderKeys[0]]) &&
                            !children_1.hasOwnProperty(tr[objectOrderKeys[0]]) ? (
                              <>
                                {Array.from({ length: 3 }, () => (
                                  <tr className="td-with-loader">
                                    {tableColumns?.map((row, mainIndex) => {
                                      return !hiddenKeys.includes(mainIndex) ? (
                                        mainIndex === 0 ? (
                                          <>
                                            <td>
                                              <SpinnerShimmer shimmer={true} />
                                            </td>
                                            <td>
                                              <SpinnerShimmer shimmer={true} />
                                            </td>
                                          </>
                                        ) : (
                                          <td>
                                            <SpinnerShimmer shimmer={true} />
                                          </td>
                                        )
                                      ) : null;
                                    })}
                                  </tr>
                                ))}
                              </>
                            ) : null}
                            {expandedRows.includes(tr[objectOrderKeys[0]]) &&
                            (children_1 && children_1.hasOwnProperty(tr[objectOrderKeys[0]]))  ? (
                            <React.Fragment key={'child'+tr[objectOrderKeys[0]]+index1}>
                              <ChildTableRenderer
                                resultSet={children_1[tr[objectOrderKeys[0]]]}
                                pivotConfig={pivotConfig}
                                customClasses={customClasses}
                                percentageShow={percentageShow}
                                displayType={displayType}
                                props={props}
                                viewType={viewType}
                                hiddenKeysParent={hiddenKeys}
                                itemList={headings}
                                hideImages={hideImages}
                                drrView={drrView}
                              />
                              </React.Fragment>
                            ) : (
                              ""
                            )}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  ) : (
                    <div>
                      <p>NO DATA FOUND</p>
                    </div>
                  )}
                </table>
                {lazyLoading && (
                  <div className="lazy-loading-container d-flex p-5 justify-content-center align-items-center w-100">
                  <LazyLoadSpinner text={`Loading ${pagination.limit} More Items...`} />                </div>
                )}
              </>) : (
                <div>
                  <p>NO DATA FOUND</p>
                </div>
              )}
            </div>
          </Stack>
        </div>
      </>
    );
    if (FunnelViews.length > 0) {
      var radioValues = FunnelViews;
    } else {
      var radioValues = defaultRadioValues
    }
  
    const overviewSettingBtnHandler = () => {
      setIsVisible(!isVisible);
    };
  
    useEffect(() => {
      const filterHeadings = {};
      for (let i = 0; i < tableColumns.length; i++) {
        const item = tableColumns[i];
        if (allMetrics.includes(keyExtractor(item.key))) {
          filterHeadings[keyExtractor(item.key)] = {
            value: humanize(item.shortTitle),
            min: tempHeadings[keyExtractor(item.key)]?.min,
            max: tempHeadings[keyExtractor(item.key)]?.max,
            enable: !hiddenKeys.includes(i),
          };
        }
      }
      setHeadings(filterHeadings);
      setFilterHeadings(JSON.parse(JSON.stringify(filterHeadings)));

    }, [defaultHiddenKeys,tempHeadings,predefinedHeadings]);

    useEffect(() => {
      // filling filter headings from urlQueryParams
      let searchParams = new URLSearchParams(window.location.search);
      const urlQueryFilter= JSON.parse(decodeURIComponent(searchParams.get("urlQuery") || "{}"));
      if (urlQueryFilter.length > 0) {
        convertToFilterFormat(urlQueryFilter, filterHeadings);
      }
    }, [filterHeadings]);

    const onClickHandler = (event, index, key) => {
      setHeadings((prev) => {
        let data = { ...prev };
        data[key].enable = event.target.checked;
        data[key].min = "";
        data[key].max = "";
        return data;
      });
      setFilterHeadings((prev) => {
        let data = { ...prev };
        data[key].enable = event.target.checked;
        data[key].min = "";
        data[key].max = "";
        return data;
      });
      hideUnhideColumn(event.target.checked, key);
    };
  
    function hideUnhideColumn(value, key) {
      if (value) {
        setHiddenKeys((prev) => {
          let data = [...new Set(prev)];
          const objWithIdIndex = data.indexOf(
            tableColumns
              .map(function (o) {
                return keyExtractor(o.key);
              })
              .indexOf(key)
          );
          data.splice(objWithIdIndex, 1);
          return data;
        });
      } else {
        setHiddenKeys((prev) => {
          let data = [...new Set(prev)];
          data.push(
            tableColumns
              .map(function (o) {
                return keyExtractor(o.key);
              })
              .indexOf(key)
          );
          return data;
        });
      }
    }
    const inputValueChange = (event, type, key) => {
      setFilterHeadings((prev) => {
        let data = { ...prev };
        data[key][type] = event;
        return data;
      });
    };
    const handleDrop = (droppedItem) => {
      if (!droppedItem.destination) return;
      var updatedList = [...itemList];
      const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
      updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
      dragDropColumn(updatedList);
    };
  
    function dragDropColumn(updatedList) {
      const orderedObj = {};
      updatedList.forEach((key) => {
        if (headings[key] !== undefined) {
          orderedObj[key] = headings[key];
        }
      });
      var orderedArr = updatedList.map((key) =>
        tableColumns.find((obj) => keyExtractor(obj.key) === key)
      );
      orderedArr = orderedArr.filter(item => item !== null && item !== undefined);    
      const notFoundArr = tableColumns.filter(
        (obj) => !updatedList.includes(keyExtractor(obj.key))
      );
      const finalArr = [...notFoundArr, ...orderedArr];
      
      let arrangedDataSource = dataSource?.map((tr, index1) => {
        let foundData = updatedList.reduce((acc, key) => {
          const entry = Object.entries(tr).find(
            ([k, v]) => keyExtractor(k) === key
          );
          if (entry) {
            acc[entry[0]] = entry[1];
          }
          return acc;
        }, {});
        let notfoundData = Object.entries(tr)
          .filter(([k, v]) => !updatedList.includes(keyExtractor(k)))
          .reduce((acc, [k, v]) => {
            acc[k] = v;
            return acc;
          }, {});
        const mergedObj = { ...notfoundData, ...foundData };
        return mergedObj;
      });
      setDataSource(arrangedDataSource);
      setHeadings(orderedObj);
      setFilterHeadings(JSON.parse(JSON.stringify(orderedObj)))
      setItemList(updatedList);
      setTableColumns(finalArr);
    }
  
    useEffect(() => {
      if (viewType === "Custom View" && runHidden === false) {
        (async () => {
          var key = "reports_config_with_drr"
          if(page === "performance-overview") {
            key = "reports_config_without_drr"
          }
          const result = await getCustomView(clientId,key);
          let output = result.value;
          if (
            output !== undefined &&
            output !== null &&
            output !== "" &&
            Object.entries(output).length > 0
          ) {
            await dragDropColumn(Object.keys(output));
            setRunHidden(true);
          }
        })();
      }
    }, [viewType,CurrentViewType]); // Only run when the viewType or current viewType gets changed! Not on all props.
  
    useEffect(() => {
      if (viewType === "Custom View") {
        (async () => {
          var key = "reports_config_with_drr"
          if(page === "performance-overview") {
            key = "reports_config_without_drr"
          }
          const result = await getCustomView(clientId,key);
          let output = result.value;
          if (
            output !== undefined &&
            output !== null &&
            output !== "" &&
            Object.entries(output).length > 0
          ) {
            for (const key in output) {
              if (output.hasOwnProperty(key)) {
                output[key].min = tempHeadings[key]?.min;
                output[key].max = tempHeadings[key]?.max;
              }
              if (!output[key].enable) {
                hideUnhideColumn(output[key].enable, key);
              }
            }
          }
          setHeadings(output);
          setFilterHeadings(JSON.parse(JSON.stringify(output)))
        })();
        setRunHidden(false);
      }
    }, [runHidden]);
  
    const closeFilters = () => {
      setIsVisible(false);
      setFilterHeadings(JSON.parse(JSON.stringify(headings)))
    };
    const ref = useDetectClickOutside({ onTriggered: closeFilters });
    const applyFilter = () => {
      const parsedHeadings = JSON.parse(JSON.stringify(filterHeadings))
      setTempHeadings(parsedHeadings);
      setHeadings(JSON.parse(JSON.stringify(parsedHeadings)))
      if(page === "performance-overview") {
        saveCustomView(parsedHeadings, clientId, dataPresent,"reports_config_without_drr");
      } else {
        saveCustomView(parsedHeadings, clientId, dataPresent,"reports_config_with_drr");
      }
      
      props.dataFilter(parsedHeadings);
      setIsVisible(!isVisible);
      setStartIdx(0);
      setVisibleData([]);
    };
    const resetFilter = () => {
      let data = structuredClone(filterHeadings);
      Object.entries(data).map(([key,metric]) => {
      data[key].min = ""
      data[key].max = ""
      })
      setFilterHeadings(data);
    };
    const selectAll = (event) => {
      let data = structuredClone(filterHeadings);
      Object.entries(data).map(([key,metric]) => {
      data[key].enable = event.target.checked
      hideUnhideColumn(event.target.checked, key);
      })
      setFilterHeadings(data)
      setHeadings(JSON.parse(JSON.stringify(data)))
    }
    var tableFilterBody = (
      <>
        <div className="campaign-list-table-data">
          <Stack className="campaign-list-main-table">
            <div className={" reports-filter-table-wrapper filter"}>
              {tableColumns?.length ? (
                <table
                  className={
                    "table table reports-filter-table table-sm table-responsive"
                  }
                >
                  <thead className="fixed-table">
                  <tr className="text-center">
                    <th
                      className={viewType === "Custom View" ? "" : "d-none"}
                    >Action</th>
                    <th className={viewType === "Custom View" ? "" : "d-none"}>
                      <div className="d-flex gap-2 align-items-center">
                      <SwitchBtn
                        className="reports-toggle"
                        checked={
                          Object?.values(filterHeadings)?.every(metric => metric?.enable)? true : false
                        }
                        label={""}
                        id={"selectAll"}
                        onChange={(e) =>
                          selectAll(e)
                        }
                      ></SwitchBtn>
                      </div>
                    </th>
                    <th>Metric</th>
                    <th>Min</th>
                    <th>Max</th>
                  </tr>
                </thead>
                  <DragDropContext onDragEnd={handleDrop}>
                    <Droppable droppableId="list-container">
                      {(provided) => (
                        <tbody
                          className="another-tbody list-container"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{ overflowY: "auto" }}
                        >
                          {Object.keys(headings).length > 0 && <>
                          {Object.keys(filterHeadings).map((key, index) => (
                            <Draggable
                              key={key}
                              draggableId={key}
                              index={index}
                              isDragDisabled={
                                viewType === "Custom View" ? filterHeadings[key].enable ? false : true : true
                              }
                            >
                              {(provided) => (
                                <tr
                                  className="cursor-pointer"
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                >
                                  <td
                                    className={
                                      viewType === "Custom View" ? "" : "d-none"
                                    }
                                  >
                                    <img src={dragIcon} className="filterIcon" alt="filter" />
                                  </td>
                                  <td
                                    className={
                                      viewType === "Custom View" ? "" : "d-none"
                                    }
                                  >
                                    <SwitchBtn
                                      className="reports-toggle"
                                      checked={
                                        headings[key]?.enable ? true : false
                                      }
                                      label={""}
                                      id={key}
                                      onChange={(e) =>
                                        onClickHandler(e, index, key)
                                      }
                                    ></SwitchBtn>
                                  </td>
                                  <td>{headings[key].value}</td>
                                  <td>
                                    <div className="d-flex gap-2">
                                      {/* <div>Min</div> */}
                                      <div className="d-flex justify-center actionn-text">
                                        <input
                                          className="form-control-sm"
                                          type="number"
                                          inputMode="number"
                                          disabled={!filterHeadings[key].enable}
                                          value={filterHeadings[key].min}
                                          onChange={(e) =>
                                            inputValueChange(
                                              e.target.value,
                                              "min",
                                              key
                                            )
                                          }
                                          id={key + "min"}
                                        ></input>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex gap-1">
                                      {/* <div>Max</div> */}
                                      <div className="d-flex justify-center actionn-text">
                                        <input
                                          className="form-control-sm"
                                          type="number"
                                          inputMode="number"
                                          disabled={!filterHeadings[key].enable}
                                          value={filterHeadings[key].max}
                                          onChange={(e) =>
                                            inputValueChange(
                                              e.target.value,
                                              "max",
                                              key
                                            )
                                          }
                                          id={key + "max"}
                                        ></input>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))}
                          </>}
                          {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </table>
              ) : (
                <div></div>
              )}
            </div>
          </Stack>
        </div>
      </>
    );
    useEffect(() => {
      if(drrView === '') {
      } else {
        props.drrView(drrView,'parent')
      }
      setExpandedRows([])
      setSelectedRows([])
  },[drrView])
  
    function searchBoxvalue(data) {
      props.handleSearch(data)
    }
    return (
      <>
      <Loader visible={isLoaderVisible}></Loader>
  
      <div className="campaign-list-table-data">
      <div className="d-flex align-items-center justify-content-between my-3">
        <div className="d-flex gap-2">
          {props.drrView && viewType !== "Custom View" && <>
                  <div className="data-toggle-buttons">
                  <button disabled={!!shimmerEffect} className={`${(drrView === "aggregate" || drrView === "") ? "primary-active-btn " : "primary-inactive-btn "} ${!!shimmerEffect ? "disabled" :""}`} onClick={()=>{setDrrView('aggregate')}}>Aggregate View</button>
                  </div>
                  <div className="data-toggle-buttons">
                  <button disabled={!!shimmerEffect} className={`${drrView === "drr" ? "primary-active-btn " : "primary-inactive-btn "} ${!!shimmerEffect ? "disabled" :""}`}  onClick={()=>{setDrrView('drr')}}>DRR View</button>
                  </div>
                  </>}
        </div>
        <div className="paginationtext">Showing {dataSource.length}  Out Of {totalRecords} Records</div>
      </div>
        <div className="d-flex justify-content-between align-items-center  mt-1 w-100 gap-3">
          <div className="reports d-flex gap-2">
          {   
            radioValues.map((radio, index) => {
              return (
                <div className="data-toggle-buttons">
                  <button disabled={!!shimmerEffect} className={`${viewType === radio ? "primary-active-btn " : "primary-inactive-btn "} ${!!shimmerEffect ? "disabled" :""}`} id="all-btn" onClick={()=>{setViewTypeAction(radio)}}>{radio}</button>
                  </div>
              )
           })
          }
          </div>
          {showFilter && <SearchWithSelect Value={searchBoxvalue} default={showFilter} /> }
          <div className="d-flex">
          {props?.showCustomFilterBtn && <div className="d-flex align-items-center gap-1">
                                    <button
                                        className={`primary-inactive-btn d-flex align-items-center`}
                                        data-tooltip-content={"Create Product Set with existing filters"}
                                        data-tooltip-id={"create-product-set-tooltip"}
                                        id="quick-filters-btn"
                                        onClick={()=>props?.handleFilterNavigate("PRODUCT_SET")}
                                      >
                                        Create Product Set
                                      </button>
                                      <button
                                            className={
                                            `primary-inactive-btn d-flex align-items-center`
                                            }
                                            data-tooltip-content={`Create Stop Loss Rule with existing filters`}
                                            data-tooltip-id="stoploss-tooltip"
                                            id="download-report-btn"
                                            onClick={()=>props?.handleFilterNavigate("STOP_LOSS")}
                                            >
                                           Create StopLoss
                                        </button>
                                 </div>}
          <div className="position-relative mx-2" ref={ref}>
            {props?.showCustomFilterBtn && <button
             style={{position:"relative"}}
              className="primary-inactive-btn apply-filter d-flex align-items-center"
              onClick={()=>{props?.handleCustomBtnClick()}}
            >
              <div className="d-flex gap-2 align-items-center">
                {" "}
                <img
                  src={overviewSeiingIcon}
                  className="cursor-pointer-global filterIcon"
                  alt="overview-settings-tile"
                ></img>{" "}
                <div >Filters
                {props?.isFilterApplied ? (
                  <div className="filter-applied">
                </div>
                ) : null}
              </div>
              </div>
            </button>}
            {(customClasses.includes("noFilters"))  ? <></> :
            <button
              className="primary-inactive-btn apply-filter d-flex align-items-center"
              onClick={overviewSettingBtnHandler}
            >
              <div className="d-flex gap-2 align-items-center">
                {" "}
                <img
                  src={overviewSeiingIcon}
                  className="cursor-pointer-global filterIcon"
                  alt="overview-settings-tile"
                ></img>{" "}
                <div>Filters</div>
              </div>
            </button>
            }
            {isVisible && (
              <div
                className=""
                style={{
                  position: "absolute",
                  right: "120px",
                  zIndex: "9999",
                  top: "-16px",
                }}
              >
                <div className="tooltip-arrow"></div>
                <div className="shadow-lg p-1 mb-5 rounded tooltip-content">
                  <div className="d-flex justify-content-between align-items-center py-3 px-3">
                    <div className="text-left">Filter Logic</div>
                    <div className="d-flex gap-2 align-items-center">
                      <button className="primary-inactive-btn"  onClick={resetFilter}>Reset</button>
                      <Button
                        size="sm"
                        className="primary-active-btn"
                        onClick={applyFilter}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                  <div className="bg-white py-2 rounded">{tableFilterBody}</div>
                </div>
              </div>
            )}
          </div>
          <div>
            <Download pageName={pageName} query={resultSet?.loadResponse?.pivotQuery} page={page} type={'normal'} onDownload={onDownloadComplete} isWrapperApi={resultSet?.isWrapperApi} customDownloadURL={customDownloadURL}/> 
          </div>
          </div>
          
        </div>
        <Stack className="campaign-list-main-table">
          <TableRb noDataFound={!dataSource?.length} tableHeads={tableColumns} shimmer={shimmerEffect} table={tableBody} hiddenKeys={hiddenKeys}></TableRb>
        </Stack>
      </div>
      </>
    );
  };
  