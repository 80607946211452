import axios from "axios";
import { encodeData } from "../common/encodeData";

export function getClientAccountDetails (clientData = {}) {
    const setupKey = (clientData?.flipkart_setup && clientData?.flipkart_setup?.profile_id) ? 'flipkart_setup' : 'myntra_setup'
    const accountName =  setupKey === 'flipkart_setup' ?  clientData[setupKey]?.brand_name: clientData[setupKey]?.brand_name
    const profileId = clientData[setupKey]?.profile_id
    const accountType =  setupKey === 'flipkart_setup' ?  clientData[setupKey]?.account_type: clientData[setupKey]?.account_type ?? setupKey
    return {accountName,setupKey,profileId,accountType};
}

export const getPlatformStatus = (clientData) => {
    let myntraStatus = false
    let flipkartStatus = false
    let amazonStatus = false
    if(clientData?.myntra_setup && clientData?.myntra_setup?.profile_id) {
      myntraStatus = true
    }
    if(clientData?.flipkart_setup && clientData?.flipkart_setup?.profile_id) {
      flipkartStatus = true
    }
    if(clientData?.isAmazonSetup) {
      amazonStatus = true
    }
    return {myntraStatus,flipkartStatus,amazonStatus}
  }



export const FlipkartMyntraDataKeys = {
    'Day':'p_gmjx3texgd',
    'Week':'p_tev66wexgd',
    'Month':'p_rqry8yexgd',
    'Campaign':'p_oybyh5dxgd',
    'Targeting':'p_dey0ghkwgd',
    'FSN Report':'p_eaucr3voed',
    'Search Term':'p_e3dkxkwoed',
    'Placement':'p_0ut21iwoed',
    'Product':'p_eywmhuiihd',   
}

export const generateIFrameReportUrl = (platform,type='Day',clientId) => {
    const BASE_URL = `https://lookerstudio.google.com/embed/u/0/reporting`
    const flipKartKey = '37b37b6d-17fd-4a8a-89e0-16c5b3316c13'
    const myntraKey = 'ebb015a7-decf-4ab9-ab1e-fd4dcc988158'
    const setupKey = platform
    const reportKey = setupKey === 'flipkart' ? flipKartKey : myntraKey
    const flipKartData = {"ds22.parse_id": clientId,"ds1.parse_id": clientId,"ds156.parse_id": clientId,"ds39.parse_id": clientId,"ds52.parse_id": clientId,"ds45.parse_id": clientId}
    const myntraData = {"ds297.parse_id": clientId,"ds384.parse_id": clientId}
    const params = setupKey === 'flipkart' ? flipKartData : myntraData
    const pageKey = FlipkartMyntraDataKeys[type]
    const url = `${BASE_URL}/${reportKey}/page/${pageKey}?params=${encodeData(params)}`
    return url
  }


  export const getClientDataFromAdyogiAPI = async (passedClientId) => { 
    try {
      const url = `${process.env.REACT_APP_LOGIN_API}/api/amzn/getClientData?clientId=${passedClientId}`;
      const config = {
        method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${process.env.REACT_APP_AUTHORIZATION_FOR_NORMAL_USER}`
            }
      }
      const data = await axios.get(url,config);
      if(data?.status === 200) {
        return data;
      }
    } catch (error) {
      console.log(error)
    }
  }