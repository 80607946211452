import {
  FiltersCampaignDataQuadrant,
  FiltersDimensionDataQuadrant,
  FiltersMeasuresDataQuadrant,
} from "../../../../components/Filter/FilterData/quadrantFilter";
import styles from "./FilterViewer.module.css";

// Utility function to map operator to a human-readable format
const operatorMapping = {
  equals: "Equals",
  notEquals: "Not Equals",
  gt: "Greater Than",
  gte: "Greater Than or Equal",
  lt: "Less Than",
  lte: "Less Than or Equal",
  contains: "Contains",
  notContains: "Not Contains",
  startsWith: "Starts With",
  notStartsWith: "Not Starts With",
  notNull: "Exist",
  isNull: "Not Exist",
  afterDate: "After Date",
  afterOrOnDate: "After or On Date",
  beforeDate: "Before Date",
  beforeOrOnDate: "Before or On Date",
};


// Utility function to parse and convert filters to a readable string
const parseFilters = (filter, fieldMapping) => {
  if (!filter) return '';

  if (filter.type === 'RULE_ITEM') {
    if (filter.member?.includes('profileId')) return '';

    const fieldName = fieldMapping[filter.member] || filter.member;
    const operator = operatorMapping[filter.operator] || filter.operator;
    const values = filter.values.join(', ');
    return `${fieldName} ${operator} ${values}`;
  }

  const conjunction = filter.type === 'AND_RULE' ? '<b>AND</b>' : '<b>OR</b>';
  const conditions = filter.and || filter.or;
  const parsedConditions = conditions
    .map((condition) => parseFilters(condition, fieldMapping))
    .filter(Boolean); // Remove empty strings

  return parsedConditions.length > 0
    ? `(${parsedConditions.join(` ${conjunction} `)})`
    : '';
};

const FilterPreview = ({ filters }) => {
  // Field mapping to make member names more readable
  //   const fieldMapping = {
  //     'VariantAnalysisTablePPMPlaceholder.cost': 'Cost',
  //     'VariantAnalysisTablePPMPlaceholder.profileId': 'Profile ID',
  //     'VariantAnalysisTablePPMPlaceholder.campaignFormat': 'Campaign Format',
  //   };
  const userPlatform = JSON.parse(localStorage.getItem("user_platform"));
  let fieldMapping = {};
  FiltersMeasuresDataQuadrant[userPlatform].fields.forEach((field) => {
    fieldMapping[field.id] = field.name;
  });
  FiltersDimensionDataQuadrant[userPlatform].fields.forEach((field) => {
    fieldMapping[field.id] = field.name;
  });
  FiltersCampaignDataQuadrant[userPlatform].fields.forEach((field) => {
    fieldMapping[field.id] = field.name;
  });

  let measuresPreview = "";
  let dimensionsPreview = "";
  let campaignAmazonPreview = "";
  let topXPreview = "";
  if (filters?.measures) {
    measuresPreview = parseFilters(filters.measures, fieldMapping);
  }
  if (filters?.dimensions) {
    dimensionsPreview = parseFilters(filters.dimensions, fieldMapping);
  }
  if (filters?.campaigns) {
    campaignAmazonPreview = parseFilters(filters.campaigns, fieldMapping);
  }
  if (filters?.topX) {
    topXPreview = parseFilters(filters.topX, fieldMapping);
  }

  return (
    <div>
      {measuresPreview || dimensionsPreview || campaignAmazonPreview || topXPreview ? (
        <>
          {measuresPreview && (
            <>
              {" "}
              <p className={styles.previewHeading}>Measures</p>
              <div className={styles.previewInnerBox}>
                <p
                  className={styles.previewText}
                  dangerouslySetInnerHTML={{ __html: `${measuresPreview}` }}
                ></p>
              </div>
            </>
          )}
          {dimensionsPreview && (
            <>
              <p className={styles.previewHeading}>Dimensions</p>
              <div className={styles.previewInnerBox}>
                <p
                  className={styles.previewText}
                  dangerouslySetInnerHTML={{ __html: `${dimensionsPreview}` }}
                ></p>
              </div>
            </>
          )}
          {campaignAmazonPreview && (
            <>
              {" "}
              <p className={styles.previewHeading}>Campaign Amazon</p>
              <div className={styles.previewInnerBox}>
                <p
                  className={styles.previewText}
                  dangerouslySetInnerHTML={{
                    __html: `${campaignAmazonPreview}`,
                  }}
                ></p>
              </div>
            </>
          )}
          {topXPreview && (
            <>
              {" "}
              <p className={styles.previewHeading}>Narrow Down</p>
              <div className={styles.previewInnerBox}>
                <p
                  className={styles.previewText}
                  dangerouslySetInnerHTML={{
                    __html: `${topXPreview}`,
                  }}
                ></p>
              </div>
            </>
          )}
        </>
      ) : (
        <p>Not Applied</p>
      )}
    </div>
  );
};

export default FilterPreview;

// Example usage:
// const filters = {
//   measures: { ... },
//   dimensions: { ... },
//   campaigns: { ... }
// };
// <FilterPreview filters={filters} />
