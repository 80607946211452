import React, { useEffect, useState } from 'react'
import { useGlobalContext } from '../../../context/global'
import {
  FiltersCampaignDataQuadrant,
    FiltersDimensionDataQuadrant,
    FiltersMeasuresDataQuadrant,
    useTopXDataConfig,
  } from "../../../components/Filter/FilterData/quadrantFilter";
import { processFields } from "../../../components/Filter/subComponents/filterHelper";
import styles from '../subComponents/productSetFilters.module.css'
import FilterWidget from "../../../components/Filter/Widget";
import arrowUpIcon from "../../../assets/icons/arrow_up_icon.svg";
import arrowDownIcon from "../../../assets/icons/down_arrow.svg";
import CheckboxRb from '../../../components/Buttons/checkbox/checkbox';
import CustomDropDown from '../../../components/DropDown/CustomDropDown';
import dropdownIcon from "../../../assets/icons/downArrowForSplitButton.svg";
import CustomTextField from '../../../components/Filter/subComponents/CustomTextField/CustomTextField';



const ProductSetFilters = (props) => {
    const {clientId} = useGlobalContext();
    const TopXDataConfig = useTopXDataConfig();
    const userPlatform = JSON.parse(localStorage.getItem("user_platform"));
    const {handleProductSetDetail} = props
    const [isExpanded,setIsExpanded] = useState(true);
    const [errors, setErrors] = useState(props?.errors);
    const PRODUCT_SET_DATE_OPTIONS = [
      { value: "last_3d", label: "Last 3 days", type: "" },
      { value: "last_7d", label: "Last 7 days", type: "" },
      { value: "last_30d", label: "Last 30 days", type: "" },
    ];
    const [selectedDay, setSelectedDay] = useState(
      PRODUCT_SET_DATE_OPTIONS.find((option) => option?.value === props?.customDate)
    );
    const [productSetDetail, setProductSetDetail] = useState({
      ...props.productSetDetail,
    });

    useEffect(() => {
      setSelectedDay(
        PRODUCT_SET_DATE_OPTIONS.find((option) => option.value === props.customDate)
      );
    }, [props.customDate]);

    useEffect(() => {
      setProductSetDetail({ ...props.productSetDetail });
    }, [props.productSetDetail]);

    useEffect(() => {
        const init = async () => {
          const precessedDimensionData = await processFields(
            FiltersDimensionDataQuadrant[userPlatform].fields,
            clientId,
            []
          );
          FiltersDimensionDataQuadrant[userPlatform].fields = precessedDimensionData;
          const precessedMeasuresData = await processFields(
            FiltersMeasuresDataQuadrant[userPlatform].fields,
            clientId,
            []
          );
          FiltersMeasuresDataQuadrant[userPlatform].fields = precessedMeasuresData;
          const precessedAmazonData = await processFields(
            FiltersCampaignDataQuadrant[userPlatform].fields,
            clientId,
            []
          );
          FiltersCampaignDataQuadrant[userPlatform].fields = precessedAmazonData;
        };
        init();
      }, [clientId]);

      useEffect(() => {
        setErrors(props.errors);
      }, [props.errors]);

  const productSetName = productSetDetail?.name ?? '';
  const productSetRefreshFrequency = productSetDetail?.refresh_duration ?? '';
  const productSetDeDuplicateASIN = productSetDetail?.de_duplication ?? '';

  return (
    <>
       <div className={styles.pageFilterWrapper}>
        <div
          style={{
            display: "flex",
            padding: isExpanded ? "12px 24px" : "12px 24px",
            justifyContent: "space-between",
            borderBottom: isExpanded ? "1px solid #C9CCCF" : "0px",
          }}
        >
          <div style={{ fontSize: "16px", lineHeight: "1.3", color: "#000" }}>
            Apply Filters
          </div>
          <div
            onClick={(e) => setIsExpanded(!isExpanded)}
          >
            <img
              height={10}
              src={isExpanded ? arrowUpIcon : arrowDownIcon}
              alt="up"
            />
          </div>
        </div>
        <div
          style={{
            opacity: isExpanded ? 1 : 0,
            transform: isExpanded ? "scaleY(1)" : "scaleY(0)",
            transformOrigin: "center",
            transition: "opacity 0.3s ease-in-out, transform 0.2s ease-in-out",
            visibility: isExpanded ? "visible" : "hidden",
            maxHeight: isExpanded ? "max-content" : "0", // Adjust based on content's maximum height
          }}
        >
          <FilterWidget
            config={{
              campaigns: FiltersCampaignDataQuadrant[userPlatform],
              measure: FiltersMeasuresDataQuadrant[userPlatform],
              dimension: FiltersDimensionDataQuadrant[userPlatform],
              topXConfig: TopXDataConfig,
            }}
            filters={props.filterModuleFilters}
            output={props.handleFilterOutput}
            prefixMember={""}
            type="page"
            page={"product-sets"}
            // summary={"onApplied"}
            fetchingData={props.fetchingData}
            isApplied={props.handleFilterApplied}
            narrowFilters={true}
            daysOptions={PRODUCT_SET_DATE_OPTIONS}
            selectedDay={selectedDay}
            sidebar={true}
            default={"metrics"}
            showDays={true}
            id={"exclusion"}
            campaignFilter={true}
            handleSelectedDay={props.handleCustomDate}
            calloutTitle="Select your filters"
            calloutContent="+ Add Filter"
          />
        </div>
      </div>
    <div className="card-style-new py-4 mt-3">
    <p className="dropdown-text" style={{fontWeight:'bold'}}>Other Details</p>
        <div className="row">
          <div className="col-lg-6">
            <p className="dropdown-text">Product Set Name</p>
            <CustomTextField
              type="text"
              placeholder={'Enter Product Set Name'}
              value={productSetName}
              error={errors?.name ?? "" ? true : false}
              onChange={(e) => {
                handleProductSetDetail("name", e)
              } 
              }
              className="textBoxWrapper"
            />
          </div>
          <div className="col-lg-6">
          <p className="dropdown-text">Product Set Refresh Frequency</p>
                <CustomDropDown
                  title={""}
                  noTitle={true}
                  classes="w-100"
                  data={["1 Day", "3 Days", "7 Days", "14 Days", "21 Days"]}
                  filterCampaigns={(event) =>
                    handleProductSetDetail('refresh_duration',event?.target?.value)
                  }
                  targettingValue={productSetRefreshFrequency}
                  type={"-"}
                  src={dropdownIcon}
                  fontSize={"12px"}
                  btnBgColor="#DBD9E6"
                  borderRadius="0px 3px 3px 0px"
                  menuZindex={100}
                />
          </div>
        </div>
        <div className="d-flex align-items-center">
          <CheckboxRb className={"child"} onChange={() => handleProductSetDetail('de_duplication',!productSetDeDuplicateASIN)} checked={productSetDeDuplicateASIN} classes={"checkbox-container-nowidth"}/>
          <p className="mb-0 dropdown-text">De Duplication Of ASIN's</p>
        </div>
      </div>
      
      
    
    </>
  )
}

export default ProductSetFilters
