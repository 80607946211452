import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Modal, Button, Container, Form } from "react-bootstrap";
import copyIcon from "../../../assets/svgs/copyIcon.svg";
import { useNavigate } from "react-router-dom";
import copyTextToClipboard from "../../../helper/common/copyToClipboard";
// import searchIcon from "../../../assets/icons/search_icon.svg";
import searchIcon from "../../../assets/icons/search_icon.svg";
import arrowDownIcon from "../../../assets/icons/down_arrow.svg";
import "../../searchbarForEditCampaign.css";
import getClientData from "../../../helper/clientData/getClientData";
import { masterApi } from "../../../apis/masterApi";
import {
  TOAST_POSITION_TOP_RIGHT,
  TOAST_SUCCESS,
  TOAST_DANGER,
} from "../../../constant/HardTypes";
import { useGlobalContext } from "../../../context/global";
import useUserDetails from "../../../helper/userData/useUserDetails";
import {toast} from 'react-toastify';
// import SearchbarForEditCampaign from "../../searchbarForEditCampaign";
import Select from 'react-select';
import {encodeData} from '../../../helper/common/encodeData'
import { generateIFrameReportUrl, getClientAccountDetails, getClientDataFromAdyogiAPI, getPlatformStatus } from "../../../helper/clientData/utils";
import removeDuplicateObjectsFromArray from "../../../helper/common/removeDuplicateObjectsFromArray";

const PortfoliCreationModal = () => {
  // State
  const [word, setWord] = useState("");
  const [buttonStatus, setButtonStatus] = useState(false);
  const [currentClient, setCurrentClient] = useState({ name: "Choose Client", account_id: "", client_id: "" });
  const [clientsDataToShow, setClientsDataToShow] = useState([]);
  const {
    show,
    setShow,
    showAlert,
    modalFor,
    allClientsData,
    setAllClientsData,
    portfolio,
    setPortfoliosArray,
    clientId,
    setClientId,
    clientAccountId,
    setClientAccountId,
    clientData,
    setClientData,
    campaignData,
    setCampaignData,
    adGroupData,
    setAdGroupData,
    adSetData,
    setAdSetData,
    reportData,
    setReportData,
    noMultiuserClients,
    setShowCMSLink,
    updatePlatform
  } = useGlobalContext();

  const getSort = (list, clm) => {
    const sortedData = list.sort((a, b) => {
      const nameA = a[clm]?.toLowerCase();
      const nameB = b[clm]?.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    return sortedData;
  }

  const navigate = useNavigate();
  const [userDetails] = useUserDetails();
  const isMultiUser = userDetails?.user.multiUser;
  const modalHeading =
    modalFor !== "switch-client"
      ? ("Create Portfolio")
      : (isMultiUser === "no"
        ? ("Switch Account")
        : ("Switch Client"));
  const noMultiuserClientsData = noMultiuserClients?.map((category) => {
    return {
      client_id: category?.parse_client_id,
      account_id: Math.random() * 999,
      name: category?.name,
    };
  });

  const sortnoMultiuserClientsData = getSort(noMultiuserClientsData, 'name');
  const [noMultiuserData, setNoMultiuserData] = useState(sortnoMultiuserClientsData);
  const [isSwitching, setIsSwitching] = useState(false);

  // Methods
  const handleChange = (e) => {
    setWord(e.target.value);
  };

  // const filterClients = (e) => {
  //   const filteredClients = (
  //     isMultiUser === "no" ? noMultiuserClientsData : allClientsData
  //   )?.filter((data) => {
  //     if (e.target.value === "") {
  //       return data;
  //     } else {
  //       const dataInString = data?.name + data?.account_id + data?.client_id;
  //       return dataInString
  //         ?.toLowerCase()
  //         ?.includes(e.target.value.toLowerCase());
  //     }
  //   });
  //   isMultiUser !== "no"
  //     ? setClientsDataToShow([
  //       { name: "Choose Client", account_id: "", client_id: "" },
  //       ...filteredClients,
  //     ])
  //     : setNoMultiuserData([...filteredClients]);
  // };

  function setCurrentClientData(mainData) {
    const { reportData, clientData } = mainData;
    const { client_id, account_id, default_bid } = clientData;
    setClientData(clientData);
    localStorage.setItem("reference",JSON.stringify(clientData))
    setClientId(client_id);
    setAdGroupData({
      ...adGroupData,
      account_id: account_id,
      amz_default_bid: default_bid,
    });
    setCampaignData({
      ...campaignData,
      tenant_id: client_id,
      account_id: account_id,
    });
    setAdSetData({ ...adSetData, tenant_id: client_id });
    setClientAccountId(account_id);
    setReportData(reportData);
  }

  useEffect(() => {
    setAllClientsData(getSort(allClientsData, 'name'))
  }, [allClientsData])


  const getClientsFromMyntraAndFlipkart = async () => { 
    try {
      const url = `${process.env.REACT_APP_LOGIN_API}/api/B4APIs/getAllClients`
      const config = {
        method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${process.env.REACT_APP_AUTHORIZATION_FOR_NORMAL_USER}`
            }
      }
      const data = await axios.get(url,config);
      if(data?.status === 200) {
        return data?.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  // Fetching Methods

  const convertFlipkartAndMyntraData = (clientsData) => {
    return clientsData?.reduce((acc,curr) => {
      const setupKey = (curr?.flipkart_setup && curr?.flipkart_setup?.profile_id) ? 'flipkart_setup' : 'myntra_setup'
    if(curr[setupKey] && curr[setupKey]?.profile_id) {
     const {accountName,accountType,profileId} = getClientAccountDetails(curr)
      acc.push({
        ...curr,
        name: accountName,
        account_id: profileId,
        client_id: curr?.objectId,
        account_type: accountType,
        isMyntraOrFlipkart:true,
        platform: setupKey === 'flipkart_setup' ? 'flipkart' : 'myntra'
      })
    }

    return acc
    },[])
  }

  const convertExistingData = (amazonData,flipKartData) => {
    return amazonData?.map((data) => {
      const sameIdData = flipKartData?.find((flipData) => {
        return data?.client_id === flipData?.client_id 
      }) ?? {}
      const flipkartData = (sameIdData['flipkart_setup'] && sameIdData['flipkart_setup']?.profileId) ? sameIdData['flipkart_setup'] : {}
      const isFlipKartDataPresent = Object.keys(flipkartData).length > 0
      const flipkart_setup = isFlipKartDataPresent ? flipkartData : {}
      const myntraData = (sameIdData['myntra_setup'] && sameIdData['myntra_setup']?.profileId) ? sameIdData['myntra_setup'] : {}
      const isMyntraDataPresent = Object.keys(myntraData)?.length > 0
      const myntra_setup = isMyntraDataPresent ? myntraData : {}
      return {...data,...(isFlipKartDataPresent && {flipkart_setup}),...(isMyntraDataPresent && {myntra_setup}),platform:'amazon'}

    })
  }

  const getAllClients = async () => {
    try {
      const [amazonResponse,flipkartAndMyntraResponse] = await Promise.all([getclientdata(),getClientsFromMyntraAndFlipkart()]);
      const modifiedFlipkartData = convertFlipkartAndMyntraData(flipkartAndMyntraResponse)
      const amazonModifiedData = convertExistingData(amazonResponse,modifiedFlipkartData)
      const overallData = removeDuplicateObjectsFromArray(amazonModifiedData.concat(modifiedFlipkartData),true,'client_id'); 
      const allData = getSort(overallData,'name');
      setAllClientsData(allData ?? [])
    } catch (error) {
      console.log(error)
    }
  }

  const getclientdata = async () => {
    return await  axios
      .get(
        "https://adyogiadsapi.adyogi.com/accounts?type=amazon_ad_account&fields=account_id,client_id,name,profile_id,account_type"
      )
      .then((res) => {
        if (res?.data?.data) {
          return res?.data?.data
        } else {
          alert("Some Unknown Error Occured");
        }
      });
  };

  // Effects

  useEffect(() => {
    if (modalFor === "switch-client" && allClientsData.length === 0) {
      getAllClients();
    }
  }, []);

  useEffect(() => {
    setClientsDataToShow([
      { name: "Choose Client", account_id: "", client_id: "" },
      ...allClientsData,
    ]);
  }, [allClientsData]);

  // API Integration needs to be done here
  const integrateApi = async (dataPassed, e) => {
    try {
      if (dataPassed === "") {
        e.preventDefault();
        alert("Please Enter Something");
        return;
      }
      const data = {
        name: dataPassed,
        amz_in_budget: true,
        source: "amazon",
        amz_state: "ENABLED",
      };
      setButtonStatus(true);
      const result = await masterApi(data, "createPortfolio", false, "post");
      if (result?.statusCode !== 200) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 10000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Success",
        //   description: "Error While Creating Portfolio",
        // });
        toast.error('Unknown error occurred while creating Porfolio')
        setButtonStatus(false);
        return;
      }
      setButtonStatus(false);
      // showAlert(TOAST_POSITION_TOP_RIGHT, 10000, {
      //   backgroundColor: TOAST_SUCCESS,
      //   title: "Success",
      //   description: "Portfolio has been created successfully",
      // });
      toast.success('Portfolio has been created successfully')
      setShow(false);
      setPortfoliosArray([...portfolio, dataPassed]);
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = () => {
    integrateApi(word);
  };

  
  const fetchData = async (clientId) => {
    try {
      const response = await getClientDataFromAdyogiAPI(clientId);
      const productType = response?.data?.results[0]?.amazon_setup?.product_type
      localStorage.setItem("product_type",JSON.stringify(productType ?? "internal"))
      if( productType==="Self serve") {
        setShowCMSLink(false)
      } else {
        setShowCMSLink(true)
      }
      
    } catch (error) {
      throw error;
    }
  }

  const handleSwitch = async (passedClientId) => {
    try {
      setIsSwitching(true);
      await fetchData(passedClientId)
      const mainData = await getClientData(
        passedClientId,
        true,
        userDetails?.user?.email
      );
      if ("clientData" in mainData) {
        setCurrentClientData(mainData);
        setShow(false);
        const {myntraStatus,amazonStatus,flipkartStatus} = getPlatformStatus(mainData?.clientData ?? {});
        const platform = amazonStatus ? 'amazon' : myntraStatus ? 'myntra' : flipkartStatus ?  'flipkart':'amazon'
        updatePlatform(platform)
      if(window.location.href.includes("?")) {
        if(platform === 'amazon') { 
          navigate('./')
        } else {
          const url = generateIFrameReportUrl(platform,'Day',passedClientId)
          navigate(`./reports?url=${url}`)
        }
        window.location.reload()
      } else {
        if(platform!=='amazon') { 
          const url = generateIFrameReportUrl(platform,'Day',passedClientId)
          navigate(`./reports?url=${url}`)
        }
        window.location.reload(); //Reload the page after change client to load change client data
      }
      //  navigate('/')
      }
      setIsSwitching(false);
    } catch (error) {
      alert("Some Unknown Error Occured");
      setIsSwitching(false);
    }

    // Third Party API Function call :- GainSight
  };

  const handleCopy = async (e) => {
    try {
      const copiedText = await copyTextToClipboard(e.target.id);
    } catch (error) {
      console.log(error);
    }
  };

  const [optionData, setOptionData] = useState([]);

  const [selectedOption,setSelectedOption] = useState({});

  const setClientInfo = (cid) => {
    const FoundedClient = (
      isMultiUser === "no"
        ? noMultiuserClientsData
        : allClientsData
    ).find((data) => data?.client_id === cid);

    const selectedop = optionData.find((data) => data?.value === cid);
    setSelectedOption(selectedop)

    if (
      FoundedClient !== null &&
      FoundedClient !== undefined
    ) {
      setCurrentClient(FoundedClient);
    } else {
      setCurrentClient({});
    }
  }

  useEffect(() => {
    const c_id = localStorage.getItem("clientId") ? JSON.parse(localStorage.getItem("clientId")) : "";
    setClientInfo(c_id);
  },[optionData])

  useEffect(() => {
    const optionlist = (isMultiUser === "no"
      ? [

        {
          name: "Choose Account",
          account_id: "",
          client_id: "",
        },
        ...noMultiuserData,
      ]
      : [...clientsDataToShow]
    );

    const dropdownOptions = optionlist.map((item, index) => ({
      value: item.client_id,
      label: item.name
    }));
    
    setOptionData(dropdownOptions)
  }, [isMultiUser, clientsDataToShow, noMultiuserData])

  const onDropdownOptionChange = (e) => {
    setClientInfo(e.value)
    // const FoundedClient = (
    //   isMultiUser === "no"
    //     ? noMultiuserClientsData
    //     : allClientsData
    // ).find((data) => data?.client_id === e.value);

    // const selectedop = optionData.find((data) => data?.value === e.value);
    // setSelectedOption(selectedop)

    // if (
    //   FoundedClient !== null &&
    //   FoundedClient !== undefined
    // ) {
    //   setCurrentClient(FoundedClient);
    // } else {
    //   setCurrentClient({});
    // }
  }
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const DropdownIndicator = (props) => {
    return (
      <div
        className={`dropdown-indicator ${isDropdownOpen ? 'open' : ''}`}
        onMouseDown={(e) => {
          e.preventDefault();
          setIsDropdownOpen(!isDropdownOpen);
        }}
      >
        {/* {isDropdownOpen && <img className="search-box-pop" src={searchIcon} style={{ zIndex: "1000" }} alt={"search Client"} />} */}
        <img className="search-box-pop" src={searchIcon} style={{ zIndex: "1000" }} alt={"search Client"} />
        <img src={arrowDownIcon} style={{ zIndex: "1000" }} alt={"search Client"} />
      </div>
    );
  };

  return (
    <>
      <Modal show={show} onHide={setShow}>
        <Modal.Header className="portfolio-border">
          <Modal.Title className="text-center">
            {/* {modalFor !== "switch-client"
              ? "Create Portfolio"
              : "Switch Client"} */}
            {modalHeading}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalFor !== "switch-client" ? (
            <>
              <Form.Label> Add Portfolio Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Portfolio Name"
                onChange={handleChange}
              />
            </>
          ) : (
            <>
              <div className="main-body-wrapper">
                {/* <div className="search-bar-container-client-switch mb-3">
                  <span className="search-icon-container">
                    <img
                      src={searchIcon}
                      style={{ zIndex: "1000" }}
                      alt={"search Client"}
                    ></img>
                    <Search color="#4D4184" className="search-icon" />
                  </span>
                  <span className="search-input-container">
                    <input
                      type="text"
                      className={"search-input-field create-campaign-outline"}
                      placeholder={
                        isMultiUser === "no"
                          ? "Filter Account"
                          : "Filter Client"
                      }
                      onChange={filterClients}
                    />
                  </span>
                </div> */}
                <div className="filter-dropdown">
                  <div className="select-drop">
                    <Select
                      value={selectedOption}
                      className={`basic-single ${isDropdownOpen ? 'open' : ''}`}
                      classNamePrefix="select"
                      isSearchable={true}
                      options={optionData}
                      onChange={(e) => { onDropdownOptionChange(e) }}
                      components={{ DropdownIndicator }}
                      placeholder={isMultiUser === 'no' ? 'Choose Account' : 'Choose Client'}
                      onFocus={() => setIsDropdownOpen(true)}
                      onBlur={() => setIsDropdownOpen(false)}
                      menuPlacement="auto"
                      menuPosition="fixed"
                    />
                  
                    {/* <select
                      class="form-select"
                      aria-label="Default select example"
                      name="amz_category"
                      isSearchable={true}
                      placeholder={isMultiUser === "no" ? 'Choose Account' : 'Choose Client'}
                      onChange={(e) => {
                        const FoundedClient = (
                          isMultiUser === "no"
                            ? noMultiuserClientsData
                            : allClientsData
                        ).find((data) => data?.client_id === e.target.value);
                        if (
                          FoundedClient !== null &&
                          FoundedClient !== undefined
                        ) {
                          setCurrentClient(FoundedClient);
                        } else {
                          setCurrentClient({});
                        }
                      }}
                    >
                      {(isMultiUser === "no"
                        ? [
                            {
                              name: "Choose Account",
                              account_id: "",
                              client_id: "",
                            },
                            ...noMultiuserData,
                          ]
                        : [...clientsDataToShow]
                      ).map((category, index) => {
                        return (
                          <option
                            value={category.client_id}
                            key={category?.client_id}
                          >
                            {category?.name}
                          </option>
                        );
                      })}
                    </select> */}
                  </div>
                </div>
                {/* {Object.keys(currentClient).length !== 0 && ( */}
                {selectedOption && selectedOption.value !== "" && (
                  <div
                    className="choosen-client-details p-3"
                    onClick={handleCopy}
                  >
                    {/* <h5 className="client-name">{currentClient?.name}</h5> */}
                    <div
                      className={
                        isMultiUser === "no" ? "hide" : "client-id-container"
                      }
                    >
                      <div className="id-information d-flex gap-2">
                        <span>Client Id:{currentClient?.client_id}</span>
                        <img
                          className="cursor-pointer-global"
                          src={copyIcon}
                          alt="copy"
                          id={currentClient?.client_id}
                        />
                      </div>
                      <div className="id-information d-flex gap-2">
                        <span>Profile Id:{currentClient?.account_id}</span>
                        <img
                          className="cursor-pointer-global"
                          src={copyIcon}
                          alt="copy"
                          id={currentClient?.account_id}
                        />
                      </div>
                      <div className="id-information d-flex gap-2">
                        <span>Client Account Type:{currentClient?.account_type}</span>
                        <img
                          className="cursor-pointer-global"
                          src={copyIcon}
                          alt="copy"
                          id={currentClient?.account_id}
                        />
                      </div>
                      <div className="id-information d-flex gap-2">
                        <span>Platform:{currentClient?.platform}</span>
                        <img
                          className="cursor-pointer-global"
                          src={copyIcon}
                          alt="copy"
                          id={currentClient?.account_id}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="portfolio-border">
          <Container className="d-flex gap-2 align-items-end justify-content-center btns-grp">
            <Button
              className="btns btn-greyish"
              variant="outline-success"
              onClick={() => setShow(false)}
            >
              Cancel
            </Button>
            <Button
              className="btns"
              style={{ backgroundColor: "#4D4184", color: "#fff" }}
              onClick={() =>
                modalFor !== "switch-client"
                  ? handleSubmit()
                  : handleSwitch(currentClient?.client_id)
              }
              disabled={
                buttonStatus.status ||
                (modalFor === "switch-client" &&
                  Object.keys(currentClient).length === 0)
              }
            >
              {modalFor !== "switch-client" ? "Save" : isSwitching ? "Switching..." : "Switch"}
            </Button>
          </Container>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PortfoliCreationModal;
