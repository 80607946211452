import React, { useEffect, useState } from "react";
// import { Select as PolarisSelect, TextField, Icon } from '@shopify/polaris';
// import { DeleteMinor } from '@shopify/polaris-icons';
import styles from "./andorlogic.module.css";
import ReactSelect from "react-select";
import deleteIcon from "../../../../assets/svgs/delete.svg";
import CirclePlusIcon from "../../../../assets/svgs/circle_plus.svg";
import CloseRedIcon from "../../../../assets/svgs/close_red.svg";

import CustomTextField from "../CustomTextField/CustomTextField";
import InInputComponent from "../InInputComponent/InInputComponent";
import { filterOperators } from "../../ComplexFilters";
import { toast } from "react-toastify";

const AndOrLogic = ({
  id,
  data,
  updateGroupConditionContent,
  removeGroupConditionContent,
  showCloseButton,
  operator,
  conditionError,
  config,
  type,
  userAction,
  multiple,
  multipleLimit,
  inOperatorMaxValues,
  isShowConditionOperator = true,
}) => {
  const [conditions, setConditions] = useState(
    Array.isArray(data)
      ? data
      : [
          {
            category: "",
            filter: "",
            userInput: "",
          },
        ]
  );
  const addCondition = () => {
    if(multiple && conditions.length >= limit) { 
      toast.warning(`You can add maximum ${limit} conditions`,{toastId: "limit"});
      return;
  }
    const newCondition = {
      id: Date.now().toString(),
      category: "",
      filter: "",
      userInput: "",
    };
    const updatedConditions = [...conditions, newCondition];
    setConditions(updatedConditions);
    updateGroupConditionContent(updatedConditions, "data");
  };
  const limit = multipleLimit ? multipleLimit : 1000;


  const removeCondition = (index) => {
    const updatedConditions = [...conditions];
    updatedConditions.splice(index, 1);
    setConditions(updatedConditions);
    updateGroupConditionContent(updatedConditions, "data");
  };

  const handleConditionChange = (index, field, value) => {
    const updatedConditions = [...conditions];
    updatedConditions[index][field] = value;
    setConditions(updatedConditions);
    updateGroupConditionContent(updatedConditions, "data");
  };

  const validate = (inputValue, type) => {
    if (type === "int" || type === "facebookIntOperator") {
      const numericRegex = /^[0-9.]*$/;
      return numericRegex.test(inputValue);
    } else if (
      type === "string" ||
      type === "facebookStringOperatorMultiple" ||
      type === "customOnlyContins" ||
      type === "customOnlyContinsWithSelect"
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div
      className={`${styles.rulesContainer} ${
        type === "page" ? styles.page : ""
      }`}
    >
      <div className={multiple ? `${styles.card}` : undefined}>
        <div className={styles.cardContent} style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            {conditions.map((condition, conditionIndex) => {
              const currCategory = config.fields.find(
                (val) =>
                  val.name === condition.category ||
                  val.id === condition.category
              );
              const filterSelect = config.operators[currCategory?.datatype]
                ? config.operators[currCategory?.datatype].map((field) => ({
                    value: field.id,
                    label: field.name,
                    multiple: field.multiple,
                    dropdown: field.dropdown,
                    input: field.input,
                  }))
                : config.operators["int"].map((field) => ({
                    value: field.name,
                    label: field.name,
                    multiple: field.multiple,
                    dropdown: field.dropdown,
                    input: field.input,
                  }));
              const isMultiple =
                filterSelect.find((item) => item.label === condition.filter)
                  ?.multiple ?? false;
              const isDropdownEnabled =
                filterSelect.find((item) => item.label === condition.filter)
                  ?.dropdown ?? false;
              const isInputEnabled =
                filterSelect.find((item) => item.label === condition.filter)
                  ?.input ?? true;
              const optionObject = filterSelect
                ? filterSelect.map((field) => ({
                    value: field.id,
                    label: field.name,
                  }))
                : config.operators["int"].map((field) => ({
                    value: field.name,
                    label: field.name,
                  }));
              var placeholder = currCategory?.placeholder
                ? currCategory?.placeholder
                : "Enter ...";
              const helpText = currCategory?.helpText
                ? currCategory?.helpText
                : null;
              const conditionErrors =
                (conditionError.find((group) => group[condition.id]) || {})[
                  condition.id
                ] || [];
              let userInputOptions;

              if (
                Array.isArray(condition.userInput) &&
                condition.userInput.length > 0
              ) {
                userInputOptions = condition.userInput.map((input) => ({
                  value: input,
                  label: input,
                }));
              } else if (
                condition.userInput &&
                !Array.isArray(condition.userInput)
              ) {
                userInputOptions = [
                  { value: condition.userInput, label: condition.userInput },
                ];
              } else {
                userInputOptions = [];
              }
              let filterValueSelected = condition.filter
                ? [{ value: condition.filter, label: condition.filter }]
                : [];

              return (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                      gap: "8px",
                      padding: "4px",
                      width: "100%",
                    }}
                    key={condition.id}
                  >
                    {multiple && (
                      <>
                        {conditionIndex > 0 && isShowConditionOperator ? (
                          <div
                            className={styles.select}
                            style={{ width: "13%" }}
                          >
                            <ReactSelect
                              className="basic-single"
                              classNamePrefix="Select Operator"
                              options={filterOperators}
                              value={filterOperators?.find(
                                (item) => item.value === operator
                              )}
                              onChange={(item) => {
                                userAction(true);
                                updateGroupConditionContent(
                                  item.value,
                                  "operator"
                                );
                              }}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  height: "36px",
                                  fontSize: "14px",
                                }),
                                menu: (provided, state) => ({
                                  ...provided,
                                  maxHeight: multiple ? "180px" : "180px",
                                  overflow: "hidden",
                                  fontSize: "14px",
                                  zIndex: "99",
                                }),
                                menuList: (provided, state) => ({
                                  ...provided,
                                  fontSize: "14px",
                                  maxHeight: "150px", // Ensure the menu list also respects the max height
                                }),
                              }}
                            />
                          </div>
                        ) : (
                          <div
                            className={styles.select}
                            style={{ width: "13%", textAlign: "center" }}
                          >
                            {conditionIndex === 0 ? "Where" : ""}
                          </div>
                        )}
                      </>
                    )}
                    <div style={{ minWidth: "20%", maxWidth: "200px" }}>
                      <ReactSelect
                        className="basic-single"
                        classNamePrefix="Select Field"
                        defaultValue={
                          condition.category
                            ? [
                                {
                                  value: condition.category,
                                  label: condition.category,
                                },
                              ]
                            : []
                        }
                        isSearchable={true}
                        name="field"
                        placeholder={"Select Field"}
                        menuPlacement={multiple ? "bottom" : "top"}
                        options={config.fields.reduce((acc, field) => {
                          const fieldType = field.type;
                          const group = acc.find(
                            (group) => group.label === fieldType
                          );
                          if (group) {
                            group.options.push({
                              value: field.name,
                              label: field.name,
                              name: field.name,
                            });
                          } else {
                            acc.push({
                              label: fieldType,
                              options: [
                                {
                                  value: field.name,
                                  label: field.name,
                                  name: field.name,
                                },
                              ],
                            });
                          }
                          return acc;
                        }, [])}
                        onChange={(selectedOption) => {
                          handleConditionChange(
                            conditionIndex,
                            "userInput",
                            ""
                          );
                          handleConditionChange(conditionIndex, "filter", "");
                          handleConditionChange(
                            conditionIndex,
                            "category",
                            selectedOption.name
                          );
                          userAction(true);
                          // initialInput = []
                        }}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            height: "36px",
                            fontSize: "14px",
                            borderColor:
                              conditionErrors.some((error) => error.category) &&
                              condition.category === ""
                                ? "#FF4D4F"
                                : "#AEB4B9",
                          }),
                          menu: (provided, state) => ({
                            ...provided,
                            maxHeight: multiple ? "180px" : "180px",
                            overflow: "hidden",
                            zIndex: "99",
                            fontSize: "14px",
                            width: "fit-content",
                          }),
                          menuList: (provided, state) => ({
                            ...provided,
                            fontSize: "14px",
                            maxHeight: "150px", // Ensure the menu list also respects the max height
                          }),
                        }}
                      />
                    </div>
                    <div style={{ minWidth: "25%", maxWidth: "230px" }}>
                      <ReactSelect
                        className="basic-single"
                        classNamePrefix="Select Operator"
                        value={filterValueSelected}
                        isSearchable={true}
                        name="operator"
                        options={filterSelect}
                        placeholder={"Select Operator"}
                        menuPlacement={multiple ? "bottom" : "top"}
                        onChange={(selectedOption) => {
                          handleConditionChange(
                            conditionIndex,
                            "userInput",
                            ""
                          );
                          handleConditionChange(
                            conditionIndex,
                            "filter",
                            selectedOption.label
                          );
                          userAction(true);
                          // initialInput = []
                        }}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            height: "36px",
                            fontSize: "14px",
                            borderColor:
                              conditionErrors.some((error) => error.filter) &&
                              condition.filter === ""
                                ? "#FF4D4F"
                                : "#AEB4B9",
                          }),
                          menu: (provided, state) => ({
                            ...provided,
                            maxHeight: multiple ? "180px" : "180px",
                            overflow: "hidden",
                            fontSize: "14px",
                            zIndex: "99",
                          }),
                          menuList: (provided, state) => ({
                            ...provided,
                            fontSize: "14px",
                            maxHeight: "150px", // Ensure the menu list also respects the max height
                          }),
                        }}
                      />
                    </div>
                    {!isInputEnabled ? (
                      <div style={{ width: "40%" }}></div>
                    ) : (
                      <div
                        className="filterValueSelect"
                        style={{
                          width: "40%",
                        }}
                      >
                        {currCategory?.optionData?.length > 0 &&
                        isDropdownEnabled ? (
                          <ReactSelect
                            isMulti={isMultiple}
                            className="basic-single"
                            classNamePrefix="Select Operator"
                            defaultValue={userInputOptions}
                            isSearchable={true}
                            name="userInput"
                            options={currCategory.optionData}
                            key={JSON.stringify(userInputOptions)}
                            placeholder={"Select..."}
                            menuPlacement={multiple ? "bottom" : "top"}
                            onChange={(selectedOption) => {
                              if (isMultiple) {
                                const values = selectedOption.map((option) => {
                                  return Array.isArray(option.value)
                                    ? option.value[0]
                                    : option.value;
                                });
                                handleConditionChange(
                                  conditionIndex,
                                  "userInput",
                                  values
                                );
                              } else {
                                handleConditionChange(
                                  conditionIndex,
                                  "userInput",
                                  selectedOption ? [selectedOption.value] : []
                                );
                              }
                              userAction(true);
                            }}
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                minHeight: "36px",
                                height: "auto",
                                paddingRight: "8px",
                                fontSize: "14px",
                                borderColor:
                                  conditionErrors.some(
                                    (error) => error.userInput
                                  ) && condition.userInput === ""
                                    ? "#FF4D4F"
                                    : "#AEB4B9",
                              }),
                              menu: (provided, state) => ({
                                ...provided,
                                maxHeight: multiple ? "180px" : "180px",
                                overflow: "hidden",
                                fontSize: "14px",
                                zIndex: "99",
                              }),
                              menuList: (provided, state) => ({
                                ...provided,
                                fontSize: "14px",
                                maxHeight: "150px",
                              }),
                            }}
                          />
                        ) : isMultiple === true ? (
                          <InInputComponent
                            handleConditionChange={handleConditionChange}
                            inOperatorMaxValues={inOperatorMaxValues}
                            userAction={(e) => userAction(true)}
                            conditionIndex={conditionIndex}
                            initialData={
                              condition.userInput
                                ? condition.userInput.map((row) => ({
                                    label: row,
                                    value: row,
                                  }))
                                : []
                            }
                            placeHolder={placeholder}
                            shoeError={
                              conditionErrors.some(
                                (error) => error.userInput
                              ) && condition.userInput.length === 0
                                ? true
                                : false
                            }
                            removeSelected={condition.userInput ? false : true}
                            validate={currCategory?.datatype}
                          />
                        ) : (
                          <CustomTextField
                            placeholder={placeholder}
                            value={condition.userInput}
                            error={
                              conditionErrors.some((error) => error.userInput)
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              if (validate(e, currCategory?.datatype)) {
                                handleConditionChange(
                                  conditionIndex,
                                  "userInput",
                                  [e]
                                );
                              }
                              userAction(true);
                            }}
                          />
                        )}
                      </div>
                    )}
                    <div
                      onClick={() => {
                        userAction(true);
                        removeCondition(conditionIndex);
                      }}
                      style={{
                        cursor: "pointer",
                        visibility: conditions.length < 2 && "hidden",
                      }}
                    >
                      <img src={deleteIcon} alt="deleteIcon" />
                    </div>
                  </div>
                  {helpText && (
                    <p
                      style={{
                        color: "#637381",
                        fontWeight: "400",
                        padding: "2px 32px 2px 2px",
                        fontSize: "12px",
                        textAlign: "right",
                      }}
                    >
                      *NOTE : {helpText}
                    </p>
                  )}
                </>
              );
            })}
          </div>
        </div>
        {multiple && (
          <div className={styles.addCondition}>
            <div
              onClick={(e) => {
                addCondition();
                userAction(true);
              }}
              className={styles.Condition}
              style={{ cursor: "pointer" }}
            >
              <img src={CirclePlusIcon} alt="add" />
              <p style={{ margin: "0" }}>Add Condition</p>
            </div>
          </div>
        )}
        {showCloseButton && (
          <div
            className={styles.closeButton}
            onClick={(e) => {
              userAction(true);
              removeGroupConditionContent();
            }}
          >
            <img src={CloseRedIcon} alt="close" />
          </div>
        )}
      </div>
    </div>
  );
};

export default AndOrLogic;
