const platformWiseColumnConfig = {
  amazon: [
    {
      key: "{tableName}.asin",
      title: "Variant Analysis Table Asin",
      shortTitle: "Asin",
      type: "string",
      // isSingleColumn is used to show the column in single column in comparison download
      isSingleColumn: true,
      dataIndex: "{tableName}.asin",
    },
    {
      key: "{tableName}.parentSku",
      title: "Variant Analysis Table Asin",
      shortTitle: "Parent ASIN",
      type: "string",
      isSingleColumn: true,
      dataIndex: "{tableName}.parentSku",
    },
    {
      key: "{tableName}.broadCategory",
      title: "Variant Analysis Table Broad Category",
      shortTitle: "Broad Category",
      type: "string",
      isSingleColumn: true,
      dataIndex: "{tableName}.broadCategory",
    },
    {
      key: "{tableName}.category",
      title: "Variant Analysis Table Category",
      shortTitle: "Category",
      type: "string",
      isSingleColumn: true,
      dataIndex: "{tableName}.category",
    },
    {
      key: "{tableName}.productNameWithImage",
      title: "Variant Analysis Table product_detail",
      shortTitle: "product_detail",
      type: "string",
      isSingleColumn: true,
      meta: {
        image: true,
      },
      dataIndex: "{tableName}.productNameWithImage",
    },
    {
      key: "{tableName}.cost",
      type: "number",
      dataIndex: "{tableName}.cost",
      title: "Variant Analysis Table spends",
      shortTitle: "Spends",
    },
    {
      key: "{tableName}.costDRR",
      type: "number",
      dataIndex: "{tableName}.costDRR",
      title: "Variant Analysis Table spends (DRR)",
      shortTitle: "Spends (DRR)",
    },
    {
      key: "{tableName}.roas",
      type: "number",
      dataIndex: "{tableName}.roas",
      title: "Variant Analysis Table ROAS",
      shortTitle: "ROAS",
    },
    {
      key: "{tableName}.roasDRR",
      type: "number",
      dataIndex: "{tableName}.roasDRR",
      title: "Variant Analysis Table ROAS (DRR)",
      shortTitle: "ROAS (DRR)",
    },
    {
      key: "{tableName}.impressions",
      type: "number",
      dataIndex: "{tableName}.impressions",
      title: "Variant Analysis Table Impressions",
      shortTitle: "Impressions",
    },
    {
      key: "{tableName}.impressionsDRR",
      type: "number",
      dataIndex: "{tableName}.impressionsDRR",
      title: "Variant Analysis Table Impressions (DRR)",
      shortTitle: "Impressions (DRR)",
    },
    {
      key: "{tableName}.clicks",
      type: "number",
      dataIndex: "{tableName}.clicks",
      title: "Variant Analysis Table clicks",
      shortTitle: "clicks",
    },
    {
      key: "{tableName}.clicksDRR",
      type: "number",
      dataIndex: "{tableName}.clicksDRR",
      title: "Variant Analysis Table clicks (DRR)",
      shortTitle: "clicks (DRR)",
    },
    {
      key: "{tableName}.ctr",
      type: "number",
      format: "percent",
      dataIndex: "{tableName}.ctr",
      title: "Variant Analysis Table CTR",
      shortTitle: "CTR",
    },
    {
      key: "{tableName}.ctrDRR",
      type: "number",
      format: "percent",
      dataIndex: "{tableName}.ctrDRR",
      title: "Variant Analysis Table CTR (DRR)",
      shortTitle: "CTR (DRR)",
    },
    {
      key: "{tableName}.cpc",
      type: "number",
      dataIndex: "{tableName}.cpc",
      title: "Variant Analysis Table CPC",
      shortTitle: "CPC",
    },
    {
      key: "{tableName}.cpcDRR",
      type: "number",
      dataIndex: "{tableName}.cpcDRR",
      title: "Variant Analysis Table CPC (DRR)",
      shortTitle: "CPC (DRR)",
    },
    {
      key: "{tableName}.cpm",
      type: "number",
      dataIndex: "{tableName}.cpm",
      title: "Variant Analysis Table CPM",
      shortTitle: "CPM",
    },
    {
      key: "{tableName}.cpmDRR",
      type: "number",
      dataIndex: "{tableName}.cpmDRR",
      title: "Variant Analysis Table CPM (DRR)",
      shortTitle: "CPM (DRR)",
    },
    {
      key: "{tableName}.conversions14d",
      type: "number",
      dataIndex: "{tableName}.conversions14d",
      title: "Variant Analysis Table orders",
      shortTitle: "orders",
    },
    {
      key: "{tableName}.conversions14dDRR",
      type: "number",
      dataIndex: "{tableName}.conversions14dDRR",
      title: "Variant Analysis Table Orders (DRR)",
      shortTitle: "Orders (DRR)",
    },
    {
      key: "{tableName}.conversion_rate",
      type: "number",
      format: "percent",
      dataIndex: "{tableName}.conversion_rate",
      title: "Variant Analysis Table conversion_rate",
      shortTitle: "conversion_rate",
    },
    {
      key: "{tableName}.conversion_rateDRR",
      type: "number",
      format: "percent",
      dataIndex: "{tableName}.conversion_rateDRR",
      title: "Variant Analysis Table conversion_rate (DRR)",
      shortTitle: "conversion_rate (DRR)",
    },
    {
      key: "{tableName}.sales14d",
      type: "number",
      format: "currency",
      dataIndex: "{tableName}.sales14d",
      title: "Variant Analysis Table revenue",
      shortTitle: "Revenue",
    },
    {
      key: "{tableName}.sales14dDRR",
      type: "number",
      format: "currency",
      dataIndex: "{tableName}.sales14dDRR",
      title: "Variant Analysis Table revenue (DRR)",
      shortTitle: "Revenue (DRR)",
    },
    {
      key: "{tableName}.average_order_value",
      type: "number",
      dataIndex: "{tableName}.average_order_value",
      title: "Variant Analysis Table AOV",
      shortTitle: "AOV",
    },
    {
      key: "{tableName}.average_order_valueDRR",
      type: "number",
      dataIndex: "{tableName}.average_order_valueDRR",
      title: "Variant Analysis Table AOV (DRR)",
      shortTitle: "AOV (DRR)",
    },
    {
      key: "{tableName}.acos",
      type: "number",
      dataIndex: "{tableName}.acos",
      title: "Variant Analysis Table ACOS",
      shortTitle: "ACOS",
    },
    {
      key: "{tableName}.acosDRR",
      type: "number",
      dataIndex: "{tableName}.acosDRR",
      title: "Variant Analysis Table ACOS (DRR)",
      shortTitle: "ACOS (DRR)",
    },

    // // previous columns
    // {
    //   key: "{tableName}.obDate",
    //   title: "Client Daily Campaign Amazon Table Date",
    //   shortTitle: "Date",
    //   type: "time",
    //   dataIndex: "{tableName}.obDate",
    // },
    {
      key: "{tableName}.BSR",
      type: "number",
      isSingleColumn: true,
      dataIndex: "{tableName}.BSR",
      title: "Best Seller Rank",
      shortTitle: "BSR",
    },
    {
      key: "{tableName}.MaximumRetailPrice",
      type: "number",
      isSingleColumn: true,
      dataIndex: "{tableName}.MaximumRetailPrice",
      title: "Maximum Retail Price",
      shortTitle: "MRP",
    },
    {
      key: "{tableName}.SalePrice",
      type: "number",
      isSingleColumn: true,
      dataIndex: "{tableName}.SalePrice",
      title: "Selling Price",
      shortTitle: "Selling Price",
    },
    {
      key: "{tableName}.Quantity",
      type: "number",
      isSingleColumn: true,
      dataIndex: "{tableName}.Quantity",
      title: "Available Inventory",
      shortTitle: "Available Inventory",
    },

    {
      key: "{tableName}.tacos",
      type: "number",
      dataIndex: "{tableName}.tacos",
      title: "Total Advertising Cost of Sales (TACOS)",
      shortTitle: "Total ACOS",
    },
    {
      key: "{tableName}.total_asp",
      type: "number",
      dataIndex: "{tableName}.total_asp",
      title: "Total ASP",
      shortTitle: "ASP",
    },
    {
      key: "{tableName}.trevnue",
      type: "number",
      dataIndex: "{tableName}.trevnue",
      title: "Total Revenue",
      shortTitle: "Total Revenue",
    },
    {
      key: "{tableName}.troas",
      type: "number",
      dataIndex: "{tableName}.troas",
      title: "Total ROAS",
      shortTitle: "Total ROAS",
    },
    {
      key: "{tableName}.tunits_sold",
      type: "number",
      dataIndex: "{tableName}.tunits_sold",
      title: "Total Units Sold",
      shortTitle: "Total Units Sold",
    },
  ],
  myntra: [
    {
      key: "{tableName}.product_id",
      title: "Variant Analysis Table Product ID",
      shortTitle: "Product ID",
      type: "string",
      // isSingleColumn is used to show the column in single column in comparison download
      isSingleColumn: true,
      dataIndex: "{tableName}.product_id",
    },
    {
      key: "{tableName}.product_name",
      title: "Variant Analysis Table Product Name",
      shortTitle: "Product Name",
      type: "string",
      isSingleColumn: true,
      dataIndex: "{tableName}.product_name",
    },
    {
      key: "{tableName}.views",
      type: "number",
      dataIndex: "{tableName}.views",
      title: "Views",
      shortTitle: "Views",
    },
    {
      key: "{tableName}.clicks",
      type: "number",
      dataIndex: "{tableName}.clicks",
      title: "Clicks",
      shortTitle: "Clicks",
    },
    {
      key: "{tableName}.roas",
      type: "number",
      dataIndex: "{tableName}.roas",
      title: "ROAS",
      shortTitle: "ROAS",
    },
    {
      key: "{tableName}.direct_roas",
      type: "number",
      dataIndex: "{tableName}.direct_roas",
      title: "ROAS Direct",
      shortTitle: "ROAS Direct",
    },
    {
      key: "{tableName}.clicks_drr",
      type: "number",
      dataIndex: "{tableName}.clicks_drr",
      title: "Clicks (DRR)",
      shortTitle: "Clicks (DRR)",
    },
    {
      key: "{tableName}.spends",
      type: "number",
      dataIndex: "{tableName}.spends",
      title: "Spends",
      shortTitle: "Spends",
    },
    {
      key: "{tableName}.spends_drr",
      type: "number",
      dataIndex: "{tableName}.spends_drr",
      title: "Spends (DRR)",
      shortTitle: "Spends (DRR)",
    },
    {
      key: "{tableName}.revenue",
      type: "number",
      format: "currency",
      dataIndex: "{tableName}.revenue",
      title: "Revenue",
      shortTitle: "Revenue",
    },
    {
      key: "{tableName}.mrp",
      type: "number",
      isSingleColumn: true,
      dataIndex: "{tableName}.mrp",
      title: "Maximum Retail Price",
      shortTitle: "MRP",
    },
    {
      key: "{tableName}.views_drr",
      type: "number",
      dataIndex: "{tableName}.views_drr",
      title: "Views (DRR)",
      shortTitle: "Views (DRR)",
    },
    {
      key: "{tableName}.ctr",
      type: "number",
      format: "percent",
      dataIndex: "{tableName}.ctr",
      title: "CTR",
      shortTitle: "CTR",
    },
    {
      key: "{tableName}.ctr_drr",
      type: "number",
      format: "percent",
      dataIndex: "{tableName}.ctr_drr",
      title: "CTR (DRR)",
      shortTitle: "CTR (DRR)",
    },
    {
      key: "{tableName}.cvr",
      type: "number",
      format: "percent",
      dataIndex: "{tableName}.cvr",
      title: "CVR",
      shortTitle: "CVR",
    },
    {
      key: "{tableName}.cvr_drr",
      type: "number",
      format: "percent",
      dataIndex: "{tableName}.cvr_drr",
      title: "CVR (DRR)",
      shortTitle: "CVR (DRR)",
    },
    {
      key: "{tableName}.units_sold_direct",
      type: "number",
      dataIndex: "{tableName}.units_sold_direct",
      title: "Units Sold Direct",
      shortTitle: "Units Sold Direct",
    },
    {
      key: "{tableName}.units_sold_direct_drr",
      type: "number",
      dataIndex: "{tableName}.units_sold_direct_drr",
      title: "Units Sold Direct (DRR)",
      shortTitle: "Units Sold Direct (DRR)",
    },
    {
      key: "{tableName}.units_sold_indirect_drr",
      type: "number",
      dataIndex: "{tableName}.units_sold_indirect_drr",
      title: "Units Sold Indirect (DRR)",
      shortTitle: "Units Sold Indirect (DRR)",
    },
    {
      key: "{tableName}.units_sold_indirect",
      type: "number",
      dataIndex: "{tableName}.units_sold_indirect",
      title: "Units Sold Indirect",
      shortTitle: "Units Sold Indirect",
    },
    {
      key: "{tableName}.revenue_direct",
      type: "number",
      format: "currency",
      dataIndex: "{tableName}.revenue_direct",
      title: "Revenue Direct",
      shortTitle: "Revenue Direct",
    },
    {
      key: "{tableName}.revenue_indirect",
      type: "number",
      format: "currency",
      dataIndex: "{tableName}.revenue_indirect",
      title: "Revenue Indirect",
      shortTitle: "Revenue Indirect",
    },
    {
      key: "{tableName}.direct_revenue_percentage",
      type: "number",
      format: "percent",
      dataIndex: "{tableName}.direct_revenue_percentage",
      title: "Revenue Direct (Percentage)",
      shortTitle: "Revenue Direct (Percentage)",
    },
    {
      key: "{tableName}.indirect_revenue_percentage",
      type: "number",
      format: "percent",
      dataIndex: "{tableName}.indirect_revenue_percentage",
      title: "Revenue Indirect (Percentage)",
      shortTitle: "Revenue Indirect (Percentage)",
    },
    {
      key: "{tableName}.asp",
      type: "number",
      dataIndex: "{tableName}.asp",
      title: "ASP",
      shortTitle: "ASP",
    },
    {
      key: "{tableName}.cpc",
      type: "number",
      dataIndex: "{tableName}.cpc",
      title: "CPC",
      shortTitle: "CPC",
    },
    {
      key: "{tableName}.revenue_direct_drr",
      type: "number",
      format: "currency",
      dataIndex: "{tableName}.revenue_direct_drr",
      title: "Revenue Direct (DRR)",
      shortTitle: "Revenue Direct (DRR)",
    },
    {
      key: "{tableName}.revenue_indirect_drr",
      type: "number",
      format: "currency",
      dataIndex: "{tableName}.revenue_indirect_drr",
      title: "Revenue Indirect (DRR)",
      shortTitle: "Revenue Indirect (DRR)",
    },
    {
      key: "{tableName}.asp_drr",
      type: "number",
      dataIndex: "{tableName}.asp_drr",
      title: "ASP (DRR)",
      shortTitle: "ASP (DRR)",
    },
    {
      key: "{tableName}.roas_drr",
      type: "number",
      dataIndex: "{tableName}.roas_drr",
      title: "ROAS (DRR)",
      shortTitle: "ROAS (DRR)",
    },
    {
      key: "{tableName}.cpc_drr",
      type: "number",
      dataIndex: "{tableName}.cpc_drr",
      title: "CPC (DRR)",
      shortTitle: "CPC (DRR)",
    },
    {
      key: "{tableName}.orders",
      type: "number",
      dataIndex: "{tableName}.orders",
      title: "Orders",
      shortTitle: "Orders",
    },
  ],
  flipkart: [
    {
      key: "{tableName}.product_id",
      title: "Variant Analysis Table Product ID",
      shortTitle: "Product ID",
      type: "string",
      // isSingleColumn is used to show the column in single column in comparison download
      isSingleColumn: true,
      dataIndex: "{tableName}.product_id",
    },
    {
      key: "{tableName}.product_name",
      title: "Variant Analysis Table Product Name",
      shortTitle: "Product Name",
      type: "string",
      isSingleColumn: true,
      dataIndex: "{tableName}.product_name",
    },
    {
      key: "{tableName}.roas",
      type: "number",
      dataIndex: "{tableName}.roas",
      title: "ROAS",
      shortTitle: "ROAS",
    },
    {
      key: "{tableName}.direct_roas",
      type: "number",
      dataIndex: "{tableName}.direct_roas",
      title: "ROAS Direct",
      shortTitle: "ROAS Direct",
    },
    {
      key: "{tableName}.spends",
      type: "number",
      dataIndex: "{tableName}.spends",
      title: "Spends",
      shortTitle: "Spends",
    },
    {
      key: "{tableName}.clicks",
      type: "number",
      dataIndex: "{tableName}.clicks",
      title: "Clicks",
      shortTitle: "Clicks",
    },
    {
      key: "{tableName}.ctr",
      type: "number",
      format: "percent",
      dataIndex: "{tableName}.ctr",
      title: "CTR",
      shortTitle: "CTR",
    },
    {
      key: "{tableName}.cvr",
      type: "number",
      format: "percent",
      dataIndex: "{tableName}.cvr",
      title: "Conv. Rate",
      shortTitle: "CVR",
    },
    {
      key: "{tableName}.revenue",
      type: "number",
      format: "currency",
      dataIndex: "{tableName}.revenue",
      title: "Revenue",
      shortTitle: "Revenue",
    },
    {
      key: "{tableName}.direct_revenue_percentage",
      type: "number",
      format: "percent",
      dataIndex: "{tableName}.direct_revenue_percentage",
      title: "Revenue Direct (Percentage)",
      shortTitle: "Revenue Direct (Percentage)",
    },
    {
      key: "{tableName}.indirect_revenue_percentage",
      type: "number",
      format: "percent",
      dataIndex: "{tableName}.indirect_revenue_percentage",
      title: "Revenue Indirect (Percentage)",
      shortTitle: "Revenue Indirect (Percentage)",
    },
    {
      key: "{tableName}.units_sold_direct",
      type: "number",
      dataIndex: "{tableName}.units_sold_direct",
      title: "Units Sold (Direct)",
      shortTitle: "Units Sold (Direct)",
    },
    {
      key: "{tableName}.units_sold_indirect",
      type: "number",
      dataIndex: "{tableName}.units_sold_indirect",
      title: "Units Sold (Indirect)",
      shortTitle: "Units Sold (Indirect)",
    },
    {
      key: "{tableName}.revenue_direct",
      type: "number",
      format: "currency",
      dataIndex: "{tableName}.revenue_direct",
      title: "Revenue (Direct)",
      shortTitle: "Revenue (Direct)",
    },
    {
      key: "{tableName}.revenue_indirect",
      type: "number",
      format: "currency",
      dataIndex: "{tableName}.revenue_indirect",
      title: "Revenue (Indirect)",
      shortTitle: "Revenue (Indirect)",
    },
    {
      key: "{tableName}.cpc",
      type: "number",
      dataIndex: "{tableName}.cpc",
      title: "CPC",
      shortTitle: "CPC",
    },
    {
      key: "{tableName}.impressions",
      type: "number",
      dataIndex: "{tableName}.impressions",
      title: "Impressions",
      shortTitle: "Impressions",
    },
    {
      key: "{tableName}.orders",
      type: "number",
      dataIndex: "{tableName}.orders",
      title: "Orders",
      shortTitle: "Orders",
    },
    {
      key: "{tableName}.cpm",
      type: "number",
      dataIndex: "{tableName}.cpm",
      title: "CPM",
      shortTitle: "CPM",
    },
    {
      key: "{tableName}.aov",
      type: "number",
      dataIndex: "{tableName}.aov",
      title: "Average Order Value",
      shortTitle: "AOV",
    },
    {
      key: "{tableName}.acos",
      type: "number",
      dataIndex: "{tableName}.acos",
      title: "ACOS",
      shortTitle: "ACOS",
    },
    {
      key: "{tableName}.clicks_drr",
      type: "number",
      dataIndex: "{tableName}.clicks_drr",
      title: "Clicks (DRR)",
      shortTitle: "Clicks (DRR)",
    },
    {
      key: "{tableName}.spends_drr",
      type: "number",
      dataIndex: "{tableName}.spends_drr",
      title: "Spends (DRR)",
      shortTitle: "Spends (DRR)",
    },
    {
      key: "{tableName}.ctr_drr",
      type: "number",
      format: "percent",
      dataIndex: "{tableName}.ctr_drr",
      title: "CTR (DRR)",
      shortTitle: "CTR (DRR)",
    },
    {
      key: "{tableName}.cvr_drr",
      type: "number",
      format: "percent",
      dataIndex: "{tableName}.cvr_drr",
      title: "Conv. Rate (DRR)",
      shortTitle: "CVR (DRR)",
    },
    {
      key: "{tableName}.units_sold_drr",
      type: "number",
      dataIndex: "{tableName}.units_sold_drr",
      title: "Units Sold (DRR)",
      shortTitle: "Units Sold (DRR)",
    },
    {
      key: "{tableName}.units_sold_direct_drr",
      type: "number",
      dataIndex: "{tableName}.units_sold_direct_drr",
      title: "Units Sold (Direct) (DRR)",
      shortTitle: "Units Sold (Direct) (DRR)",
    },
    {
      key: "{tableName}.units_sold_indirect_drr",
      type: "number",
      dataIndex: "{tableName}.units_sold_indirect_drr",
      title: "Units Sold (Indirect) (DRR)",
      shortTitle: "Units Sold (Indirect) (DRR)",
    },
    {
      key: "{tableName}.revenue_direct_drr",
      type: "number",
      format: "currency",
      dataIndex: "{tableName}.revenue_direct_drr",
      title: "Revenue (Direct) (DRR)",
      shortTitle: "Revenue (Direct) (DRR)",
    },
    {
      key: "{tableName}.revenue_indirect_drr",
      type: "number",
      format: "currency",
      dataIndex: "{tableName}.revenue_indirect_drr",
      title: "Revenue (Indirect) (DRR)",
      shortTitle: "Revenue (Indirect) (DRR)",
    },
    {
      key: "{tableName}.roas_drr",
      type: "number",
      dataIndex: "{tableName}.roas_drr",
      title: "ROAS (DRR)",
      shortTitle: "ROAS (DRR)",
    },
    {
      key: "{tableName}.cpc_drr",
      type: "number",
      dataIndex: "{tableName}.cpc_drr",
      title: "CPC (DRR)",
      shortTitle: "CPC (DRR)",
    },
    {
      key: "{tableName}.revenue_drr",
      type: "number",
      format: "currency",
      dataIndex: "{tableName}.revenue_drr",
      title: "Revenue (DRR)",
      shortTitle: "Revenue (DRR)",
    },
  ],
};

export const getColumnsConfig = (
  tableName,
  visibleColumns,
  isComparisonApi = false
) => {
  const userPlatform = JSON.parse(localStorage.getItem("user_platform"));
  let columnConfig = platformWiseColumnConfig[userPlatform];
  //first map then filter because we need to replace the tableName first and then filter the visible columns
  return columnConfig
    .map((column) => {
      return {
        ...column,
        key: tableName
          ? column.key.replace("{tableName}", tableName)
          : column.key.replace("{tableName}.", ""),
        dataIndex: `${
          !column?.isSingleColumn && isComparisonApi ? "startDate-endDate," : ""
        }${
          tableName
            ? column.dataIndex.replace("{tableName}", tableName)
            : column.dataIndex.replace("{tableName}.", "")
        }`,
      };
    })
    .filter((column) => {
      return visibleColumns.includes(column.key);
    });
};