import moment from "moment"
import { keyExtractor } from "../widget"

export default function createDataForCSV (headers,bodyData) {
    const TABLE_NAME = 'rule_entity_tracking'
    const excelBodyRow = bodyData?.reduce((acc,curr) => {
        const row = headers?.map((header) => {
            let valueForRow = ""
            for (const key in curr) {
                if(keyExtractor(key) === header?.id) {
                    valueForRow = curr[key]
                }
            }
            if(header?.id === 'rule_config') {
                if(JSON.parse(curr['rule_entity_tracking.rule_config'])?.ruleActionConfig?.entityActionConfig?.percentageChange === undefined) {
                    valueForRow = JSON.parse(curr['rule_entity_tracking.rule_config'])?.ruleActionConfig?.entityActionConfig?.actionType 
                  } else {
                    valueForRow = JSON.parse(curr['rule_entity_tracking.rule_config'])?.ruleActionConfig?.entityActionConfig?.actionType + " by "+ JSON.parse(curr[`${`rule_entity_tracking.rule_config`}`])?.ruleActionConfig?.entityActionConfig?.percentageChange + "%"
                  }
            } else if(header?.id === 'parent_rule_config') {
                valueForRow = getCommaSepratedValue(curr['rule_tracking.parent_rule_config']) ?? "N/A"
            } else if (header?.id === "lookback_window") { 
                valueForRow = JSON.parse(curr[`rule_entity_tracking.rule_config`])?.entityMetricFilter?.timePeriod 
            } else if(header?.id === 'created_at') {
                valueForRow = moment.utc(curr[`${TABLE_NAME}.created_at`]).local().format("HH:mm:ss DD-MM-YYYY")
            } else if (header?.id === 'decision_old' && JSON.parse(curr[`${TABLE_NAME}.decision`]).extraInfo && JSON.parse(curr[`${TABLE_NAME}.decision`]).extraInfo.OldBid !== undefined)  {
                valueForRow = JSON.parse(curr[`${TABLE_NAME}.decision`])?.extraInfo?.OldBid
            } else if (header?.id === 'decision_new' && JSON.parse(curr[`${TABLE_NAME}.decision`]).extraInfo && JSON.parse(curr[`${TABLE_NAME}.decision`]).extraInfo.NewBid !== undefined) {
                valueForRow = JSON.parse(curr[`${TABLE_NAME}.decision`]).extraInfo.NewBid
              } else if (header?.id === 'decision_status') {
                if(JSON.parse(curr[`${TABLE_NAME}.decision`]).decisionApplied === true) {
                  valueForRow = "True"
                } else {
                  valueForRow = "False"
                }
              }
            return valueForRow;
        })
        acc?.push(row)
        return acc
    },[])
    return {excelBodyRow,headers:headers?.map((header,index) => header?.fieldName)}
}    


function getCommaSepratedValue (data) {
    
    let formattedValues = JSON.parse(data)?.scheduleConfig?.frequencyValues.map(day => day.charAt(0).toUpperCase() + day.slice(1))
    if(formattedValues) {
      return formattedValues.join(', ')
    } else {
      // console.log(JSON.parse(data))
      return JSON.parse(data)?.scheduleConfig?.frequencyValues
    }
    
  }
