import React from "react";
import "./optimizertable.css";
import DataNotFound from "../../DataNotFound/DataNotFound";
import ShimmerTable from "../../Tables/ShimmerTable";
import ReactSelect from "react-select";
import { useState } from "react";
import { useRef } from "react";
import LazyLoadSpinner from "../../spinners/LazyLoadSpinner";
import { useEffect } from "react";
import { Pagination } from "react-bootstrap";
import CustomPagination from "../../CustomPagination/CustomPagination";

const OptimizerTable = ({
  tableHeaders,
  resultSet,
  tableclassName,
  className,
  customClasses,
  renderSummaryRow,
  renderTableHeader,
  isSummaryRowRequired,
  renderTableRow,
  totalSummary,
  renderComparisonFirstRow,
  isComparisonView,
  tableLoading,
  childRowsLoading,
  isExpandable,
  getData,
  recordLimit,
  recieveLoadedData,
  resetOffset,
  noDataFound,
  mainTableClass,
  rowsCount,
  handleEditableCellClick,
  updateEditableCell,
  handleEditableValueChange
}) => {
  const [loading, setLoading] = useState(false);
  const mainRef = useRef(null);
  const [pageLimit, setPageLimit] = useState(50);


  const allRows = resultSet?.map((result, index) => {
    return renderTableRow(
      result,
      index,
      childRowsLoading,
      isExpandable
    );
  })
  const currentRowCount = rowsCount || 0;
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationOffset, setPaginationOffset] = useState(0);
  const currentRows = allRows ?? []
  const count = rowsCount;
  const {isNext,isPrev,totalPages} = getPaginationInfo(count ?? 0,pageLimit,currentPage)
  // const {totalPages:totalPagesMain} = getPaginationInfo(overAllAdsCount?.total_count ?? 0,frontEndPaginationLimit,currentPage)
  function getPaginationInfo(totalNumber, limit, pageNumber) {
    // Calculate total number of pages
    const totalPages = Math.ceil(totalNumber / limit);
    // Determine if there's a next page
    const isNext = pageNumber < totalPages;
    // Determine if there's a previous page
    const isPrev = pageNumber > 1;
    return {
        isNext: isNext,
        isPrev: isPrev,
        totalPages: totalPages
    };
}

  useEffect(() => {
    setCurrentPage(1);
  },[resetOffset])

  function getFreezeColumnClassName (keyName) {
    switch (keyName) {
      case "targeting":
       return "targeting-columns"
      default:
        break;
    }
  }

   const handlePageChange = async (pageNumber) => {
    
    const currentOffset = (pageNumber - 1) * pageLimit;
    const dataForMoreData = {
          page: pageNumber,
          offset: currentOffset,
          limit: pageLimit,
        };
        setLoading(true);
        const mainData = await getData(dataForMoreData);
        recieveLoadedData(mainData);
        setCurrentPage(pageNumber);
        setPaginationOffset(currentOffset);
        setLoading(false);
        mainRef.current.scrollTop = 0;
    };
  
    useEffect(() => {
      handlePageChange(currentPage)
    }, [pageLimit]);
  


  
  return (
    <div className="optimizer-main-table">
      <div
        className={
          className
            ? `main-tableRb-body  position-relative d-flex flex-column` +
              className
            : "main-tableRb-body position-relative d-flex flex-column"
        }
      >
        <div
          className={
            "tableRb text-center" + (tableclassName ? tableclassName : "")
          }
        >
          <div
            className={
              "main-campaignlist-table-body main-targeting-table-body-container listinng-table comparison-view-wrapper" + (mainTableClass ? mainTableClass : "")
            }
            ref={mainRef}
            // onScroll={handleScroll}
          >
            {
              <>
                {tableLoading || loading ? (
                  <ShimmerTable
                    rows={20}
                    tableHeaders={tableHeaders}
                    hideHeaders={false}
                  />
                ) : noDataFound ? (
                  <DataNotFound />
                ) : (
                  <>
                    <table className={`optimizer-table targeting-columns`}>
                      <thead className="fixed-table">
                        <tr className="main-table-row-fixed">
                          {tableHeaders?.map((data) => {
                            return renderTableHeader(data, isComparisonView);
                          })}
                        </tr>
                      </thead>
                      {resultSet?.length ? (
                        <tbody className="another-tbody">
                          {isSummaryRowRequired
                            ? renderSummaryRow(totalSummary)
                            : null}
                          {isComparisonView ? renderComparisonFirstRow() : null}
                          {/* {currentRows?.map((result, index) => {
                            return renderTableRow(
                              result,
                              index,
                              childRowsLoading,
                              isExpandable
                            );
                          })} */}
                          {currentRows}
                        </tbody>
                      ) : (
                        <DataNotFound />
                      )}
                    </table>
                    {/* {loading && (
                      <div className="lazy-loading-container d-flex p-2 justify-content-center align-items-center w-100">
                        <LazyLoadSpinner
                          text={`Loading ${recordLimit} More Items...`}
                        />
                      </div>
                    )} */}
                  </>
                )}
              </>
            }
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center gap-1 py-2 justify-content-between">
          {totalPages > 1 && <div style={{ width: "auto",display:"flex",gap:"10px",alignItems:"center", color:"#4f5366" }} >
                  <label htmlFor="pageLimit">Page Size</label>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="Select Page Size"
                      menuPlacement={"auto"}
                      searchable={false}
                      options={[{label:"50",value:50},{label:"100",value:100},{label:"200",value:200},{label:"300",value:300},{label:"500",value:500}]}
                      value={{label:pageLimit,value:pageLimit}}
                      onChange={(item) => {
                        setPageLimit(item.value)
                      }}
                      disabled={loading}
                      styles={{
                          control: (provided, state) => ({
                              ...provided,
                              height: "36px",
                              fontSize: "14px",
                            }),
                          menu: (provided, state) => ({
                              ...provided,
                              maxHeight: "180px",
                              overflow: "hidden",
                              fontSize: "14px",
                              zIndex: "99",
                              }),
                          menuList: (provided, state) => ({
                              ...provided,
                              fontSize: "14px",
                              maxHeight: "150px", // Ensure the menu list also respects the max height
                              }),
                              }}
                      />
            </div>}   
                 <CustomPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                  lazyLoading={loading}
                  />
            </div>
      </div>
  );
};

export default OptimizerTable;
