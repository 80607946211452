import React from "react";
import movingUp from "../../../assets/svgs/arrow_upward_green.svg";
import movingDown from "../../../assets/svgs/arrow_downward_red.svg";
import movingUpRed from "../../../assets/svgs/arrow_upward_red.svg";
import movingDownGreen from "../../../assets/svgs/arrow_downward_green.svg";
import numeral from 'numeral';
import defaultDateRangesWithLables from "../../../constant/calendarDefaultConfigurations";
import { keyExtractor } from "../../../helper/widget";

export function humanize(str) {
  var i,
    frags = str?.split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i]?.charAt(0)?.toUpperCase() + frags[i]?.slice(1);
  }
  return frags?.join(" ");
}

export const COLORS_SERIES = [
  '#1D216E', '#50A5B5', '#DEC46A', '#81B550','#703AC7',
  "#7078CC",
  "#70BDCC",
  "#E5DB7E",
  "#9CCC70",
  "#0099c6",
  "#00A0FA",
  "#BEB1F9",
  "#FF9800",
  "#7078CC",
  "#70BDCC",
  "#3366cc",
  "#dc3912",
  "#ff9900",
  "#109618",
  "#990099",
  "#0099c6",
  "#dd4477",
  "#66aa00",
  "#b82e2e",
  "#316395",
  "#3366cc",
  "#994499",
  "#22aa99",
  "#aaaa11",
  "#6633cc",
  "#e67300",
  "#8b0707",
  "#651067",
  "#329262",
  "#5574a6",
  "#3b3eac",
  "#b77322",
  "#16d620",
  "#b91383",
  "#f4359e",
  "#9c5935",
  "#a9c413",
  "#2a778d",
  "#668d1c",
  "#bea413",
  "#0c5922",
  "#743411",
];
export const PALE_COLORS_SERIES = [
  "#2C91FF",
  "#93c7d2",
  "#eddeab",
  "#b1d293",
  "#BEB1F9",
  "#86aaf9",
  "#a1d3dd",
  "#d6dbe4",
  "#fdeecd",
  "#dad8fe",
];
export const commonOptions = {
  responsive: !0,
  interaction: { mode: "index", intersect: !0 },
  plugins: { legend: { position: "top" } },
  scales: {
    x: { ticks: { autoSkip: !0, maxRotation: 0, padding: 12, minRotation: 0 } },
    y0: { type: "linear", display: !0, position: "left" },
    y1: {
      type: "linear",
      display: !0,
      position: "right",
      grid: { drawOnChartArea: !1 },
    },
  },
};
export const useDrilldownCallback = ({
  datasets,
  labels,
  onDrilldownRequested,
  pivotConfig,
}) => {
  return React.useCallback(
    (elements) => {
      if (elements.length <= 0) return;
      const { datasetIndex, index } = elements[0];
      const { yValues } = datasets[datasetIndex];
      const xValues = [labels[index]];
      if (typeof onDrilldownRequested === "function") {
        onDrilldownRequested({ xValues, yValues }, pivotConfig);
      }
    },
    [datasets, labels, onDrilldownRequested]
  );
};

export const formatTableData = (columns, data) => {
  function flatten(columns = []) {
    return columns.reduce((memo, column) => {
      if (column.children) {
        return [...memo, ...flatten(column.children)];
      }
      return [...memo, column];
    }, []);
  }
  const typeByIndex = flatten(columns).reduce((memo, column) => {
    return { ...memo, [column.dataIndex]: column };
  }, {});

  function formatValue(value, { type, format } = {}) {
    if (value == undefined) {
      return value;
    }
    if( value === "NaN") {
      return 0;
    }
    if(value == Infinity || value == -Infinity) {
      return "∞";
    }
    if (type === "boolean") {
      if (typeof value === "boolean") {
        return value.toString();
      } else if (typeof value === "number") {
        return Boolean(value).toString();
      }
      return value;
    }
    if (type === "number" && format === "percent") {
      return [parseFloat(value).toFixed(2), "%"].join("");
    }
    return value.toString();
  }

  function format(row) {
    return Object.fromEntries(
      Object.entries(row).map(([dataIndex, value]) => {
        return [dataIndex, formatValue(value, typeByIndex[dataIndex])];
      })
    );
  }
  return data.map(format);
};

export const createQueryURL = (allFilters) => {
  let url = {}
  for(const filter of allFilters) {
   const filterName = filter.member.split('.')[1]
   if(filterName!=='profileId' && filterName!=="profile_id" && !allMetrics?.includes(filterName) && filterName!=="parentSku") {
     url[filterName] = filter.values.join(",")
   }
  }
 return url
}

export const dataQueryParser = (currentData,e,type,tableName) => {
  let data = [...currentData];
  data.forEach((x, i) => {
    if (x.member === `${tableName}.` + type) {
      data.splice(i, 1);
    }
  });
  if (e.length > 0) {
    data.push({
      member: `${tableName}.` + type,
      operator: "equals",
      values: e,
    });
  }
  return data
}

export async function saveCustomView(headings, clientId, datapresent,key) {
  let type = "POST";
  let apiUrl =
    process.env.REACT_APP_ADS_API + "/client/" + clientId + "/custom-config";
  const result = await getCustomView(clientId, key);
  let output = result.value;
  if (output !== undefined && output !== null && output !== "") {
    type = "PUT";
    apiUrl =
      process.env.REACT_APP_ADS_API +
      "/client/" +
      clientId +
      "/custom-config/" +
      result.id;
  }

  const data = { key: key, value: headings };
  fetch(apiUrl, {
    method: type,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {});
}

export async function getCustomView(clientId,key) {
  const apiUrl =
    process.env.REACT_APP_ADS_API +
    "/client/" +
    clientId +
    "/custom-config?key="+key;
  let output = false;
  await fetch(apiUrl, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => {
      output = data;
    });
  return output;
}

export const allMetrics = [
  "cost",
  "costDRR",
  "impressions",
  "impressionsDRR",
  "clicks",
  "clicksDRR",
  "conversions14d",
  "conversions14dDRR",
  "sales14d",
  "sales14dDRR",
  "cpm",
  "cpmDRR",
  "ctr",
  "ctrDRR",
  "cpc",
  "cpcDRR",
  "conversion_rate",
  "conversion_rateDRR",
  "roas",
  "roasDRR",
  "acos",
  "acosDRR",
  "average_order_value",
  "average_order_valueDRR",
];

export const measuresFunnal = {
  amazon:[
    "dateDiff",
    "costDRR",
    "impressions",
    "impressionsDRR",
    "clicks",
    "clicksDRR",
    "ctrDRR",
    "cpcDRR",
    "cpmDRR",
    "conversions14dDRR",
    "conversion_rate",
    "conversion_rateDRR",
    "sales14dDRR",
    "average_order_valueDRR",
    "acosDRR",
    "roasDRR",
],
myntra:[
  "spends_drr",
  "views",
  "views_drr",
  "clicks",
  "clicks_drr",
  "ctr_drr",
  "cpc_drr",
  "units_sold_direct_drr",
  "units_sold_indirect_drr",
  "revenue_indirect_drr",
  "cvr",
  "cvr_drr",
  "revenue_direct_drr",
  "asp_drr",
  "roas_drr",
],
flipkart:[
  "spends_drr",
  "views",
  "views_drr",
  "clicks",
  "clicks_drr",
  "ctr_drr",
  "cpc_drr",
  "revenue_drr",
  "units_sold_drr",
  "units_sold_direct_drr",
  "units_sold_indirect_drr",
  "revenue_indirect_drr",
  "cvr",
  "cvr_drr",
  "revenue_direct_drr",
  "asp_drr",
  "roas_drr",
]
}
export const measuresFunnalDRR = {
  amazon:[
    "dateDiff",
      "cost",
      "impressions",
      "impressionsDRR",
      "clicks",
      "clicksDRR",
      "ctr",
      "cpc",
      "cpm",
      "cpmDRR",
      "conversions14d",
      "conversion_rate",
      "conversion_rateDRR",
      "sales14d",
      "average_order_value",
      "acos",
      "roas",
  ],
  myntra:[
    "spends",
    "views",
    "views_drr",
    "clicks",
    "clicks_drr",
    "ctr",
    "cpc",
    "units_sold_direct",
    "cvr",
    "cvr_drr",
    "revenue",
    "mrp",
    "units_sold_indirect",
    "revenue_direct",
    "revenue_indirect",
    "orders",
    "asp",
    "roas",
    "direct_revenue_percentage",
    "indirect_revenue_percentage",
    "direct_roas"
  ],
  flipkart:[
    "impressions",
    "cpm",
    "aov",
    "acos",
    "spends",
    "views",
    "views_drr",
    "clicks",
    "clicks_drr",
    "ctr",
    "cpc",
    "units_sold_direct",
    "cvr",
    "cvr_drr",
    "revenue",
    "mrp",
    "units_sold_indirect",
    "revenue_direct",
    "revenue_indirect",
    "direct_revenue_percentage",
    "indirect_revenue_percentage",
    "orders",
    "asp",
    "roas",
    "direct_roas"
  ],
}
export const measuresPerformance = {
  amazon:[
    "dateDiff",
    "cost",
    "costDRR",
    "impressionsDRR",
    "clicksDRR",
    "ctrDRR",
    "cpc",
    "cpcDRR",
    "cpm",
    "cpmDRR",
    "conversions14dDRR",
    "conversion_rateDRR",
    "sales14d",
    "sales14dDRR",
    "average_order_value",
    "average_order_valueDRR",
    "acos",
    "acosDRR",
    "roas",
    "roasDRR",
  ],
  myntra:[
    "spends",
    "spends_drr",
    "views_drr",
    "clicks_drr",
    "ctr_drr",
    "cpc",
    "cpc_drr",
    "units_sold_direct_drr",
    "cvr_drr",
    "revenue_direct",
    "revenue_direct_drr",
    "units_sold_indirect_drr",
    "revenue_indirect_drr",
    "asp",
    "asp_drr",
    "roas",
    "roas_drr",
    "direct_revenue_percentage",
    "indirect_revenue_percentage",
    "direct_roas",
  ],
  flipkart:[
    "impressions",
    "cpm",
    "aov",
    "acos",
    "spends",
    "spends_drr",
    "views_drr",
    "clicks_drr",
    "ctr_drr",
    "cpc",
    "cpc_drr",
    "units_sold_direct_drr",
    "cvr_drr",
    "revenue_direct",
    "direct_revenue_percentage",
    "indirect_revenue_percentage",
    "revenue_drr",
    "units_sold_drr",
    "revenue_direct_drr",
    "units_sold_indirect_drr",
    "revenue_indirect_drr",
    "asp",
    "asp_drr",
    "roas",
    "direct_roas",
    "roas_drr",
  ]
}
export const measuresPerformanceDRR = {
  amazon:[
    "dateDiff",
      "cost",
      "costDRR",
      "impressions",
      "clicks",
      "ctr",
      "cpc",
      "cpcDRR",
      "cpm",
      "cpmDRR",
      "conversions14d",
      "conversion_rate",
      "sales14d",
      "sales14dDRR",
      "average_order_value",
      "average_order_valueDRR",
      "acos",
      "acosDRR",
      "roas",
      "roasDRR",
  ],
  myntra:[
    "spends",
    "spends_drr",
    "views",
    "clicks",
    "ctr",
    "cpc",
    "cpc_drr",
    "units_sold_direct",
    "cvr",
    "revenue_direct",
    "revenue_direct_drr",
    "revenue_drr",
    "asp",
    "asp_drr",
    "revenue",
    "mrp",
    "units_sold_indirect",
    "revenue_indirect",
    "orders",
    "roas",
    "roas_drr",
    "direct_revenue_percentage",
    "indirect_revenue_percentage",
    "direct_roas"
  ],
  flipkart:[
    "impressions",
    "cpm",
    "aov",
    "acos",
    "spends",
    "spends_drr",
    "views",
    "clicks",
    "ctr",
    "cpc",
    "cpc_drr",
    "units_sold_direct",
    "cvr",
    "revenue_direct",
    "direct_revenue_percentage",
    "indirect_revenue_percentage",
    "revenue_direct_drr",
    "asp",
    "asp_drr",
    "revenue",
    "mrp",
    "units_sold_indirect",
    "revenue_indirect",
    "orders",
    "roas",
    "direct_roas",
    "roas_drr",
  ]
}
export const ComparisonViewFunel = {
  amazon:[
    "dateDiff",
      "costDRR",
      "impressions",
      "impressionsDRR",
      "clicks",
      "clicksDRR",
      "ctrDRR",
      "cpcDRR",
      "cpm",
      "cpmDRR",
      "conversions14dDRR",
      "conversion_rateDRR",
      "sales14dDRR",
      "average_order_valueDRR",
      "acosDRR",
      "roasDRR"
  ],
  myntra:
    [
    "clicks_drr",
    "spends_drr",
    "views_drr",
    "ctr_drr",
    "cvr_drr",
    "units_sold_direct_drr",
    "units_sold_indirect_drr",
    "unit_sold_direct_drr",
    "unit_sold_indirect_drr",
    "revenue_direct_drr",
    "revenue_indirect_drr",
    "asp_drr",
    "roas_drr",
    "cpc_drr",
  ],
  flipkart:
    [
    "clicks_drr",
    "spends_drr",
    "views_drr",
    "ctr_drr",
    "cvr_drr",
    "units_sold_direct_drr",
    "units_sold_indirect_drr",
    "unit_sold_direct_drr",
    "unit_sold_indirect_drr",
    "revenue_direct_drr",
    "revenue_drr",
    "units_sold_drr",
    "revenue_indirect_drr",
    "asp_drr",
    "roas_drr",
    "cpc_drr",
  ]
};
export const ComparisonViewFunelDRR = {
  amazon:[
    "dateDiff",
      "cost",
      "impressions",
      "impressionsDRR",
      "clicks",
      "clicksDRR",
      "ctr",
      "cpc",
      "cpm",
      "cpmDRR",
      "conversions14d",
      "conversion_rate",
      "sales14d",
      "average_order_value",
      "acos",
      "roas"
  ],
  myntra:[
    "views",
    "clicks",
    "roas",
    "spends",
    "revenue",
    "mrp",
    "ctr",
    "cvr",
    "units_sold_direct",
    "units_sold_indirect",
    "revenue_direct",
    "revenue_indirect",
    "asp",
    "cpc",
    "orders",
    "direct_roas",
    "direct_revenue_percentage",
    "indirect_revenue_percentage",
  ],
  flipkart:[
    "impressions",
    "cpm",
    "aov",
    "acos",
    "views",
    "clicks",
    "roas",
    "direct_roas",
    "spends",
    "revenue",
    "mrp",
    "ctr",
    "cvr",
    "units_sold_direct",
    "units_sold_indirect",
    "revenue_direct",
    "revenue_indirect",
    "direct_revenue_percentage",
    "indirect_revenue_percentage",
    "asp",
    "cpc",
    "orders",
  ]
};

export const linkedStrings = [
  "week",
  "month",
  "Category",
  "Date",
  "Portfolio Name",
  "Campaign Format",
  "Targeting Type",
  "Campaign Name",
  "Child ASIN",
];

export const formulatedStrings = [
  "cpm",
  "ctr",
  "cpc",
  "conversion_rate",
  "average_order_value",
  "roas",
  "acos",
  "cpmDRR",
  "ctrDRR",
  "cpcDRR",
  "conversion_rateDRR",
  "average_order_valueDRR",
  "roasDRR",
  "acosDRR",
];

export const metricStrings = [
    "cost",
    "costDRR",
    "impressions",
    "impressionsDRR",
    "clicks",
    "clicksDRR",
    "conversions14d",
    "conversions14dDRR",
    "sales14d",
    "sales14dDRR",
    "TotalOrderItemCount"
];

export const expendableStrings = [
  "Parent ASIN",
  "Campaign Format",
  "Placement",
  "Targeting",
  "Query",
  "Portfolio Name",
  "Campaign Format",
  "Broad Category"
];

export const defaultRadioValues = ["Performance View", "Funnel View", "Custom View"]

export const summaryMapping = {
  cpm: { numeratorKey: "cost", denominatorKey: "impressions", multiplier: 1000 },
  ctr: { numeratorKey: "clicks", denominatorKey: "impressions", multiplier: 100, symbol: "%" },
  cpc: { numeratorKey: "cost", denominatorKey: "clicks" },
  acos: { numeratorKey: "cost", denominatorKey: "sales14d", multiplier: 100 },
  roas: { numeratorKey: "sales14d", denominatorKey: "cost" },
  average_order_value: { numeratorKey: "sales14d", denominatorKey: "conversions14d", decimal: 0 },
  conversion_rate: { numeratorKey: "conversions14d", denominatorKey: "clicks", multiplier: 100, symbol: "%" },
  cpmDRR: { numeratorKey: "cost", denominatorKey: "impressions", multiplier: 1000 },
  ctrDRR: { numeratorKey: "clicks", denominatorKey: "impressions", multiplier: 100, symbol: "%" },
  cpcDRR: { numeratorKey: "cost", denominatorKey: "clicks" },
  acosDRR: { numeratorKey: "cost", denominatorKey: "sales14d", multiplier: 100 },
  roasDRR: { numeratorKey: "sales14d", denominatorKey: "cost" },
  average_order_valueDRR: { numeratorKey: "sales14d", denominatorKey: "conversions14d", decimal: 0 },
  conversion_rateDRR: { numeratorKey: "conversions14d", denominatorKey: "clicks", multiplier: 100, symbol: "%" },
  costDRR: { numeratorKey: "cost", denominatorKey: "dateDiff", decimal: 0 },
  impressionsDRR: { numeratorKey: "impressions", denominatorKey: "dateDiff", decimal: 0 },
  clicksDRR: { numeratorKey: "clicks", denominatorKey: "dateDiff", decimal: 0 },
  conversions14dDRR: { numeratorKey: "conversions14d", denominatorKey: "dateDiff", decimal: 0 },
  sales14dDRR: { numeratorKey: "sales14d", denominatorKey: "dateDiff", decimal: 0 },
  MaximumRetailPrice: { numeratorKey: "MaximumRetailPrice", hideSummary: true },
  BSR: { numeratorKey: "BSR", hideSummary: true },
  total_asp: { numeratorKey: "total_asp" },
  troas: { numeratorKey: "troas" },
  tacos: { numeratorKey: "tacos" },
  trevnue: { numeratorKey: "trevnue" },
  tunits_sold: { numeratorKey: "tunits_sold" },
  Quantity: { numeratorKey: "Quantity", hideSummary: true },
  SalePrice: { numeratorKey: "SalePrice" , hideSummary: true }
}
;


export function sortArrayOfObjectsByLabel(data,label) {
  return data.sort((a, b) => {
    let labelA = a[label].toLowerCase();
    let labelB = b[label].toLowerCase();

    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });
}


export const formatDate = (string) => {
  let date = new Date(string);
  const offset = date.getTimezoneOffset()
  date = new Date(date.getTime() - (offset*60*1000))
  return date.toISOString().split('T')[0].toString()
};

export const calenderDate = (formattedDateString) => {
  const dateParts = formattedDateString.split('-');
  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]) - 1; // Months are zero-based in JavaScript Date object
  const day = parseInt(dateParts[2]);

  const date = new Date(year, month, day);
  return date;
};


export const getSpecificDataFormat = (date) => {
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear().toString().slice(-2);
  return `${day} ${month} ${year}`;
};

export const getSelectedPresetLabel = (range) => {
  for (let i = 0; i < defaultDateRangesWithLables.length; i++) {
    const staticRange = defaultDateRangesWithLables[i].range();
    if (
      range.startDate.toString() === staticRange.startDate.toString() &&
      range.endDate.toString() === staticRange.endDate.toString()
    ) {
      return { date: defaultDateRangesWithLables[i].label, isCustom: false };
    }
  }

  const formattedStartDate = getSpecificDataFormat(range.startDate);
  const formattedEndDate = getSpecificDataFormat(range.endDate);
  return {
    date: formattedStartDate !== formattedEndDate
      ? formattedStartDate + " - " + formattedEndDate
      : formattedStartDate,
    isCustom: true,
  };
};

export function displayDate(data, type) {
  if (type === "date") {
    return new Date(data).toLocaleDateString(
      "en-US",
      {
        day: "numeric",
        year: "numeric",
        month: "long",
      }
    )
  } else if (type === "month") {
    return new Date(data).toLocaleDateString(
      "en-US",
      {
        month: "long",
        year:"numeric"
      }
    )
  }
}

export const objectoFiltering  = (queryObject, tableName) => {
  const mainFiltering = [];
  for (let key in queryObject) {
    if (key !== "viewtype" && key!=="start" && key!=="end" && key!=="metricViewType" && key!=="performanceGroupType" && key!=="reportType" && key!=="parentSku" && key !=="urlQuery" && key !== "downloadLimit") {
      const operator = tableName === 'AmazonTargetTable' && key === 'adyogiTargetingType' ? 'contains' : 'equals'
      if(queryObject[key]!=="" && queryObject[key]!==undefined) {
        mainFiltering.push({
          member: `${tableName}.${key}`,
          operator: operator,
          values:queryObject[key]?.split(","),
        });
      }
    }
  }
  return mainFiltering;
}

export const  getSearchParamsObject = (object) => {
  const paramsObject = {};

    for (const [key, value] of object.entries()) {
        // Convert values to appropriate types if needed
        paramsObject[key] = value;
    }

    return paramsObject;
 }

export const format = '0,0'; // 10,000

export const formatDecimal = '0,0.00'; //10,000.00
export const format1Decimal = '0,0.0'; //10,000.0
export const formatK = '0 a'; // 10k

export function formatPieChartData(result,type,table,dimension) {
  if(Object?.keys(result ?? {})?.length > 0) {
  let cubeResult = JSON.parse(JSON.stringify(result))
  let returnResultLabel = [];
  let returnResultSeries = [];
  let data = cubeResult?.loadResponse?.results[0]?.data
  let tempArray = data.map((value, index) => {
      if(index < 5) {
          if( typeof value[`${table}.${dimension}`] === 'undefined' || value[`${table}.${dimension}`] === null ) {
              returnResultLabel.push("NULL")
          } else {
              returnResultLabel.push(value[`${table}.${dimension}`])
          }
          returnResultSeries.push(Math.round(parseFloat(value[`${table}.${type}`])));
      } else if(index === 5) {
          returnResultLabel.push('Other')
          returnResultSeries.push(
            data.reduce((acc, obj, index) => {
              if (index > 4) {
                if (typeof acc == "undefined") {
                  acc = 0;
                }
                return acc + Math.round(parseFloat(obj[`${table}.${type}`]));
              }
            }, 0)
          );
      } 
  });
  return {"label":returnResultLabel,"series":returnResultSeries};
  } 
}

export function formatPieChartDataComplete(result,type,table,dimension) {
  if(Object.keys(result).length > 0) {
  let cubeResult = JSON.parse(JSON.stringify(result))
  let returnResultLabel = [];
  let returnResultSeries = [];
  let data = cubeResult?.loadResponse?.results[0]?.data
  let tempArray = data.map((value, index) => {
          if( typeof value[`${table}.${dimension}`] === 'undefined' || value[`${table}.${dimension}`] === null ) {
              returnResultLabel.push("NULL")
          } else {
              returnResultLabel.push(value[`${table}.${dimension}`])
          }
          returnResultSeries.push(Math.round(parseFloat(value[`${table}.${type}`])));

  });
  return {"label":returnResultLabel,"series":returnResultSeries};
  } 
}

export const getDivideData = (val1, val2) => {
  return parseFloat(val2) !== 0 ? val1 / val2 : 0;
};

export const getComparisonHeader = (data) => {
  const convertArray = (arr) => {
    return arr.map((obj) => {
      if (obj.children) {
        const children = obj.children.map((child) => ({
          key: child.key,
          type: child.type,
          format: child.format,
          meta: child.meta,
          dataIndex: child.dataIndex,
          title: child.title,
          shortTitle: child.shortTitle,
          fieldName:child?.shortTitle
        }));
        return children;
      } else {
        return obj;
      }
    });
  };
  const outputArray = convertArray(data);
  const mergedArray = outputArray.reduce((acc, curr) => acc.concat(curr), []);
  const uniqueKeys = new Set();
  const filteredData = mergedArray.filter((obj) => {
    if (!uniqueKeys.has(obj.key)) {
      uniqueKeys.add(obj.key);
      return true;
    }
    return false;
  });
  return filteredData;
};


export function getPercentageChange(oldValue, newValue) {
  if (oldValue === 0 || isNaN(oldValue) || isNaN(newValue)) return "0.0";
  return (((newValue - oldValue) / oldValue) * 100).toFixed(1);
}
const getCurrentData = (resultSet, objKey, objValue, dataIndex) => {
  let outputObject = {};
  resultSet.loadResponses.forEach((obj, index) => {
    if (index === 0) {
      obj.data.forEach((key, index1) => {
        Object.entries(key).forEach(([key1, value], innerIndex) => {
          if (dataIndex) {
            if (innerIndex === dataIndex) {
              if (key1 === objKey && value === objValue) {
                outputObject = obj.data[index1];
              }
            }
          } else {
            if (innerIndex === 0) {
              if (key1 === objKey && value === objValue) {
                outputObject = obj.data[index1];
              }
            }
          }
        });
      });
    }
  });
  return outputObject;
};

// const getPreviousData = (resultSet, objKey, objValue, dataIndex) => {
//   let outputObject = {};
//   resultSet.loadResponses.forEach((obj, index) => {
//     if (index === 1) {
//       obj.data.forEach((key, index1) => {
//         Object.entries(key).forEach(([key1, value], innerIndex) => {
//           if (dataIndex) {
//             if (innerIndex === dataIndex) {
//               if (key1 === objKey && value === objValue) {
//                 outputObject = obj.data[index1];
//               }
//             }
//           } else {
//             if (innerIndex === 0) {
//               if (key1 === objKey && value === objValue) {
//                 outputObject = obj.data[index1];
//               }
//             }
//           }
//         });
//       });
//     }
//   });
//   return outputObject;
// };
const getPreviousData = (resultSet, dimensionKeys, dimensionValues) => {
  if (!resultSet.loadResponses[1]?.data) return {};

  return (
    resultSet.loadResponses[1].data.find((item) =>
      dimensionKeys.every((key) => item[key] === dimensionValues[key])
    ) || {}
  );
};



export const getComparisonFormatTimeDimension = (input) => {

    return input.flatMap(item => 
        item.compareDateRange.map(range => ({
            dimension: item.dimension,
            dateRange: range
        }))
    );

}

// export const getComparisonDataForWrapperApi = (data,columns) => {
//   console.log("columns",columns)
//   const mergedData = [];
//   data.forEach((item, index) => {
//     let dataObj = {};
//     Object.entries(item).forEach(([key, value], innerIndex) => {
//       if(typeof value !== 'object') {
//         dataObj[key] = value ?? "-";
//       }else if( key === "current_data"){
//         Object.entries(value).forEach(([key1, value1], innerIndex) => {
//           dataObj[key1] = {
//             current: { value : value1 ?? "-", change: item["change_percentage_data"][key1]},
//             previous: item["comparison_data"][key1] ?? "-"
//           };
//         });
//       }
//   });
//   mergedData.push(dataObj)
//   })
//   return mergedData;
// }
export const getComparisonDataForWrapperApi = (data, columns) => {
  const mergedData = [];
  data.forEach((item) => {
    const dataObj = {};

    columns.forEach((column) => {
      const { dataIndex, key } = column;

      if (dataIndex.split(",")?.length > 1) {
        // Handle columns with previous and current values
        dataObj[key] = {
          current: {
            value: item.current_data[key] ?? "-",
            change: item.change_percentage_data[key] ?? "-",
          },
          previous: item.comparison_data[key] ?? "-",
        };
      } else {
        // Handle single-column fields
        dataObj[key] = item[key] ?? "-";
      }
    });
    mergedData.push(dataObj);
  });

  return mergedData;
};


// export const getComparisonData = (resultSet, page, type) => {
//   const mergedData = [];
//   var currentDimensionKeys = [];
//   // Iterate over the array of objects
//   resultSet.loadResponses.forEach((obj, index) => {
//     if (index === 0) {
//       obj.data.forEach((key, index1) => {
//         let dataObj = {};
//         let previousObjec = {};
//         Object.entries(key).forEach(([key1, value], innerIndex) => {
//           // if(key1?.split(".")?.[0] === "VariantAnalysisTablePPMPlaceholder" && ["parentSku","asin","category","broadCategory"].includes(key1?.split(".")?.pop())) {
//           //   dataObj[key1] = value;
//           // }
//           // else
//            if (innerIndex === 0) {
//             // previousObjec = getPreviousData(resultSet, key1, value, 0);
//             dataObj[key1] = value;
//             currentDimensionKeys.push(value)
//           } else if (
//             page === "product-child" &&
//             key1 === "VariantAnalysisTable.productName"
//           ) {
//             dataObj[key1] = value;
//           } else if (
//             page === "expendable" &&
//             innerIndex === 1 &&
//             type === "child"
//           ) {
//             previousObjec = getPreviousData(resultSet, key1, value, innerIndex);
//             dataObj[key1] = value;
//           } else {
//             if (Object.keys(previousObjec).length) {
//               if (key1 !== "compareDateRange") {
//                 dataObj[key1] = {
//                   current: {
//                     value: value,
//                     change: getPercentageChange(previousObjec[key1], value),
//                   },
//                   previous: previousObjec[key1] ?? 0,
//                 };
//               }
//               if (key1.includes("conversion_rate")) {
//                 dataObj[key1] = {
//                   current: {
//                     value: value?.toFixed(2),
//                     change: getPercentageChange(previousObjec[key1], value),
//                   },
//                   previous: previousObjec[key1].toFixed(2) ?? 0,
//                 };
//               }
//               if (key1.includes("ctr")) {
//                 dataObj[key1] = {
//                   current: {
//                     value: value?.toFixed(2) + "%",
//                     change: getPercentageChange(previousObjec[key1], value),
//                   },
//                   previous: previousObjec[key1].toFixed(2) + "%" ?? 0,
//                 };
//               }
//             } else {
//               if (key1 !== "compareDateRange") {
//                 dataObj[key1] = {
//                   current: {
//                     value: value,
//                     change: getPercentageChange(previousObjec[key1], value),
//                   },
//                   previous: previousObjec[key1] ?? 0,
//                 };
//               }
//               if (key1.includes("conversion_rate")) {
//                 dataObj[key1] = {
//                   current: { value: value?.toFixed(3), change: NaN },
//                   previous: 0,
//                 };
//               }
//             }
//           }
//         });
//         mergedData.push(dataObj);
//       });
//     }
//   });
//   return mergedData;
// };
const extractKeysFromColumns = (columns) => {
  if (!columns?.length) return { dimensionKeys: [], metricKeys: [] };

  // Extract all dimension keys (non-nested columns)
  const dimensionKeys = [
    ...new Set(
      columns
        .filter((col) => !col.children) // Filter out nested columns
        .map((col) => col.key) // Extract keys
    ),
  ];

  // Extract all metric keys from nested children (remove duplicates)
  const metricKeys = [
    ...new Set(
      columns
        .flatMap((col) => col.children || []) // Flatten nested children
        .map((child) => child.key) // Extract keys
    ),
  ];

  return { dimensionKeys, metricKeys };
};

export const getComparisonData = (resultSet) => {
  const columns = resultSet.tableColumns(resultSet.loadResponse.pivotQuery);
  if (!resultSet.loadResponses?.length) return [];
  // Extract dimension and metric keys from the columns list
  const { dimensionKeys, metricKeys } = extractKeysFromColumns(columns);
  if (!dimensionKeys.length || !metricKeys.length) return [];

  const mergedData = [];
  const currentData = resultSet.loadResponses[0]?.data || [];

  currentData.forEach((currentItem) => {
    const dataObj = {};

    // Add all dimension keys to the output object
    dimensionKeys.forEach((dimensionKey) => {
      dataObj[dimensionKey] = currentItem[dimensionKey];
    });

    // Create an object with dimension key-value pairs for matching
    const dimensionValues = dimensionKeys.reduce((acc, key) => {
      acc[key] = currentItem[key];
      return acc;
    }, {});

    // Find the previous data object based on all dimension keys
    const previousItem = getPreviousData(resultSet, dimensionKeys, dimensionValues);

    // Process metric keys
    metricKeys.forEach((metricKey) => {
      const currentValue = currentItem[metricKey] || 0;
      const previousValue = previousItem[metricKey] || 0;
      const percentageChange = getPercentageChange(previousValue, currentValue);

      // Format specific metrics (e.g., conversion_rate, ctr)
      if (metricKey.includes("conversion_rate")) {
        dataObj[metricKey] = {
          current: { value: currentValue?.toFixed(2), change: percentageChange },
          previous: previousValue?.toFixed(2) || "0.00",
        };
      } else if (metricKey.includes("ctr")) {
        dataObj[metricKey] = {
          current: { value: `${currentValue?.toFixed(2)}%`, change: percentageChange },
          previous: `${previousValue?.toFixed(2)}%` || "0.00%",
        };
      } else {
        dataObj[metricKey] = {
          current: { value: currentValue, change: percentageChange },
          previous: previousValue || 0,
        };
      }
    });

    mergedData.push(dataObj);
  });

  return mergedData;
};


export const getKeyWiseTotal = (key, totals, daysDifference,page) => {
  if (summaryMapping[key]) {
    const { numeratorKey, denominatorKey, multiplier, symbol, decimal, hideSummary } = summaryMapping[key];
    let numerator;
    if(denominatorKey === "dateDiff") {
       numerator = parseFloat(getDivideData(totals[numeratorKey], daysDifference));
    } else if(numeratorKey && denominatorKey ) {
       numerator = parseFloat(getDivideData(totals[numeratorKey], totals[denominatorKey]));
    }else if(hideSummary){
      numerator = "-";
    }else{
      numerator = parseFloat(totals[key]) ?? "-"
    }
    const result = isNaN(numerator) ? "-" : (numerator * (multiplier || 1));
    let resultValue = parseFloat(result);
    if(decimal === 0) {
      resultValue = parseFloat(resultValue).toFixed(0)
    } else {
      resultValue = parseFloat(resultValue).toFixed(2)
    }
    return isNaN(resultValue) ? "-" : resultValue + (symbol ? "%" : "")
} else {
  return isNaN(totals[key]) ? "-" : (page!=="marketing-stream" ?parseFloat(totals[key]).toFixed(0):numeral(totals[key]).format(formatDecimal));
}
}

export const getClassNameThead = (hiddenKeys,sortKey,mainIndex) => {
  if (mainIndex === 0) {
    if(sortKey === mainIndex){
      return "targeting-second-column targeting-table-heads selected";
    }else{
      return "targeting-second-column targeting-table-heads";
    }
  } else if (hiddenKeys.includes(mainIndex)) {
    return "d-none";
  } else if (sortKey === mainIndex) {
    return "targeting-table-heads selected";
  } else {
    return "targeting-table-heads";
  }
}

export const openPopup = (imageUrl) => {
  const popup = document.createElement('div');
  popup.classList.add('popupProductImage');
  
  const image = document.createElement('img');
  image.src = imageUrl;
  
  popup.appendChild(image);
  document.body.appendChild(popup);
  
  const closePopup = () => {
    document.body.removeChild(popup);
  };
  
  popup.addEventListener('click', closePopup);
};

export function getProductWithImage (td,hideImages) {
  if(!td) {
    return <></>
  }
  if(!hideImages) {
    return <><div className="d-flex gap-2 align-items-center">
              {td?.split('||')?.[0].trim() !== '' && td?.split('||')?.[0].trim() != '0' && 
              <div onClick={(e)=> openPopup(td?.split('||')?.[0].trim())} >
                <img src={td?.split('||')?.[0].trim()} alt="Product" className="productIcon img-responsive" />
              </div>
              }
              <div>{td?.split('||')?.[1]?.trim()}</div>
              </div>
          </>
  } else {
    return <><div className="d-flex gap-2 align-items-center">
                <div>{td?.split('||')?.[1]?.trim()}</div>
              </div>
          </>
  }
  
}
export function getProductWithImageForLogs (td,asin) {
  if(!td) {
    return <></>
  }
  return <><div className="d-flex gap-2 align-items-center flex-column">
              {td?.split('||')?.[0].trim() !== '' && td?.split('||')?.[0].trim() != '0' && 
              <div onClick={(e)=> openPopup(td?.split('||')?.[0].trim())} >
                <img src={td?.split('||')?.[0].trim()} alt="Product" className="productIcon img-responsive" />
              </div>
              }
               <div>{asin}</div>
              <div>{td?.split('||')?.[1]?.trim()}</div>
              </div>
          </>
  
}

export const getPercentageChangeDiv = (value, type, images) => {
  if (isNaN(value) && !images) {
    return "";
  } else if (!isFinite(value) && !images) {
    return "";
  } else if(images && (!isFinite(value) || isNaN(value))) {
    return <td></td>
  }else if( value === 2147483647 || value === -2147483648) {
    return "∞"
  }
  let chartType = keyExtractor(type);
  var different = ["cpm", "cpc", "acos"];
  let color = "";
  let img = "";
  if (value >= 0) {
    color = different.includes(chartType) ? "#B91C1C" : "#047857";
    img = different.includes(chartType) ? movingUpRed : movingUp;
  } else {
    color = different.includes(chartType) ? "#047857" : "#B91C1C";
    img = different.includes(chartType) ? movingDownGreen : movingDown;
  }
  if (images) {
    return (
      <td>
        <span
          className="percentage-up-down-container reports"
          style={{ display: "inline" }}
        >
          <span className="percentage-desc" style={{ color: color }}>
            {numeral(Math.abs(value)).format(format)}%
          </span>
          {
            <span className="percentage-desc" style={{ color: color }}>
              {value > 0 ? "↑" : "↓"}
            </span>
          }
        </span>
      </td>
    );
  } else {
    return (
      <>
        <div className="percentage-up-down-container reports">
          <span className="percentage-desc" style={{ color: color }}>
          {numeral(Math.abs(value)).format(format)}%
          </span>
          <span className="percentage-icon">
            <img src={img} alt="movingdown" />
          </span>
        </div>
      </>
    );
  }
};

export const checkObject = (td, type) => {
  if (type === "previous") {
    return typeof td === "object" && td !== null
      ? td?.previous
      : td === null
      ? ""
      : td?.previous;
  } else if (type === "change") {
    return typeof td === "object" && td !== null
      ? td.current.change
      : td === null
      ? ""
      : 0;
  } else {
    return typeof td === "object" && td !== null
      ? td.current.value
      : td === null
      ? null
      : td;
  }
};