const updatePlaceholders = (fields) => {
  return fields.map((field) => {
    let placeholderText = "Enter a number (e.g., 25 for 25%)";
    return { ...field, placeholder: placeholderText };
  });
};

const commonOperators = {
  string: [
    {
      id: "equals",
      name: "Equals",
      multiple: false,
      dropdown: true,
      input: true,
    },
    {
      id: "notEquals",
      name: "Not Equals",
      multiple: false,
      dropdown: true,
      input: true,
    },
    { id: "equals", name: "IN", multiple: true, dropdown: true, input: true },
    {
      id: "notEquals",
      name: "Not IN",
      multiple: true,
      dropdown: true,
      input: true,
    },
    {
      id: "contains",
      name: "Contains",
      multiple: false,
      dropdown: false,
      input: true,
    },
    {
      id: "notContains",
      name: "Not Contains",
      multiple: false,
      dropdown: false,
      input: true,
    },
    {
      id: "startsWith",
      name: "Starts With",
      multiple: false,
      dropdown: false,
      input: true,
    },
    {
      id: "notStartsWith",
      name: "Not Starts With",
      multiple: false,
      dropdown: false,
      input: true,
    },
    {
      id: "notNull",
      name: "Exist",
      multiple: false,
      dropdown: false,
      input: false,
    },
    {
      id: "isNull",
      name: "Not Exist",
      multiple: false,
      dropdown: false,
      input: false,
    },
  ],
  int: [
    {
      id: "equals",
      name: "Equals",
      multiple: false,
      dropdown: false,
      input: true,
    },
    {
      id: "notEquals",
      name: "Not Equals",
      multiple: false,
      dropdown: false,
      input: true,
    },
    // { id: "equals", name: "IN",multiple:true },
    // { id: "notEquals", name: "Not In",multiple:true },
    {
      id: "gt",
      name: "Greater Than",
      multiple: false,
      dropdown: false,
      input: true,
    },
    {
      id: "gte",
      name: "Greater Than or Equal",
      multiple: false,
      dropdown: false,
      input: true,
    },
    {
      id: "lt",
      name: "Less Than",
      multiple: false,
      dropdown: false,
      input: true,
    },
    {
      id: "lte",
      name: "Less Than or Equal",
      multiple: false,
      dropdown: false,
      input: true,
    },
    {
      id: "notNull",
      name: "Exist",
      multiple: false,
      dropdown: false,
      input: false,
    },
    {
      id: "isNull",
      name: "Not Exist",
      multiple: false,
      dropdown: false,
      input: false,
    },
  ],
  date: [
    { id: "equals", name: "Equals", multiple: false },
    { id: "notEquals", name: "Not Equals", multiple: false },
    { id: "afterDate", name: "After Date", multiple: false },
    { id: "afterOrOnDate", name: "After or On Date", multiple: false },
    { id: "beforeDate", name: "Before Date", multiple: false },
    { id: "beforeOrOnDate", name: "Before or On Date", multiple: false },
  ],
  customOnlyContins: [
    {
      id: "contains",
      name: "Contains",
      multiple: false,
      dropdown: false,
      input: true,
    },
    {
      id: "notContains",
      name: "Not Contains",
      multiple: false,
      dropdown: false,
      input: true,
    },
  ],
  customOnlyContinsWithSelect: [
    {
      id: "contains",
      name: "Equals",
      multiple: false,
      dropdown: true,
      input: true,
    },
    {
      id: "notContains",
      name: "Not Equals",
      multiple: false,
      dropdown: true,
      input: true,
    },
  ],
};

export const FiltersMeasuresDataQuadrant = {
  amazon: {
    operators: commonOperators,
    fields: [
      {
        name: "Spends",
        id: "VariantAnalysisTablePPMPlaceholder.cost",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "cost_key",
      },
      {
        name: "Spends (DRR)",
        id: "VariantAnalysisTablePPMPlaceholder.costDRR",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "cost_ddr_key",
      },
      {
        name: "Impressions",
        id: "VariantAnalysisTablePPMPlaceholder.impressions",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "impressions_key",
      },
      {
        name: "Impressions (DRR)",
        id: "VariantAnalysisTablePPMPlaceholder.impressionsDRR",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "impressions_ddr_key",
      },
      {
        name: "Clicks",
        id: "VariantAnalysisTablePPMPlaceholder.clicks",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "clicks_key",
      },
      {
        name: "Clicks (DRR)",
        id: "VariantAnalysisTablePPMPlaceholder.clicksDRR",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "clicks_ddr_key",
      },
      {
        name: "CTR",
        id: "VariantAnalysisTablePPMPlaceholder.ctr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "ctr_key",
      },
      {
        name: "CTR (DRR)",
        id: "VariantAnalysisTablePPMPlaceholder.ctrDRR",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "ctr_ddr_key",
      },
      {
        name: "CPC",
        id: "VariantAnalysisTablePPMPlaceholder.cpc",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "cpc_key",
      },
      {
        name: "CPC (DRR)",
        id: "VariantAnalysisTablePPMPlaceholder.cpcDRR",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "cpc_ddr_key",
      },
      {
        name: "CPM",
        id: "VariantAnalysisTablePPMPlaceholder.cpm",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "cpm_key",
      },
      {
        name: "CPM (DRR)",
        id: "VariantAnalysisTablePPMPlaceholder.cpmDRR",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "cpm_ddr_key",
      },
      {
        name: "Conversions",
        id: "VariantAnalysisTablePPMPlaceholder.conversions14d",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "conversions14d_key",
      },
      {
        name: "Conversions (DRR)",
        id: "VariantAnalysisTablePPMPlaceholder.conversions14dDRR",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "conversions14d_ddr_key",
      },
      {
        name: "Conversion Rate",
        id: "VariantAnalysisTablePPMPlaceholder.conversion_rate",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "conversion_rate_key",
      },
      {
        name: "Conversion Rate (DRR)",
        id: "VariantAnalysisTablePPMPlaceholder.conversion_rateDRR",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "conversion_rate_ddr_key",
      },
      {
        name: "Revenue",
        id: "VariantAnalysisTablePPMPlaceholder.sales14d",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "sales14d_key",
      },
      {
        name: "Revenue (DRR)",
        id: "VariantAnalysisTablePPMPlaceholder.sales14dDRR",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "sales14d_ddr_key",
      },
      {
        name: "Average Order Value",
        id: "VariantAnalysisTablePPMPlaceholder.average_order_value",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "average_order_value_key",
      },
      {
        name: "Average Order Value (DRR)",
        id: "VariantAnalysisTablePPMPlaceholder.average_order_valueDRR",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "average_order_value_ddr_key",
      },
      {
        name: "ACOS",
        id: "VariantAnalysisTablePPMPlaceholder.acos",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "acos_key",
      },
      {
        name: "ACOS (DRR)",
        id: "VariantAnalysisTablePPMPlaceholder.acosDRR",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "acos_ddr_key",
      },
      {
        name: "ROAS",
        id: "VariantAnalysisTablePPMPlaceholder.roas",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "roas_key",
      },
      {
        name: "ROAS (DRR)",
        id: "VariantAnalysisTablePPMPlaceholder.roasDRR",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "roas_ddr_key",
      },
      {
        name: "Maximum Retail Price",
        id: "VariantAnalysisTablePPMPlaceholder.MaximumRetailPrice",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "maximum_retail_price_key",
      },
      {
        name: "BSR",
        id: "VariantAnalysisTablePPMPlaceholder.BSR",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "bsr_key",
      },
      {
        name: "Selling Price",
        id: "VariantAnalysisTablePPMPlaceholder.SalePrice",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "salePrice_key",
      },
      {
        name: "Available Inventory",
        id: "VariantAnalysisTablePPMPlaceholder.Quantity",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "Quantity_key",
      },
      {
        name: "Total ASP",
        id: "VariantAnalysisTablePPMPlaceholder.total_asp",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "total_asp_key",
      },
      {
        name: "Total ROAS",
        id: "VariantAnalysisTablePPMPlaceholder.troas",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "troas_key",
      },
      {
        name: "Total ACOS",
        id: "VariantAnalysisTablePPMPlaceholder.tacos",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "tacos_key",
      },
      {
        name: "Total Revenue",
        id: "VariantAnalysisTablePPMPlaceholder.trevnue",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "trevnue_key",
      },
      {
        name: "Total Units Sold",
        id: "VariantAnalysisTablePPMPlaceholder.tunits_sold",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "tunits_sold_key",
      },
    ],
  },
  myntra: {
    operators: commonOperators,
    fields: [
      {
        name: "Views",
        id: "views",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "views_key",
      },
      {
        name: "Views DRR",
        id: "views_drr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "views_drr_key",
      },
      {
        name: "Clicks",
        id: "clicks",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "clicks_key",
      },
      {
        name: "Clicks DRR",
        id: "clicks_drr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "clicks_drr_key",
      },
      {
        name: "Spends",
        id: "spends",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "spends_key",
      },
      {
        name: "Spends DRR",
        id: "spends_drr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "spends_drr_key",
      },
      {
        name: "CTR",
        id: "ctr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "ctr_key",
      },
      {
        name: "CTR DRR",
        id: "ctr_drr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "ctr_drr_key",
      },
      {
        name: "Conv. Rate",
        id: "cvr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "cvr_key",
      },
      {
        name: "Conv. Rate DRR",
        id: "cvr_drr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "cvr_drr_key",
      },
      {
        name: "Units Sold (Direct)",
        id: "units_sold_direct",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "units_sold_direct_key",
      },
      {
        name: "Units Sold (Indirect)",
        id: "units_sold_indirect",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "units_sold_indirect_key",
      },
      {
        name: "Revenue (Direct)",
        id: "revenue_direct",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "revenue_direct_key",
      },
      {
        name: "Revenue (Indirect)",
        id: "revenue_indirect",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "revenue_indirect_key",
      },
      {
        name: "Revenue (Direct) Percentage",
        id: "direct_revenue_percentage",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "direct_revenue_percentage_key",
      },
      {
        name: "Revenue (Indirect) Percentage",
        id: "indirect_revenue_percentage",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "indirect_revenue_percentage_key",
      },
      {
        name: "Average Selling Price (ASP)",
        id: "asp",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "asp_key",
      },
      
      {
        name: "ROAS",
        id: "roas",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "roas_key",
      },
      {
        name: "ROAS Direct",
        id: "direct_roas",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "direct_roas_key",
      },
      {
        name: "CPC",
        id: "cpc",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "cpc_key",
      },
      {
        name: "Units Sold (Direct) DRR",
        id: "units_sold_direct_drr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "units_sold_direct_drr_key",
      },
      {
        name: "Units Sold (Indirect) DRR",
        id: "units_sold_indirect_drr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "units_sold_indirect_drr_key",
      },
      {
        name: "Revenue (Direct) DRR",
        id: "revenue_direct_drr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "revenue_direct_drr_key",
      },
      {
        name: "Revenue (Indirect) DRR",
        id: "revenue_indirect_drr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "revenue_indirect_drr_key",
      },
      {
        name: "ASP DRR",
        id: "asp_drr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "asp_drr_key",
      },
      {
        name: "ROAS DRR",
        id: "roas_drr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "roas_drr_key",
      },
      {
        name: "CPC DRR",
        id: "cpc_drr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "cpc_drr_key",
      },
      {
        name: "Maximum Retail Price",
        id: "mrp",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "mrp_key",
      },
      {
        name: "Average Order Value",
        id: "aov",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "aov_key",
      },
      {
        name: "Orders",
        id: "orders",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "orders_key",
      },
    ],
  },
  flipkart: {
    operators: commonOperators,
    fields: [
      {
        name: "Clicks",
        id: "clicks",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "clicks_key",
      },
      {
        name: "Spends",
        id: "spends",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "spends_key",
      },
      {
        name: "CTR",
        id: "ctr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "ctr_key",
      },
      {
        name: "Conv. Rate",
        id: "cvr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "cvr_key",
      },
      {
        name: "Revenue",
        id: "revenue",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "revenue_key",
      },
      {
        name: "Units Sold (Direct)",
        id: "units_sold_direct",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "units_sold_direct_key",
      },
      {
        name: "Units Sold (Indirect)",
        id: "units_sold_indirect",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "units_sold_indirect_key",
      },
      {
        name: "Revenue (Direct)",
        id: "revenue_direct",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "revenue_direct_key",
      },
      {
        name: "Revenue (Indirect)",
        id: "revenue_indirect",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "revenue_indirect_key",
      },
      {
        name: "Revenue (Direct) Percentage",
        id: "direct_revenue_percentage",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "direct_revenue_percentage_key",
      },
      {
        name: "Revenue (Indirect) Percentage",
        id: "indirect_revenue_percentage",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "indirect_revenue_percentage_key",
      },
      {
        name: "ROAS",
        id: "roas",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "roas_key",
      },
      {
        name: "ROAS Direct",
        id: "direct_roas",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "direct_roas_key",
      },
      {
        name: "CPC",
        id: "cpc",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "cpc_key",
      },
      {
        name: "Impressions",
        id: "impressions",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "impressions_key",
      },
      {
        name: "Orders",
        id: "orders",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "orders_key",
      },
      {
        name: "CPM",
        id: "cpm",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "cpm_key",
      },
      {
        name: "Average Order Value",
        id: "aov",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "aov_key",
      },
      {
        name: "ACOS",
        id: "acos",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "acos_key",
      },
      {
        name: "Clicks DRR",
        id: "clicks_drr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "clicks_drr_key",
      },
      {
        name: "Spends DRR",
        id: "spends_drr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "spends_drr_key",
      },
      {
        name: "CTR DRR",
        id: "ctr_drr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "ctr_drr_key",
      },
      {
        name: "Conv. Rate DRR",
        id: "cvr_drr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "cvr_drr_key",
      },
      {
        name: "Units Sold DRR",
        id: "units_sold_drr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "units_sold_drr_key",
      },
      {
        name: "Units Sold (Direct) DRR",
        id: "units_sold_direct_drr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "units_sold_direct_drr_key",
      },
      {
        name: "Units Sold (Indirect) DRR",
        id: "units_sold_indirect_drr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "units_sold_indirect_drr_key",
      },
      {
        name: "Revenue (Direct) DRR",
        id: "revenue_direct_drr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "revenue_direct_drr_key",
      },
      {
        name: "Revenue (Indirect) DRR",
        id: "revenue_indirect_drr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "revenue_indirect_drr_key",
      },
      {
        name: "ROAS DRR",
        id: "roas_drr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "roas_drr_key",
      },
      {
        name: "CPC DRR",
        id: "cpc_drr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "cpc_drr_key",
      },
      {
        name: "Revenue DRR",
        id: "revenue_drr",
        datatype: "int",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "revenue_drr_key",
      },
    ],
  },
};

export const FiltersDimensionDataQuadrant = {
  amazon: {
    operators: commonOperators,
    fields: [
      {
        name: "Product Name",
        id: "VariantAnalysisTablePPMPlaceholder.productName",
        datatype: "string",
        type: "default",
        placeholder: "enter product name",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "product_name_key",
      },
      {
        name: "ASIN",
        id: "VariantAnalysisTablePPMPlaceholder.asin",
        datatype: "string",
        type: "default",
        placeholder: "enter ASIN",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "asin_key",
      },
      {
        name: "Color",
        id: "VariantAnalysisTablePPMPlaceholder.color",
        datatype: "string",
        type: "default",
        placeholder: "enter color",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "color_key",
      },
      {
        name: "Gender",
        id: "VariantAnalysisTablePPMPlaceholder.gender",
        datatype: "string",
        type: "default",
        placeholder: "enter gender",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "gender_key",
      },
      {
        name: "Category",
        id: "VariantAnalysisTablePPMPlaceholder.category",
        datatype: "string",
        type: "default",
        placeholder: "enter category",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "category_key",
      },
    ],
  },
  myntra: {
    operators: commonOperators,
    fields: [
      {
        name: "Product Name",
        id: "product_name",
        datatype: "string",
        type: "default",
        placeholder: "enter product name",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "product_name_key",
      },
      {
        name: "Product ID",
        id: "product_id",
        datatype: "string",
        type: "default",
        placeholder: "enter Product ID",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "product_id_key",
      },
      {
        name: "Category",
        id: "category",
        datatype: "string",
        type: "default",
        placeholder: "enter category",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "category_key",
      },
      {
        name: "Brand",
        id: "brand",
        datatype: "string",
        type: "default",
        placeholder: "enter brand",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "brand_key",
      },
      {
        name: "Size",
        id: "size",
        datatype: "string",
        type: "default",
        placeholder: "enter size",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "size_key",
      },
      {
        name: "Seller SKU Code",
        id: "seller_sku_code",
        datatype: "string",
        type: "default",
        placeholder: "enter seller sku code",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "seller_sku_code_key",
      },
      {
        name: "Base Colour",
        id: "base_colour",
        datatype: "string",
        type: "default",
        placeholder: "enter base colour",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "base_colour_key",
      },
      {
        name: "Gender",
        id: "gender",
        datatype: "string",
        type: "default",
        placeholder: "enter gender",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "gender_key",
      },

      {
        name: "Master Category",
        id: "master_category",
        datatype: "string",
        type: "default",
        placeholder: "enter master category",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "master_category_key",
      },
    ],
  },
  flipkart: {
    operators: commonOperators,
    fields: [
      {
        name: "Product Name",
        id: "product_name",
        datatype: "string",
        type: "default",
        placeholder: "enter product name",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "product_name_key",
      },
      {
        name: "Product ID",
        id: "product_id",
        datatype: "string",
        type: "default",
        placeholder: "enter Product ID",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "product_id_key",
      },
      {
        name: "Brand Name",
        id: "brand",
        datatype: "string",
        type: "default",
        placeholder: "enter brand",
        dynamicOptions: false,
        autoComplete: true,
        source: "Adyogi",
        apiKey: "brand_key",
      },
    ],
  },
};

export const FiltersCampaignDataQuadrant = {
  amazon: {
    operators: commonOperators,
    fields: [
      {
        name: "Category Name",
        id: "VariantAnalysisTablePPMPlaceholder.category",
        datatype: "string",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: true,
        optionsKey: "category_names_api_key",
        source: "BackendAll",
        optionsType: "Static",
        dataFromApi: true,
        apiKey: "category_names_api_key",
      },
      {
        name: "Campaign Name",
        id: "VariantAnalysisTablePPMPlaceholder.campaignName",
        datatype: "string",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: true,
        optionsKey: "campaign_names_api_key",
        source: "BackendAll",
        optionsType: "Static",
        dataFromApi: true,
        apiKey: "campaign_names_api_key",
      },
      {
        name: "Portfolio Name",
        id: "VariantAnalysisTablePPMPlaceholder.portfolioName",
        datatype: "string",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: true,
        optionsKey: "portfolio_names_api_key",
        source: "BackendAll",
        optionsType: "Static",
        dataFromApi: true,
        apiKey: "portfolio_names_api_key",
      },
      {
        name: "Product Set",
        id: "VariantAnalysisTablePPMPlaceholder.productSet",
        datatype: "string",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: true,
        optionsKey: "product_set_api_key",
        source: "BackendAll",
        optionsType: "Static",
        dataFromApi: true,
        apiKey: "product_set_api_key",
      },
      {
        name: "Campaign Type",
        id: "VariantAnalysisTablePPMPlaceholder.campaignType",
        datatype: "string",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: true,
        optionsKey: "Retarget,Prospect",
        optionsType: "Static",
        source: "Adyogi",
        apiKey: "campaignType_id_key",
      },
      {
        name: "Campaign Format",
        id: "VariantAnalysisTablePPMPlaceholder.campaignFormat",
        datatype: "string",
        type: "default",
        placeholder: "Select a format",
        dynamicOptions: true,
        optionsKey: "SD,SP,SB(V),SB",
        optionsType: "Static",
        source: "Adyogi",
        apiKey: "campaign_format_key",
      },
      {
        name: "Targeting Type",
        id: "VariantAnalysisTablePPMPlaceholder.targetingType",
        datatype: "string",
        type: "default",
        placeholder: "Select targeting type",
        dynamicOptions: true,
        optionsKey:
          "G.Keyword,C.Keyword,NB.Keyword,Audience,Automatic,NB.Product,B.Keyword,B.Audience,B.Product",
        optionsType: "Static",
        source: "Adyogi",
        apiKey: "targeting_type_key",
      },
      {
        name: "Ad Status",
        id: "VariantAnalysisTablePPMPlaceholder.adStatus",
        datatype: "string",
        type: "default",
        placeholder: "Select ad status",
        dynamicOptions: true,
        optionsKey: "enabled,paused",
        optionsType: "Static",
        source: "Adyogi",
        apiKey: "ad_status_key",
      },
    ],
  },
  myntra: {
    operators: commonOperators,
    fields: [
      {
        name: "Campaign Name",
        id: "campaign_name",
        datatype: "string",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: true,
        source: "Adyogi",
        optionsType: "Static",
        dataFromApi: true,
        apiKey: "campaign_names_key",
      },
      {
        name: "Campaign ID",
        id: "campaign_id",
        datatype: "string",
        type: "default",
        placeholder: "enter campaign id",
        dynamicOptions: true,
        source: "Adyogi",
        optionsType: "Static",
        dataFromApi: true,
        apiKey: "campaign_id_key",
      },
      {
        name: "Campaign Status",
        id: "campaign_status",
        datatype: "string",
        type: "default",
        placeholder: "enter campaign status",
        dynamicOptions: true,
        source: "Adyogi",
        optionsType: "Static",
        dataFromApi: true,
        apiKey: "campaign_status_key",
      },
      {
        name: "Ad Group Name",
        id: "ad_group_name",
        datatype: "string",
        type: "default",
        placeholder: "Enter ad group name",
        dynamicOptions: true,
        optionsType: "Static",
        source: "Adyogi",
        apiKey: "ad_group_name_key",
      },
      {
        name: "Ad Group ID",
        id: "ad_group_id",
        datatype: "string",
        type: "default",
        placeholder: "enter ad group ID",
        dynamicOptions: true,
        optionsType: "Static",
        source: "Adyogi",
        apiKey: "ad_group_id_key",
      },
      {
        name: "Ad Group Status",
        id: "ad_group_status",
        datatype: "string",
        type: "default",
        placeholder: "enter ad group Status",
        dynamicOptions: true,
        optionsType: "Static",
        source: "Adyogi",
        apiKey: "ad_group_status_key",
      },
    ],
  },
  flipkart: {
    operators: commonOperators,
    fields: [
      {
        name: "Campaign Name",
        id: "campaign_name",
        datatype: "string",
        type: "default",
        placeholder: "enter any value",
        dynamicOptions: true,
        source: "Adyogi",
        optionsType: "Static",
        dataFromApi: true,
        apiKey: "campaign_names_key",
      },
      {
        name: "Campaign ID",
        id: "campaign_id",
        datatype: "string",
        type: "default",
        placeholder: "enter campaign id",
        dynamicOptions: true,
        source: "Adyogi",
        optionsType: "Static",
        dataFromApi: true,
        apiKey: "campaign_id_key",
      },
      {
        name: "Campaign Type",
        id: "campaign_type",
        datatype: "string",
        type: "default",
        placeholder: "enter campaign type",
        dynamicOptions: true,
        source: "Adyogi",
        optionsType: "Static",
        dataFromApi: true,
        apiKey: "campaign_type_key",
      },
      {
        name: "Ad Group Name",
        id: "ad_group_name",
        datatype: "string",
        type: "default",
        placeholder: "Enter ad group name",
        dynamicOptions: true,
        optionsType: "Static",
        source: "Adyogi",
        apiKey: "ad_group_name_key",
      },
      {
        name: "Ad Group ID",
        id: "ad_group_id",
        datatype: "string",
        type: "default",
        placeholder: "enter ad group ID",
        dynamicOptions: true,
        optionsType: "Static",
        source: "Adyogi",
        apiKey: "ad_group_id_key",
      },
    ],
  },
};


export const useTopXDataConfig = () => {
  const userPlatform = JSON.parse(localStorage.getItem("user_platform"));
  
  return {
    operators: {int: [
      { id: "TOP", name: "Top",multiple:false },
      { id: "BOTTOM", name: "Bottom",multiple:false }
    ]},
    fields: updatePlaceholders(FiltersMeasuresDataQuadrant[userPlatform].fields)
  }
}

export const PlatformWiseQueryMeasures = {
  amazon: [
    "VariantAnalysisTablePPMPlaceholder.cost",
    "VariantAnalysisTablePPMPlaceholder.costDRR",
    "VariantAnalysisTablePPMPlaceholder.SalePrice",
    "VariantAnalysisTablePPMPlaceholder.Quantity",
    "VariantAnalysisTablePPMPlaceholder.impressions",
    "VariantAnalysisTablePPMPlaceholder.impressionsDRR",
    "VariantAnalysisTablePPMPlaceholder.clicks",
    "VariantAnalysisTablePPMPlaceholder.clicksDRR",
    "VariantAnalysisTablePPMPlaceholder.ctr",
    "VariantAnalysisTablePPMPlaceholder.ctrDRR",
    "VariantAnalysisTablePPMPlaceholder.cpc",
    "VariantAnalysisTablePPMPlaceholder.cpcDRR",
    "VariantAnalysisTablePPMPlaceholder.cpm",
    "VariantAnalysisTablePPMPlaceholder.cpmDRR",
    "VariantAnalysisTablePPMPlaceholder.conversions14d",
    "VariantAnalysisTablePPMPlaceholder.conversions14dDRR",
    "VariantAnalysisTablePPMPlaceholder.conversion_rate",
    "VariantAnalysisTablePPMPlaceholder.conversion_rateDRR",
    "VariantAnalysisTablePPMPlaceholder.sales14d",
    "VariantAnalysisTablePPMPlaceholder.sales14dDRR",
    "VariantAnalysisTablePPMPlaceholder.average_order_value",
    "VariantAnalysisTablePPMPlaceholder.average_order_valueDRR",
    "VariantAnalysisTablePPMPlaceholder.acos",
    "VariantAnalysisTablePPMPlaceholder.acosDRR",
    "VariantAnalysisTablePPMPlaceholder.roas",
    "VariantAnalysisTablePPMPlaceholder.roasDRR",
    "VariantAnalysisTablePPMPlaceholder.MaximumRetailPrice",
    "VariantAnalysisTablePPMPlaceholder.BSR",
    "VariantAnalysisTablePPMPlaceholder.total_asp",
    "VariantAnalysisTablePPMPlaceholder.troas",
    "VariantAnalysisTablePPMPlaceholder.tacos",
    "VariantAnalysisTablePPMPlaceholder.trevnue",
    "VariantAnalysisTablePPMPlaceholder.tunits_sold",
  ],
  myntra: [
    "views",
    "views_drr",
    "clicks",
    "clicks_drr",
    "spends",
    "spends_drr",
    "ctr",
    "ctr_drr",
    "cvr",
    "cvr_drr",
    "revenue",
    "units_sold_direct",
    "units_sold_indirect",
    "revenue_direct",
    "revenue_indirect",
    "asp",
    "roas",
    "cpc",
    "units_sold_direct_drr",
    "units_sold_indirect_drr",
    "revenue_direct_drr",
    "revenue_indirect_drr",
    "asp_drr",
    "roas_drr",
    "cpc_drr",
    "impressions",
    "orders",
    "cpm",
    "aov",
    "acos",
    "mrp",
    "direct_roas",
    "direct_revenue_percentage",
    "indirect_revenue_percentage",
  ],
  flipkart: [
    "clicks",
    "spends",
    "ctr",
    "cvr",
    "revenue",
    "units_sold_direct",
    "units_sold_indirect",
    "revenue_direct",
    "revenue_indirect",
    "roas",
    "cpc",
    "impressions",
    "orders",
    "cpm",
    "aov",
    "acos",
    "clicks_drr",
    "spends_drr",
    "ctr_drr",
    "cvr_drr",
    "units_sold_drr",
    "units_sold_direct_drr",
    "units_sold_indirect_drr",
    "revenue_direct_drr",
    "revenue_indirect_drr",
    "roas_drr",
    "cpc_drr",
    "revenue_drr",
    "direct_roas",
    "direct_revenue_percentage",
    "indirect_revenue_percentage",
  ],
};

export const PlatformWiseQueryDefaultDimension = {
  amazon: [
    // "VariantAnalysisTablePPMPlaceholder.profileId",
    "VariantAnalysisTablePPMPlaceholder.asin",
    "VariantAnalysisTablePPMPlaceholder.productNameWithImage",
  ],
  myntra: ["product_id", "product_name"],
  flipkart: ["product_id", "product_name"],
};

export const PlatformWiseQueryOrder = {
  amazon: [["VariantAnalysisTablePPMPlaceholder.cost", "desc"]],
  myntra: [["spends", "desc"]],
  flipkart: [["spends", "desc"]],
};

export const PlatformWiseKeyMapper = {
  amazon: {
    date: "VariantAnalysisTablePPMPlaceholder.obDate",
    tableName: "VariantAnalysisTablePPMPlaceholder.",
    defaultCampaignBackend: "amazon_campaigns_filter",
  },
  myntra: {
    date: "date",
    tableName: "",
    defaultCampaignBackend: "myntra_campaigns_filter",
  },
  flipkart: {
    date: "date",
    tableName: "",
    defaultCampaignBackend: "flipkart_campaigns_filter",
  },
};
