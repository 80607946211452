import { masterApi } from "../../apis/masterApi";
import { useGlobalContext } from "../../context/global";
import sendUserInfoToClarity from "../analytics/clarity";
import sendUserInfoToGainsight from "../analytics/gainsight";
import useUserDetails from "../userData/useUserDetails";
import axios from "axios";
import { currencySymbolPicker } from "../../constant/defaultCurrencyConfigurations";
import { getClientAccountDetails, getClientDataFromAdyogiAPI } from "./utils";
import React from "react";


const fetchData = async (clientId) => {
  try {
    const response = await getClientDataFromAdyogiAPI(clientId);
    return response
    
  } catch (error) {
    throw error;
  }
}
export default async function getClientData(passedClientId, wantReports,email="") {
  /**
   * Function to Get the current ClientId Data including reports
   * @Note Just the pass the clientId for which you want the data and if you want reports just pass true or else pass false
   */
  // This function will return client data and reports of client
  const data = await masterApi({}, "clientData", false, "Get", passedClientId);
  const back4appData = await fetchData(passedClientId);
  localStorage.setItem('back4appData',JSON.stringify(back4appData?.data?.results[0]))
  const websiteForGainsight = back4appData?.data?.results[0]?.website
  let client_id = "";
  let account_id = "";
  let default_bid = "";
  let brand_id = "";
  let name = "";
  let brand_keywords = "";
  let account_type = "";
  let vendor_catalog_csv_url = "";
  let id = "";
  let currency_code = "";
  let setup_key = "";
  let  mainData = {};  
  if(data?.data?.data.length > 0) {
    const obj = data?.data?.data[0];
    // const { client_id, account_id, default_bid, brand_id, name , brand_keywords,account_type,vendor_catalog_csv_url,id,currency_code } = obj
    // data?.data?.data[0];
    client_id = obj?.client_id;
    account_id = obj?.account_id;
    default_bid = obj?.default_bid;
    brand_id = obj?.brand_id;
    name = obj?.name;
    brand_keywords = obj?.brand_keywords;
    account_type = obj?.account_type;
    vendor_catalog_csv_url = obj?.vendor_catalog_csv_url;
    id = obj?.id;
    currency_code = obj?.currency_code
    setup_key = 'amazon_setup'; // This is for amazon configs.
    mainData = {clientData:{...data?.data?.data[0],isAmazonSetup:true}};
  }

  if(data?.data?.data?.length === 0 && back4appData?.data?.results?.length > 0) {
    const obj = back4appData?.data?.results[0];
    const {accountName,setupKey,profileId,accountType} = getClientAccountDetails(obj);
    setup_key = setupKey; // This is for amazon configs.
    client_id = obj?.objectId;
    account_id = profileId;
    default_bid = obj?.defaultBid ?? 1;
    brand_id = obj?.brandId;
    name = accountName;
    brand_keywords = obj?.brandKeywords;
    account_type = accountType ?? setupKey;
    vendor_catalog_csv_url = obj?.vendorCatalogCsvUrl;
    id = obj?.objectId;
    currency_code = obj?.currencyCode
    if(obj.amazon_setup) {
      delete obj['amazon_setup'];
    }
    mainData = {clientData:{...back4appData?.data?.results[0],isAmazonSetup:false}};
  }

  if(data?.data?.data?.length && back4appData?.data?.results?.length) {
    const obj1Data = data?.data?.data[0] ?? {}
    const obj2Data = back4appData?.data?.results[0] ?? {}
    if(obj1Data?.client_id === obj2Data?.objectId) { 
      mainData = {clientData:{...obj1Data,...obj2Data,isAmazonSetup:true}};
    }
  }

  localStorage.setItem("clientId", JSON.stringify(client_id));
  localStorage.setItem("clientData", JSON.stringify(mainData?.clientData ?? {}));
  localStorage.setItem("accountIdNumeric",JSON.stringify(id));
  localStorage.setItem("client_account_type",JSON.stringify(account_type))
  localStorage.setItem("setup_key", JSON.stringify(setup_key));


  //Sending data to gainsight
  if(email) {
    sendUserInfoToGainsight(
      { email: email },
      { clientId: client_id, ...(websiteForGainsight && {website:websiteForGainsight}) },
    );
    //sending Data to clarity
    sendUserInfoToClarity({ email: email }, { clientId: client_id },{name : name});
  }
  const currency_symbol = currencySymbolPicker(currency_code);
  localStorage.setItem("currency_symbol",JSON.stringify(currency_symbol))
  
  localStorage.setItem("defaultBid", default_bid);
  localStorage.setItem("brandId", brand_id);
  localStorage.setItem("clientId", JSON.stringify(client_id));
  localStorage.setItem("accountId", JSON.stringify(account_id));
  localStorage.setItem("clientName", JSON.stringify(name));
  localStorage.setItem("brandKeywords",JSON.stringify(brand_keywords))
  localStorage.setItem("csvUrl",JSON.stringify(vendor_catalog_csv_url))
  
 

  // Another API Call For Reports.
  if (wantReports === true) {
    axios
      .get(
        `https://app1.adyogi.com/api/amzn/getCustomReports?accountId=${account_id}`
      )
      .then((response) => {
        // setReportData(response.data);
        localStorage.setItem("reportData", JSON.stringify(response.data));
        const data  = response.data
        mainData["reportData"] = data;
      });

    // setClientAccountId(account_id);
  }
  return mainData;
}
