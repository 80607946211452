import { keyExtractor } from "../widget";

export const getCubeQueryDimensions = (cube, dimensions) => {
  return dimensions?.map((dimension) => `${cube}.${dimension}`);
};

export const getSelectedRows = (data, isExpandable) => {
  return data?.reduce((acc, curr) => {
    if (isExpandable && curr?.child_rows?.length) {
      curr?.child_rows.forEach((childRow) => {
        if (childRow.isChecked) {
          acc.push(childRow);
        }
      });
    } else {
      if (curr?.isChecked) {
        acc.push(curr);
      }
    }
    return acc;
  }, []);
};

export const cubeQueryResultFormatter = (
  cubeQueryResultSet,
  viewType,
  metricsToFormat,
  cube
) => {
  const summaryRow = "";
  let resultSetData = [];

  if (viewType === "Comparison") {
    const firstData = cubeQueryResultSet?.loadResponse?.results[0]?.data || [];
    const secondData = cubeQueryResultSet?.loadResponse?.results[1]?.data || [];

    resultSetData = mergeAndCalculateChange(
      firstData,
      secondData,
      metricsToFormat,
      cube
    );
  } else {
    resultSetData =
      cubeQueryResultSet?.loadResponse?.results[0]?.data?.map((queryResult) => {
        return changeHeader(queryResult);
      }) || [];
  }

  return { summaryRow, resultSetData };
};

export const comparisonDataConverter = () => {

}

function changeHeader(data) {
  let modifiedObject = {};
  Object.keys(data).forEach((key) => {
    const newKey = keyExtractor(key);
    modifiedObject[newKey] = data[key];
  });
  return modifiedObject;
}

function mergeAndCalculateChange(firstData, secondData, metrics, cube) {
  return firstData.map((queryResult, index) => {
    const modifiedObject = changeHeader(queryResult, metrics);

    metrics.forEach((metric) => {
      const currentValue = modifiedObject[metric] || 0;

      // Check if the secondData has a corresponding entry
      if (secondData[index]) {
        const previousValue = secondData[index][`${cube}.${metric}`] || 0;
        const change = currentValue - previousValue;

        const percentageChange = (change / Math.abs(previousValue)) * 100;

        modifiedObject[metric] = {
          curr: currentValue,
          prev: previousValue,
          change:
            `${percentageChange?.toFixed(2)}%` === ("NaN%" || "Infinity%")
              ? "N/A"
              : `${percentageChange?.toFixed(2)}%`,
          moment: getMoment(currentValue, previousValue),
        };
      } else {
        modifiedObject[metric] = {
          curr: currentValue,
          prev: 0,
          change: "N/A",
          moment: "N/A",
        };
      }
    });

    return modifiedObject;
  });
}

export default function getMoment(current, previous) {
  return current > previous ? "up" : "down";
}
