import React, { useEffect, useState } from "react";
import SectionTitle from "../../components/sectionTitle";
import { useDispatch, useSelector } from "react-redux";
import { Card, Form, ProgressBar } from "react-bootstrap";
import CustomDropDown from "../../components/DropDown/CustomDropDown";
import overviewSeiingIcon from "../../assets/svgs/filter.svg";

import styles from "./quadrantView.module.css";
import {
  Chart,
  LinearScale,
  CategoryScale,
  PointElement,
  Tooltip,
  LineElement,
  Filler,
  BarElement,
  Legend,
  BarController,
} from "chart.js";
import {
  createQuadrants,
  generateSummaryData,
  metricsMapper,
  validateInputData,
  generateContent,
  createImage,
  initialFilterConfigs,
  generateQuadrantFilter,
  mergeCustomOutputToQuadrantFilters,
  defaultDimensions,
  ruleItemInjector,
  quadrantAxisMetrics,
  transformToTopXArray,
} from "../../helper/Quadrant/quadrantHelper";
import Annotation from "chartjs-plugin-annotation";
import cubejs from "@cubejs-client/core";
import { useGlobalContext } from "../../context/global";
import { formatDate } from "../../components/Reports/Components/utils";
import { toast } from "react-toastify";
import useToken from "../../helper/Reports/useToken";
import topPerformingImage from "../../assets/svgs/202.svg";
import lowDiscoveryImage from "../../assets/svgs/questionmark.svg";
import nonPerformingImage from "../../assets/svgs/nonperforming.svg";
import highPotentialImage from "../../assets/svgs/topstar.svg";
import MultiSelect from "../../components/DropDown/Multiselect";
import "./quadrantview.css";
import PageStatus from "../../components/Common/PageStatus";
import { useNavigate } from "react-router-dom";
import { masterApi } from "../../apis/masterApi";
import MasterTagetinngActionAlertMModal from "../../components/modal/masterTargetinngActionnAlertMModal/masterTargetingActionnAlertModal";
import { campaignListActions } from "../../redux/slices/campaignList";
import FilterWidget from "../../components/Filter/Widget";

import {
  FiltersCampaignDataQuadrant,
  FiltersDimensionDataQuadrant,
  FiltersMeasuresDataQuadrant,
  PlatformWiseKeyMapper,
  PlatformWiseQueryDefaultDimension,
  PlatformWiseQueryMeasures,
  PlatformWiseQueryOrder,
  useTopXDataConfig,
} from "../../components/Filter/FilterData/quadrantFilter";
import { processFields } from "../../components/Filter/subComponents/filterHelper";
import axios from "axios";
import { keyExtractor } from "../../helper/widget";

const QuadrantView = () => {
  const dispatch = useDispatch();
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );
  const quadrants = createQuadrants();
  const { cubeToken, setCubeToken, calendarDate, clientAccountId, clientId } =
    useGlobalContext();
  const  TopXDataConfig = useTopXDataConfig();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const userPlatform = JSON.parse(localStorage.getItem("user_platform"));
  const [filterModuleFilters, setFilterModuleFilters] = useState({});
  const navigate = useNavigate();

  const [conditionalFilters,setConditionalFilters] = useState([{id:1,filterName:'',value:''},{id:2,filterName:'',value:''}])
  
  const [selectedAdditionalMetrics, setSelectedAdditionalMetrics] = useState([]);
  const [showAlertMsgOf,setShowAlertMsgOf] = useState("")
  const [isAnyValueChanged, setIsAnyValueChanged] = useState(false);

  const [cubeQueryMeasure, setCubeQueryMeasure] = useState(PlatformWiseQueryMeasures[userPlatform]);
  const [cubeQueryDimensions, setCubeQueryDimensions] = useState(PlatformWiseQueryDefaultDimension[userPlatform]);

  const [filters, setFilters] = useState(initialFilterConfigs);
  const yAxisStartValue = Number(conditionalFilters[1].value ?? 0);
  const xAxisStartValue = Number(conditionalFilters[0].value ?? 0);

  const paramsForQuadrantFilters = {
    xFilterName: conditionalFilters[0]?.filterName,
    xFiltervalue: xAxisStartValue,
    yFilterName: conditionalFilters[1]?.filterName,
    yFilterValue: yAxisStartValue,
  };

  // const {quadrant1Filter,quadrant2Filter,quadrant3Filter,quadrant4Filter} = getAllQuadrantFilters(paramsForQuadrantFilters);
  const quadrant1FilterMetrics = generateQuadrantFilter(
    paramsForQuadrantFilters.xFilterName,
    paramsForQuadrantFilters.yFilterName,
    xAxisStartValue,
    yAxisStartValue,
    1
  );
  const quadrant2FilterMetrics = generateQuadrantFilter(
    paramsForQuadrantFilters.xFilterName,
    paramsForQuadrantFilters.yFilterName,
    xAxisStartValue,
    yAxisStartValue,
    2
  );
  const quadrant3FilterMetrics = generateQuadrantFilter(
    paramsForQuadrantFilters.xFilterName,
    paramsForQuadrantFilters.yFilterName,
    xAxisStartValue,
    yAxisStartValue,
    3
  );
  const quadrant4FilterMetrics = generateQuadrantFilter(
    paramsForQuadrantFilters.xFilterName,
    paramsForQuadrantFilters.yFilterName,
    xAxisStartValue,
    yAxisStartValue,
    4
  );
  const quadrant1Filter = mergeCustomOutputToQuadrantFilters(
    filterModuleFilters?.metrics?.custom_output,
    filterModuleFilters?.dimension?.custom_output,
    filterModuleFilters?.campaigns?.custom_output,
    filterModuleFilters?.topX?.custom_output,
    quadrant1FilterMetrics,
    clientAccountId
  );
  const quadrant2Filter = mergeCustomOutputToQuadrantFilters(
    filterModuleFilters?.metrics?.custom_output,
    filterModuleFilters?.dimension?.custom_output,
    filterModuleFilters?.campaigns?.custom_output,
    filterModuleFilters?.topX?.custom_output,
    quadrant2FilterMetrics,
    clientAccountId
  );
  const quadrant3Filter = mergeCustomOutputToQuadrantFilters(
    filterModuleFilters?.metrics?.custom_output,
    filterModuleFilters?.dimension?.custom_output,
    filterModuleFilters?.campaigns?.custom_output,
    filterModuleFilters?.topX?.custom_output,
    quadrant3FilterMetrics,
    clientAccountId
  );
  const quadrant4Filter = mergeCustomOutputToQuadrantFilters(
    filterModuleFilters?.metrics?.custom_output,
    filterModuleFilters?.dimension?.custom_output,
    filterModuleFilters?.campaigns?.custom_output,
    filterModuleFilters?.topX?.custom_output,
    quadrant4FilterMetrics,
    clientAccountId
  );

  const wrapperWidth =
    window.innerWidth > 1200
      ? "800px"
      : window.innerWidth > 1000
      ? "800px"
      : "100%";

  const getAdditionalFilters = (data) => {
    let filters = [];
    data.forEach((filter) => {
      if (filter?.default?.length > 0) {
        filters.push({
          member: `VariantAnalysisTablePPMPlaceholder.${filter?.memberkey}`,
          operator: "equals",
          values: filter?.default,
        });
      }
    });
    return filters;
  };

  const additonalFilters = getAdditionalFilters(filters);

  Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    quadrants,
    Tooltip,
    LineElement,
    Filler,
    Annotation,
    BarElement,
    Legend,
    BarController
  );


  const cubejsApi = cubejs(cubeToken, {
    apiUrl: process.env.REACT_APP_CUBE_JS_URL,
  });

  const [additionalMetric, setAdditionalMetric] = useState("CTR");
  const [loading, setIsLoading] = useState(false);
  const [completion, setCompletion] = useState(0);

  const [cubeQueryOrder, setCubeQueryOrder] = useState(PlatformWiseQueryOrder[userPlatform]);

  // All Summary Related states
  const [q1Summary, setQ1Summary] = useState({ summary: {}, data: [] });
  const [q2Summary, setQ2Summary] = useState({ summary: {}, data: [] });
  const [q3Summary, setQ3Summary] = useState({ summary: {}, data: [] });
  const [q4Summary, setQ4Summary] = useState({ summary: {}, data: [] });

  const [token, refresh] = useToken("");

  const [fetchIssue, setFetchIssue] = useState(false);
  const yAxisFilter = conditionalFilters[1]?.filterName;
  const xAxisFilter = conditionalFilters[0]?.filterName;
  const yAxisMetricSelected = yAxisFilter === "" ? "Select" : yAxisFilter;
  const xAxisMetricSelected = xAxisFilter === "" ? "Select" : xAxisFilter;

  const xKeyFilter = metricsMapper[userPlatform][xAxisMetricSelected];
  const yKeyFilter = metricsMapper[userPlatform][yAxisMetricSelected];

  const additionalFilterMetric1 = selectedAdditionalMetrics?.map((data) => {
    return { label: data, value: q1Summary?.summary[metricsMapper[userPlatform][data]] ?? 0 };
  });
  const additionalFilterMetric2 = selectedAdditionalMetrics?.map((data) => {
    return { label: data, value: q2Summary?.summary[metricsMapper[userPlatform][data]] ?? 0 };
  });
  const additionalFilterMetric3 = selectedAdditionalMetrics?.map((data) => {
    return { label: data, value: q3Summary?.summary[metricsMapper[userPlatform][data]] ?? 0 };
  });
  const additionalFilterMetric4 = selectedAdditionalMetrics?.map((data) => {
    return { label: data, value: q4Summary?.summary[metricsMapper[userPlatform][data]] ?? 0 };
  });

  // useEffect(() => {
  // if(validateInputData(conditionalFilters)){
  //     handleQuadrantDataUpdate()
  //   }
  // },[filters])

  const quadrant1MetricX = q1Summary?.summary[xKeyFilter] ?? 0;
  const quadrant1MetricY = q1Summary?.summary[yKeyFilter] ?? 0;
  const quadrant2MetricX = q2Summary?.summary[xKeyFilter] ?? 0;
  const quadrant2MetricY = q2Summary?.summary[yKeyFilter] ?? 0;
  const quadrant3MetricX = q3Summary?.summary[xKeyFilter] ?? 0;
  const quadrant3MetricY = q3Summary?.summary[yKeyFilter] ?? 0;
  const quadrant4MetricX = q4Summary?.summary[xKeyFilter] ?? 0;
  const quadrant4MetricY = q4Summary?.summary[yKeyFilter] ?? 0;

  const q1Content = generateContent(
    q1Summary?.summary["total_count"],
    xAxisFilter,
    quadrant1MetricX,
    yAxisFilter,
    quadrant1MetricY,
    additionalFilterMetric1
  );
  const q2Content = generateContent(
    q2Summary?.summary["total_count"],
    xAxisFilter,
    quadrant2MetricX,
    yAxisFilter,
    quadrant2MetricY,
    additionalFilterMetric2
  );
  const q3Content = generateContent(
    q3Summary?.summary["total_count"],
    xAxisFilter,
    quadrant3MetricX,
    yAxisFilter,
    quadrant3MetricY,
    additionalFilterMetric3
  );
  const q4Content = generateContent(
    q4Summary?.summary["total_count"],
    xAxisFilter,
    quadrant4MetricX,
    yAxisFilter,
    quadrant4MetricY,
    additionalFilterMetric4
  );

  const highPotential = createImage(highPotentialImage);
  const lowDiscovery = createImage(lowDiscoveryImage);
  const nonperforming = createImage(nonPerformingImage);
  const topperforming = createImage(topPerformingImage);
  const handleFilterOutput = (filters, type) => {
    // updatePageNum(1)
    // setComplexFiltersCount(countCategoryObjects(filters.dimension.data) + countCategoryObjects(filters.metrics.data) + countCategoryObjects(filters?.topX?.data));
    setFilterModuleFilters(filters);
  };

  const timeDimensions = [
    {
      dimension: PlatformWiseKeyMapper[userPlatform]?.date,
      dateRange: [
        formatDate(calendarDate[0].startDate),
        formatDate(calendarDate[0].endDate),
      ],
    },
  ];
  async function getQuadrantData(filter) {
    return new Promise(async (resolve, reject) => {
      try {
        const limit = 5000;
        const summaryUrl = `${process.env.REACT_APP_CATALOG_API}/client/${clientId}/products:insights-summary?source=${userPlatform}`;

        // update filter with default dimensions
        let tempFilter = JSON.parse(JSON.stringify(filter));
        if(userPlatform === 'amazon'){
         tempFilter = ruleItemInjector(tempFilter,"dimensions", defaultDimensions(clientAccountId));
        const excludeSBCampaignsFiltering = {type: "RULE_ITEM","member":"VariantAnalysisTablePPMPlaceholder.campaignFormat","operator":"notEquals","values":["SB","SB(V)"]}
        tempFilter = ruleItemInjector(tempFilter,"campaigns", defaultDimensions("",[excludeSBCampaignsFiltering]));
       }
        let tempExtraFilters = tempFilter?.campaigns;
        let percentileFilters = transformToTopXArray(filter?.topX) ?? [];
        
        let extraFilters = {
          type: `${userPlatform}`,
          [PlatformWiseKeyMapper[userPlatform]?.defaultCampaignBackend]: tempExtraFilters,
        };
        const queryData = {
          dimensions: cubeQueryDimensions,
          filters: tempFilter,
          extra_filters: extraFilters,
          percentile_filters: percentileFilters,
          measures: cubeQueryMeasure,
          order: PlatformWiseQueryOrder[userPlatform],
          timeDimensions: timeDimensions,
          limit,
        };
        const dataToPost = {
          query: queryData,
        };
        const summaryData = await axios.post(summaryUrl, dataToPost);
        if (summaryData?.status === 200) {
          resolve(summaryData?.data);
        }
      } catch (error) {
        toast.error("unknown error occurred" + JSON.stringify(error));
        setIsLoading(false);
        setFetchIssue(true);
        reject(error);
        console.log(error);
      }
    });
  }

  const fetchAllQuadrantData = async () => {
    let completionInterval = null;
    // This function will be used to update the completion status as we're hitting all the API's parallely and not waiting for one to complete
    try {
      setIsLoading(true);
      setCompletion(0);
      // Define filters
      const filters = [
        quadrant1Filter,
        quadrant2Filter,
        quadrant3Filter,
        quadrant4Filter,
      ];
      // Function to increment completion
      const incrementCompletion = (value) => {
        setCompletion(value);
      };

      // Increment completion in steps
      for (let i = 1; i <= 3; i++) {
        setTimeout(() => incrementCompletion(i * 25), i * 1500);
      }
      // Create an array of promises
      const dataPromises = filters.map((filter) => getQuadrantData(filter));

      // Hitting all the API's parallely for high performance as done in D2C not waiting for one to complete
      const [q1Data, q2Data, q3Data, q4Data] = await Promise.all(dataPromises);
      // Clear the interval and set completion to 100%
      clearInterval(completionInterval);    
      const q1Summary = generateSummaryData(q1Data);

      const q2Summary = generateSummaryData(q2Data);

      const q3Summary = generateSummaryData(q3Data);

      const q4Summary = generateSummaryData(q4Data);
      setCompletion(100);
      setIsLoading(false);
      // Now you have all the summary data
      return { q1Summary, q2Summary, q3Summary, q4Summary };
    } catch (error) {
      toast.error("Some unknown error occurred!Please Retry.");
      console.error("Error fetching quadrant data:", error);
    }
  };

  const handleQuadrantDataUpdate = async (e) => {
    try {
      if (validateInputData(conditionalFilters)) {
        setIsLoading(true);
        const { q1Summary, q2Summary, q3Summary, q4Summary } =
          await fetchAllQuadrantData();
        setQ1Summary({ summary: q1Summary });
        setQ2Summary({ summary: q2Summary });
        setQ3Summary({ summary: q3Summary });
        setQ4Summary({ summary: q4Summary });
        setIsLoading(false);
        setFetchIssue(false);
        setIsAnyValueChanged(false);
      } else {
        toast.error(
          "Please select both axis metrics and values to generate quadrant view"
        );
      }
    } catch (error) {
      setFetchIssue(true);
      setIsLoading(false);
      toast.error(JSON.stringify(error));
      if (error.message === "Invalid token") {
        setCubeToken(false);
        localStorage.setItem("cubeToken", null);
        refresh();
      }
    }
  };

  function convertToUrlFormat(data) {
    const urlParams = data?.map((item) => {
      const key = keyExtractor(item.member); // Get the last part of the member name
      const value = item.values.join(","); // Join multiple values with a comma
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`; // Encode the key and value for URL
    });

    return urlParams.join("&"); // Join all parameters with '&'
  }

  const handleProductAction = (quadrant, action="VIEW_PRODUCT") => {
    let filters = "";
    let queryFilter = [];
    if (quadrant === 1) {
      queryFilter = quadrant1Filter;
    } else if (quadrant === 2) {
      queryFilter = quadrant2Filter;
    } else if (quadrant === 3) {
      queryFilter = quadrant3Filter;
    } else if (quadrant === 4) {
      queryFilter = quadrant4Filter;
    }

    filters = encodeURIComponent(JSON.stringify(queryFilter));
    if (filters !== "" && action === "VIEW_PRODUCT") {
      window.open(
        "/product-analytics?reportType=Product&viewtype=Performance+View&" +
          "urlQuery=" +
          filters,
        "_blank"
      );
    }else if(action === "CREATE_PRODUCT_SET"){
      if(userPlatform === 'myntra' || userPlatform === 'flipkart'){
        return toast.info(`This feature is currently not supported for ${userPlatform}.`)
      }
      window.open(
        "/product-analytics?reportType=Product&viewtype=Performance+View&page=productset&" +
          "urlQuery=" +
          filters,
        "_blank"
      );
    }else if(action === "PAUSE"){
      if(userPlatform === 'myntra' || userPlatform === 'flipkart'){
        return toast.info(`This feature is currently not supported for ${userPlatform}.`)
      }
      window.open(
        "/product-analytics?reportType=Product&viewtype=Performance+View&page=stoploss&" +
          "urlQuery=" +
          filters,
        "_blank"
      );
    }
  };


  const noClickHandler = () => {
    dispatch(campaignListActions.hideActionAlertModal());
  }
  const handleConfirmPause =async () => {
    try {
      const status = "paused";
      const data = {
        status: status
      };
      dispatch(campaignListActions.hideActionAlertModal());
      const result = await masterApi(data, "uploadasinsstatus", false, "post");

      if (result?.statusCode !== 200) {
        toast.error('Some Unknown Error Occurred')
      } else {
        toast.success('Updated Successfully changes might take some minutes to reflect!')
      }
    } catch (error) {
      throw error.message;
    }
  }


  const handleConditionChange = (actionkey, receivedValue, recievedId) => {
    setIsAnyValueChanged(true);
    setConditionalFilters((prev) => {
      return [...prev]?.map((prevFilter) => {
        if (prevFilter.id === recievedId) {
          if (actionkey === "filterName") {
            prevFilter[actionkey] = receivedValue;
          }
          if (actionkey === "value" && !isNaN(receivedValue)) {
            prevFilter[actionkey] = receivedValue;
          }
        }

        return { ...prevFilter };
      });
    });
  };

  const isDataPresent = () => {
    return (
      Object?.keys(q1Summary?.summary).length > 0 &&
      Object?.keys(q2Summary?.summary).length > 0 &&
      Object?.keys(q3Summary?.summary).length > 0 &&
      Object?.keys(q4Summary?.summary).length > 0
    );
  };
  const getAppliedFilters = (e, filterKeys, type) => {
    setFilters((prev) => {
      return [...prev]?.map((prevFilter) => {
        if (type === prevFilter?.key) {
          prevFilter.default = e;
        }
        return { ...prevFilter };
      });
    });
  };

  useEffect(() => {
    if (validateInputData(conditionalFilters)) {
      handleQuadrantDataUpdate();
    }
  }, [filterModuleFilters]);

  useEffect(() => {
    const init = async () => {
      const precessedDimensionData = await processFields(
        FiltersDimensionDataQuadrant[userPlatform].fields,
        clientId,
        []
      );
      FiltersDimensionDataQuadrant[userPlatform].fields = precessedDimensionData;
      const precessedMeasuresData = await processFields(
        FiltersMeasuresDataQuadrant[userPlatform].fields,
        clientId,
        []
      );
      FiltersMeasuresDataQuadrant[userPlatform].fields = precessedMeasuresData;
      const precessedAmazonData = await processFields(
        FiltersCampaignDataQuadrant[userPlatform].fields,
        clientId,
        []
      );
      FiltersCampaignDataQuadrant[userPlatform].fields = precessedAmazonData;
    };
    init();
  }, [clientId]);

  const isAllQuadrantDataPresent = isDataPresent();

  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      id="main-box"
    >
      {/*filter start==================================== */}
      <FilterWidget
        key="quadrant_filter"
        config={{
          campaigns : FiltersCampaignDataQuadrant[userPlatform],
          measure: FiltersMeasuresDataQuadrant[userPlatform],
          dimension: FiltersDimensionDataQuadrant[userPlatform],
          topXConfig: TopXDataConfig,
        }}
        campaignFilter={true}
        filters={filterModuleFilters}
        showPopupModal={showFilterModal}
        setShowPopupModal={setShowFilterModal}
        output={handleFilterOutput}
        prefixMember={""}
        type="popup"
        sidebar={true}
        default={"metrics"}
        page={"overlay"}
        isApplied={() => {}}
        summary={"always"}
        narrowFilters={true}
        summaryOutput={(e) => {}}
        calloutTitle="Select your filters"
        calloutContent="+ Add Filter"
        // calloutDescription="Add new attributes at campaign level to determine campaign funnel"
      />

      {/*filter end======================================= */}
      <div className="d-flex justify-content-between align-items-center mb-1">
        <div className="mt-1">
          <SectionTitle sectionTitleHeading={"Quadrant View"}></SectionTitle>
        </div>
        <button
        style={{position:"relative"}}
              className="primary-inactive-btn apply-filter d-flex align-items-center"
              onClick={() => {
                setShowFilterModal(true);
              }}
            >
              <div className="d-flex gap-2 align-items-center">
                {" "}
                <img
                  src={overviewSeiingIcon}
                  className="cursor-pointer-global filterIcon"
                  alt="overview-settings-tile"
                ></img>{" "}
                <div >Filters
                {filterModuleFilters?.metrics?.data?.length > 0 ||
                filterModuleFilters?.dimension?.data?.length > 0 ||
                filterModuleFilters?.campaigns?.data?.length > 0 ||
                filterModuleFilters?.topX?.data?.length > 0 ? (
                  <div className="filter-applied">
                </div>
                ) : null}
              </div>
              </div>
            </button>
      </div>
      {/* <OptimizerFilters handleDropdown={getAppliedFilters} filtersData={filters}/> */}

      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between">
            <div className="d-flex gap-5">
              <div className="d-flex align-items-center gap-3">
                <CustomDropDown
                  defaultSelected={[]}
                  targettingValue={xAxisMetricSelected}
                  data={quadrantAxisMetrics[userPlatform]}
                  title={"Select X Axis Metric"}
                  filterCampaigns={(type, value) => {
                    handleConditionChange(
                      "filterName",
                      type?.target?.value,
                      conditionalFilters[0]?.id
                    );
                  }}
                />
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label className="filter-options m-0">
                    X Axis Value
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    onChange={(e) => {
                      handleConditionChange(
                        "value",
                        e?.target?.value?.trim(),
                        conditionalFilters[0]?.id
                      );
                    }}
                    value={conditionalFilters[0]?.value}
                    placeholder="Enter Value"
                  />
                </Form.Group>
              </div>
              <div className="d-flex align-items-center gap-3">
                <CustomDropDown
                  defaultSelected={[]}
                  data={quadrantAxisMetrics[userPlatform]}
                  targettingValue={yAxisMetricSelected}
                  title={"Select Y Axis Metric"}
                  filterCampaigns={(type, value) => {
                    handleConditionChange(
                      "filterName",
                      type?.target?.value,
                      conditionalFilters[1]?.id
                    );
                  }}
                />
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label className="filter-options m-0">
                    Y Axis Value
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    onChange={(e) => {
                      handleConditionChange(
                        "value",
                        e?.target?.value?.trim(),
                        conditionalFilters[1]?.id
                      );
                    }}
                    value={conditionalFilters[1]?.value}
                    placeholder="Enter Value"
                  />
                </Form.Group>
              </div>
            </div>
            <div className="d-flex align-items-center mt-3">
              <button
                className="primary-active-btn"
                disabled={loading}
                onClick={handleQuadrantDataUpdate}
              >
                {loading ? "Generating..." : "Generate Quadrant"}
              </button>
            </div>
          </div>
        </Card.Body>
      </Card>
      <p className="quadrant-view-vs-heading mt-3 mb-2">
        {yAxisMetricSelected !== "Select" && xAxisMetricSelected !== "Select"
          ? ` ${xAxisMetricSelected} V/S ${yAxisMetricSelected}  Performance`
          : null}{" "}
      </p>
      <Card className="mt-0">
        <Card.Body>
          <div className="d-flex justify-content-center">
            <MultiSelect
              title={"Select Additional Metrics"}
              defaultSelected={selectedAdditionalMetrics}
              options={quadrantAxisMetrics[userPlatform].filter(
                (data) =>
                  data !== yAxisMetricSelected && data !== xAxisMetricSelected
              )}
              onApply={(e) => {
                if (e.length > 3) {
                  toast.error("You can select maximum 3 additional metrics");
                  return;
                }
                setSelectedAdditionalMetrics(e);
              }}
              isShowSelectAll={false}
              isSearch={false}
              placeHolder={"Select additional metrics"}
              externalDivClasses={styles.additionalMetrics}
            />
          </div>
          <div
            style={{ minHeight: "500px" }}
            className="d-flex justify-content-center align-items-center"
          >
            {loading ? (
              <>
                <div>
                  <div
                    className="text-center"
                    style={{ fontSize: "20px", letterSpacing: "1" }}
                  >
                    {completion <= 10
                      ? "Fetching Data..."
                      : completion === 25
                      ? "Processing data..."
                      : completion === 50
                      ? `Generating Quadrant`
                      : completion === 75
                      ? "Almost there..."
                      : ""}
                  </div>
                  <div style={{ display: "flex", gap: "12px" }}>
                    <div style={{ width: "150px" }}>
                      {" "}
                      <ProgressBar
                        variant={"info"}
                        size={"medium"}
                        now={completion >= 25 ? 100 : 0}
                      />
                    </div>
                    <div style={{ width: "150px" }}>
                      {" "}
                      <ProgressBar
                        variant={"info"}
                        size={"medium"}
                        now={completion >= 50 ? 100 : 0}
                      />
                    </div>
                    <div style={{ width: "150px" }}>
                      {" "}
                      <ProgressBar
                        variant={"info"}
                        size={"medium"}
                        now={completion >= 75 ? 100 : 0}
                      />
                    </div>
                    <div style={{ width: "150px" }}>
                      {" "}
                      <ProgressBar
                        variant={"info"}
                        size={"medium"}
                        now={completion > 75 ? 100 : 0}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : ((isAllQuadrantDataPresent && !isAnyValueChanged ) && !loading) ? (
              <>
                <div
                  style={{
                    height: "600px",
                    paddingBottom: "40px",
                    marginTop: "24px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ width: wrapperWidth, position: "relative" }}>
                    <div className={`${styles.axis} ${styles.left}`}>
                      <div style={{ width: "200px" }}></div>
                      <div>
                        {yAxisMetricSelected}
                        {/* <div>{ headings.filter(head => head?.sortKey === conditionalFilters[1].filterName).map((data) =>  stripHtml(data?.name)) }  </div> */}
                        <div style={{ paddingLeft: "30px" }}>
                          {conditionalFilters[1].value}
                        </div>
                      </div>
                      <div style={{ transform: "rotate(90deg)", width: "0px" }}>
                        y
                      </div>
                    </div>
                    <div className={`${styles.axis} ${styles.bottom}`}>
                      <div></div>
                      <div style={{ textAlign: "center" }}>
                        <div>{conditionalFilters[0].value}</div>
                        {xAxisMetricSelected}
                        {/* <div >{ headings.filter(head => head?.sortKey === conditionalFilters[0].filterName).map((data) =>  stripHtml(data?.name)) } </div> */}
                      </div>
                      <div style={{ textAlign: "right" }}>x</div>
                    </div>
                    <div className={styles.quadrantWrapper}>
                        <div className={`${styles.quadrant} ${styles.high}`}>
                            <div className={styles.headingWrapperLeft}>
                                <div className={styles.headingImage}><img src={highPotential.src} alt=''/></div>
                                <div className={styles.heading}> High Potential</div>
                            </div>
                            {q2Content.map((item, index) => (
                              <p className={styles.subText} key={index}>{item}</p>
                            ))}
                            <div className={styles.cardBtnContainer}>
                            <div onClick={(e)=>handleProductAction(2)} className={styles.cardPrimaryBtn}>{q2Summary?.summary['total_count']} Products</div>
                            <div onClick={(e)=>handleProductAction(2,"CREATE_PRODUCT_SET")} className={styles.cardPrimaryBtn}>Create New Product Set</div>
                            </div>
                        </div>
                        <div className={`${styles.quadrant} ${styles.top}`}>
                            <div className={styles.headingWrapperRight}>
                                <div className={styles.heading}>Top Performing</div>
                                <div className={styles.headingImage}><img src={topperforming.src} alt=''/></div>
                            </div>
                            {q1Content.map((item, index) => (
                              <p className={styles.subText} key={index}>{item}</p>
                            ))}
                            <div className={styles.cardBtnContainer}>
                            <div  onClick={(e)=>handleProductAction(1)} className={styles.cardPrimaryBtn}>{q1Summary?.summary['total_count']} Products</div>
                            <div onClick={(e)=>handleProductAction(1,"CREATE_PRODUCT_SET")} className={styles.cardPrimaryBtn}>Create New Product Set</div>
                            </div>
                        </div>
                        <div className={`${styles.quadrant} ${styles.low}`}>
                          <div className={styles.headingWrapperLeft}>
                                <div className={styles.headingImage}><img src={lowDiscovery.src} alt=''/></div>
                                <div className={styles.heading}>Low Discovery</div>
                            </div>
                            {q3Content.map((item, index) => (
                              <p className={styles.subText} key={index}>{item}</p>
                            ))}
                            <div className={styles.cardBtnContainer}>
                            <div onClick={(e)=>handleProductAction(3)} className={styles.cardPrimaryBtn}>{q3Summary?.summary['total_count']} Products</div>
                            <div onClick={(e)=>handleProductAction(3,"CREATE_PRODUCT_SET")} className={styles.cardPrimaryBtn}>Create New Product Set</div>
                            </div>
                        </div>
                        <div className={`${styles.quadrant} ${styles.non}`}>
                            <div className={styles.headingWrapperRight}>
                                <div className={styles.heading}>Non Performing</div>
                                <div className={styles.headingImage}><img src={nonperforming.src} alt=''/></div>
                            </div>
                            {q4Content.map((item, index) => (
                              <p className={styles.subText} key={index}>{item}</p>
                            ))}
                            <div className={styles.cardBtnContainer}>
                            <div onClick={(e)=>handleProductAction(4)} className={styles.cardPrimaryBtn}>{q4Summary?.summary['total_count']} Products</div>
                            <div onClick={(e)=>handleProductAction(4,"PAUSE")} className={styles.cardPrimaryBtn}>Pause Ads</div>
                            </div>
                            
                        </div>
                    </div>
                    {/* <Scatter
                      height={200}
                      data={chartData}
                      options={chartOptions}
                      plugins={quadrants}
                      width={100}
                      ref={chartRef}
                    /> */}
                  </div>
                </div>
              </>
            ) : fetchIssue ? (
              <PageStatus msg={"Some unknown error occurred!Please Retry."} />
            ) : (
              <PageStatus
                msg={
                  "Please select metrics and values to generate quadrant view"
                }
              />
            )}
          </div>
        </Card.Body>
      </Card>
      {showAlertMsgOf !== "" &&
          <MasterTagetinngActionAlertMModal
            alertText={showAlertMsgOf}
            // alertInfo={"*SB ads not supported at the moment"}
            onYesClick={handleConfirmPause}
            onNoClick={noClickHandler}
            confirmationId={"pause-adset-confirm"}
          ></MasterTagetinngActionAlertMModal>
        }
    </div>
  );
};

export default QuadrantView;
