import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Search, Bell, InfoCircle, Person } from "react-bootstrap-icons";
import Logo from "../../assets/images/Adyogi_logoz.svg";
import "./header.css";
import "bootstrap/dist/css/bootstrap.min.css";
import PrimaryBtnRB from "../Buttons/primaryBtnRB.js/primaryBtnRB";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { useSignOut } from "react-auth-kit";
import notifyIcon from "../../assets/icons/notify_icon_homedashboard.svg";
import accountIcon from "../../assets/icons/Mask group.svg";
import closeIcon from "../../assets/icons/close_icon.png";
import CalenderIconForCampaignList from "../../assets/icons/calender-icon-for-campaignlist.svg";
import selectSideIcon from "../../assets/icons/select-btn-side-grey-icon.png";
import marketinStreamIcon from "../../assets/svgs/marketing-stream.svg";
import reportingIcon from "../../assets/icons/reporting_icon.svg";
import bidAdjust from "../../assets/svgs/bid-adjustment.svg";
import arrowUpIcon from "../../assets/icons/arrow_up_icon.svg";
import arrowDownIcon from "../../assets/icons/down_arrow.svg";
import infoIcon from "../../assets/icons/help.svg";
import { dashboardActions } from "../../redux/slices/dashboardSlice";
import { useGlobalContext } from "../../context/global";
import useUserDetails from "../../helper/userData/useUserDetails";
import HeaderDrawer from "./HeaderDrawer";
import { hideCalendarPages } from "../../constant/hideCalendarOnDifferentPages";
import { useAuthUser } from "react-auth-kit";
import { useRef } from "react";
import optimiseIcon from "../../assets/icons/optimiseIcon.png";
import CalenderComponent from "../calender/Calender";
import priceTracking from "../../assets/icons/priceTracking.svg";
import userProfile from '../../assets/icons/userProfile.png';
import downArrow from '../../assets/icons/dropDownArrow.svg';
import axios from "axios";
import {  getClientDataFromAdyogiAPI, getPlatformStatus } from "../../helper/clientData/utils";
import amazonLogo from '../../assets/svgs/amazon.svg'
import myntraLogo from '../../assets/svgs/myntra.svg'
import flipkartLogo from '../../assets/svgs/flipkart.svg'
import { encodeData } from "../../helper/common/encodeData";
import PlatformTab from "./PlatformTab";

function Header(props) {
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [toggleSearch, setToggleSearch] = useState(false);
  const mobileScreen = useMediaQuery({ query: "(max-width: 991px)" });
  const [sidebarVisibility, setSidebarVisibility] = useState(false);
  const [login, setlogin] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const logout = useSignOut();
  const navigate = useNavigate();
  const closeRef = useRef();
  const masterTargetinngCalenderIconRef = useRef(null);
  const [showCustomDateBox, setShowCustomDateBox] = useState(false);
  const { dateDifference, setDateDifference,setShowCMSLink,clientId,calendarFreeze,selectedPlatform,
    setSelectedPlatform,updatePlatform,clientData } = useGlobalContext();
  const location = useLocation();
  const auth = useAuthUser();
  const userDetails = auth();
  const isMultiuser = userDetails?.user?.multiUser === "yes" ? true : false;
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );

  const isCalendarHidden = !hideCalendarPages?.includes(location.pathname);

  let clientName = JSON.parse(localStorage.getItem("clientName"));
  useEffect(() => {
    setSidebarVisibility(dashboardSideBarVisibiltyState);
  }, [dashboardSideBarVisibiltyState]);

  const handlelogin = () => {
    setlogin(!login);
  };

  const hendelToggle = () => {
    setToggle((prev) => !prev);
    setToggleSearch(false);
  };
  const hendelToggleSearch = () => {
    setToggle(false);
    setToggleSearch((prev) => !prev);
  };

  const handleLogout = () => {
    logout();
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    fetchData(clientId)
  },[clientId])

  const dateInputClickHandler = (e) => {
    setShowCustomDateBox(!showCustomDateBox);
  };

  const closeCalender = (e) => {
    if (
      masterTargetinngCalenderIconRef.current &&
      showCustomDateBox &&
      !masterTargetinngCalenderIconRef.current.contains(e.target)
    ) {
      setShowCustomDateBox(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeCalender);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", closeCalender);
    };
  }, [showCustomDateBox]);

  const redirectToDifferentUrl = () => {
    const url =
      "https://adyogisupport.freshdesk.com/support/solutions/folders/48000683840";
    if (isMultiuser) {
      window.open(url);
    }
  };

  const fetchData = async (passedClientId) => {
    try {
      const response = await getClientDataFromAdyogiAPI(passedClientId);
      const productType = response?.data?.results[0]?.amazon_setup?.product_type
      localStorage.setItem("product_type",JSON.stringify(productType ?? "internal"))
      if( productType==="Self serve") {
        setShowCMSLink(false)
      } else {
        setShowCMSLink(true)
      }
    } catch (error) {
      throw error;
    }
  }

  const {myntraStatus,flipkartStatus,amazonStatus} = getPlatformStatus(clientData);
  const TabNames = []
  // if(isMultiuser) {
   TabNames.push({name:'Amazon',src:amazonLogo,isDisabled:!amazonStatus},{name:'Flipkart',src:flipkartLogo,isDisabled:!flipkartStatus},{name:'Myntra',src:myntraLogo,isDisabled:!myntraStatus})
  // }

  return (
    <div className="header-container ">
      <Row className=" d-flex flex-row flex-md-row header-main-row">
        {/* <Col xs={6} lg={2} className="logo-container"> */}
        {/* <img className="logo" src={"https://s3.amazonaws.com/appforest_uf/f1667308949395x251261373847231360/Blue%20Background%20White%20Text.png"} alt={"Logo"} /> */}
        {/* <img className="logo" src={Logo} alt="logo" /> */}
        {/* </Col> */}
        {mobileScreen && (
          <>
            <Col xs={3}>
              <button
                className={"navToggle"}
                onClick={() => hendelToggleSearch((prev) => !prev)}
              >
                <span className=" form-control-feedback">
                  <Search color="#4D4184" />
                </span>
              </button>
            </Col>
            <Col xs={3}>
              <button
                className={toggle ? "navToggle open" : "navToggle"}
                onClick={() => hendelToggle()}
              >
                <div className="icon-left"></div>
                <div className="icon-right"></div>
              </button>
            </Col>
          </>
        )}
        <Col>
          {mobileScreen && (
            <Row
              className={
                mobileScreen &&
                (toggleSearch ? "toggle-dropdown open" : "toggle-dropdown")
              }
            >
              <Col
                xs={12}
                xl={6}
                className="serch-bar-container "
                style={{
                  width: !mobileScreen && "544px",
                  paddingRight: mobileScreen && "calc(var(--bs-gutter-x) * .5)",
                }}
              >
                <div className="form-group has-search w-100">
                  <span className=" form-control-feedback">
                    <Search color="#4D4184" />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search for features, Keywords and more"
                  />
                </div>
              </Col>
            </Row>
          )}
          <Row
            className={
              mobileScreen &&
              (toggle ? "toggle-dropdown open" : "toggle-dropdown")
            }
          >
            {!mobileScreen && (
              <Col
                xs={12}
                md={3}
                xl={4}
                className={
                  sidebarVisibility
                    ? "serch-bar-container-onopen-sidebar pt-2"
                    : "serch-bar-container-close-sidebar pt-2"
                }
                style={{
                  // width: !mobileScreen && "544px",
                  paddingRight: mobileScreen && "calc(var(--bs-gutter-x) * .5)",
                }}
              >
                
            {    <div className="platform-tabs-container">
                 {TabNames?.map((tab,index) => {
                    return (
                    <PlatformTab name={tab.name} isDisabled={tab.isDisabled} src={tab.src} key={tab.name}/>
                    )
                  })}
                  </div>}
              </Col>
            )}
            <Col xs={12} xl={3} className={`nav-button-container pt-3 pb-3`}>
              {/* Hide Calendar on Bid Optimiser Page  */}
              {isCalendarHidden && (
                <>
                  <button
                    className={`date-container date-btn-campaignlist ${calendarFreeze ? "disable-pointer-events" : ""}`}
                    onClick={dateInputClickHandler}
                  >
                    <div className="d-flex justify-conntent-center">
                      <span className="date-btn-text">{dateDifference}</span>
                    </div>
                    <div className="btn-calender-img-holder">
                      <span className="">
                        <img
                          className="dropdown-img-for-select"
                          src={selectSideIcon}
                        ></img>

                        <img
                          className="calender-icon-for-campaignlist"
                          src={CalenderIconForCampaignList}
                        ></img>
                      </span>
                    </div>
                  </button>
                  <div
                    ref={masterTargetinngCalenderIconRef}
                    className={
                      showCustomDateBox
                        ? "calender-inclasslist-container "
                        : "hide"
                    }
                  >
                    <CalenderComponent
                      setShowCustomDateBox={setShowCustomDateBox}
                    ></CalenderComponent>
                    <span className={`calender-close-icon-campaignlist ${calendarFreeze ? "disable-pointer-events" : ""}`}>
                      <img
                      className={`${calendarFreeze ? "disable-pointer-events" : ""}`}
                        onClick={() => setShowCustomDateBox(false)}
                        src={closeIcon}
                      ></img>
                    </span>
                  </div>
                </>
              )}
            </Col>
            {!mobileScreen ? (
              <>
              <Col className={`nav-icon-container pt-3 pb-3`}>
                {/* Hiding Temprorary  */}
                 <Col className={`nav-icon ${!isMultiuser?"hide":""}`}>
                  <Link
                    to="/history-logs"
                    className="fa-question-circle"
                    data-tooltip="History Logs"
                    style={{ fontSize: "12px" }}
                  >
                    <div className="filter-icon-container-main">
                      <img src={notifyIcon} alt={"History Logs"}></img>
                    </div>
                  </Link>
                </Col>
                
                {/* <Col className="nav-icon">
                  <Link
                    to={"/bid-optimiser"}
                    className="fa-question-circle"
                    data-tooltip="Optimise Bid"
                    style={{ fontSize: "12px" }}
                  >
                    {" "}
                    <div className="filter-icon-container-main">
                      <img src={optimiseIcon} height={22} alt={"help"}></img>
                    </div>
                  </Link>
                </Col> */}
                {/* <Col className="nav-icon">
                  <Link
                    to={"/price-history"}
                    className="fa-question-circle"
                    data-tooltip="Price Tracking"
                    style={{ fontSize: "12px" }}
                  >
                    {" "}
                    <div className="filter-icon-container-main">
                      <img
                        src={priceTracking}
                        alt={"price"}
                        height={28}
                        width={28}
                      ></img>
                    </div>
                  </Link>
                </Col> */}
                
                {
                  <Col className="nav-icon">
                    <Link
                      to={"#"}
                      className="fa-question-circle"
                      data-tooltip="Help"
                      onClick={redirectToDifferentUrl}
                      style={{ fontSize: "12px" }}
                    >
                      {" "}
                      <div className="filter-icon-container-main">
                        <img src={infoIcon} alt={"help"}></img>
                      </div>
                    </Link>
                    {/* <InfoCircle /> */}
                  </Col>
                }
                {/* <Col className="nav-icon">
                  <Link
                    to={"/bid-adjust"}
                    className="fa-question-circle"
                    data-tooltip="Placement Bid Adjustment"
                    style={{ fontSize: "12px" }}
                  >
                    {" "}
                    <div className="filter-icon-container-main">
                      <img src={bidAdjust} alt={"help"} style={{width:"16px",height:"20px"}}></img>
                    </div>
                  </Link>
                </Col> */}
                {/* <Col className="nav-icon">
                  <Link
                    to={"/marketing-stream"}
                    className="fa-question-circle"
                    data-tooltip="Marketing Stream"
                    style={{ fontSize: "12px" }}
                  >
                    {" "}
                    <div className="filter-icon-container-main">
                      <img src={marketinStreamIcon} alt={"help"} style={{width:"30px",height:"30px"}}></img>
                    </div>
                  </Link>
                </Col> */}
               
              </Col>
              {/* <div className="pt-3 pb-3"> */}
               <Col className="profile-container mt-3 mb-3  cursor-pointer d-flex align-items-center position-relative"   onClick={() => setDrawer(!drawer)}>
                  <div className="profile-columns-container d-flex align-items-center justify-content-between">
                    <div className="profile-img-column">
                    <img alt="profile" src={accountIcon}/>
                    </div>
                    {clientName ? <small title={clientName} className="text-center text-client-name">{clientName.length > 25 ? clientName.slice(0,25) + "..." : clientName}</small> : ''}
                    <div className="profile-dropdown-btn-container">
                      <img alt="Open" src={downArrow}/>
                    </div>
                  </div>
                  {drawer && (
                        <HeaderDrawer setDrawer={setDrawer} drawer={drawer} />
                  )}
               </Col>
               {/* </div> */}
               </>
            ) : (
              <ul className="d-flex flex-column align-items-center justify-content-center gap-8">
                <li>
                  {" "}
                  Info <InfoCircle />
                </li>
                <li>
                  {" "}
                  Notification <Bell />
                </li>
                <li onClick={() => navigate("/reports")}>
                  {" "}
                  Reporting <img src={infoIcon} alt={"help"}></img>
                </li>
                <li onClick={handleLogout}>
                  {" "}
                  User <Person />
                </li>
              </ul>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
}
export default Header;